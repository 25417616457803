import React, { createContext, useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useLocation } from "react-router-dom";

import useAuth from "../hooks/useAuth";
import { getAnnouncements } from "../api/api";
import useUser from "../hooks/useUser";

export type Announcement = {
  id?: string;
  type?: string;
  attributes: {
    id?: number;
    style: string;
    "content-as-html": string;
    "cookie-name": string;
    "cookie-expire-date": number | null;
    "path-filter"?: string;
  };
};

type Announcements = Announcement[];

interface AnnouncementsProviderProps {
  children: React.ReactNode;
}

const AnnouncementsContext = createContext<{
  announcements: Announcements;
  removeAnnouncement: (attributes: {
    "cookie-name": string;
    "cookie-expire-date": number | null;
  }) => void;
}>({
  announcements: [],
  removeAnnouncement: () => {}
});

const useAnnouncementsContext = () => {
  const context = useContext(AnnouncementsContext);
  if (!context) {
    throw new Error(
      "Context must be used inside an Announcements Context Provider"
    );
  }
  return context;
};

const AnnouncementsProvider: React.FC<AnnouncementsProviderProps> = ({
  children
}) => {
  const location = useLocation();
  const { passwordExpiring } = useUser();
  const { authenticatedUser: user } = useAuth();
  const [initialAnnouncements, setInitialAnnouncements] =
    useState<Announcement[]>();
  const [announcements, setAnnouncements] = useState<Announcement[]>([]);

  const cookieNames = announcements?.map(
    announcement => announcement.attributes["cookie-name"]
  );
  const [cookies, setCookie] = useCookies<string>([
    ...cookieNames,
    "password-expiring"
  ]);

  useEffect(() => {
    async function fetchAnnouncements() {
      const response = await getAnnouncements();
      setInitialAnnouncements(response.data.data);
    }

    fetchAnnouncements();
  }, []);

  useEffect(() => {
    const passwordExpiryAnnouncement: Announcements = passwordExpiring()
      ? [
          {
            attributes: {
              style: "password-expiring",
              "content-as-html":
                '<div>Consider changing your password</div>Your password will be expiring soon, please click <a href="/account/password">here</a> to reset your password.',
              "cookie-name": "password-expiring",
              "cookie-expire-date": 86400
            }
          }
        ]
      : [];

    const allAnnouncements = [
      ...(initialAnnouncements || []),
      ...passwordExpiryAnnouncement
    ];

    const filteredAnnouncements = filterAnnouncements(allAnnouncements);

    setAnnouncements(filteredAnnouncements);
  }, [initialAnnouncements, user, location, cookies]);

  const filterAnnouncements = (allAnnouncements: any) => {
    return allAnnouncements?.filter((announcement: Announcement) => {
      let validRegex = false;

      try {
        validRegex = new RegExp(
          announcement?.attributes["path-filter"] || ""
        ).test(window.location.pathname);
      } catch (e) {
        return false;
      }

      const cookieAnnouncementsAccepted =
        cookies[announcement.attributes["cookie-name"]] === "accepted";

      return announcement && !cookieAnnouncementsAccepted && validRegex;
    });
  };

  const removeAnnouncement = (attributes: {
    "cookie-name": string;
    "cookie-expire-date": number | null;
  }) => {
    if (attributes["cookie-name"]) {
      let options: Record<string, string | number> = {
        path: "/"
      };

      if (attributes["cookie-expire-date"]) {
        options = { ...options, maxAge: attributes["cookie-expire-date"] };
      }

      setCookie(attributes["cookie-name"], "accepted", options);

      const newAnnouncements = initialAnnouncements?.filter(
        announcement =>
          announcement.attributes["cookie-name"] !== attributes["cookie-name"]
      );

      setInitialAnnouncements(newAnnouncements);
    }
  };

  return (
    <AnnouncementsContext.Provider
      value={{ announcements: announcements || [], removeAnnouncement }}
    >
      {children}
    </AnnouncementsContext.Provider>
  );
};

export { AnnouncementsProvider, AnnouncementsContext, useAnnouncementsContext };
