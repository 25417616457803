import React, { useEffect, useState, useMemo, useRef } from "react";
import {
  NavLink,
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams
} from "react-router-dom";
import {
  NotificationHub,
  Sidebar,
  SidebarButton,
  SidebarContent,
  SidebarProvider
} from "@nef/core";
import slugify from "slug";

import {
  getMyOrganizations,
  getOrganizationTeamInvoices,
  getOrganizationTeams,
  getOrganizationUsersByIds,
  getOrganizationById
} from "../../../api/api";
import useUser from "../../../hooks/useUser";
import type {
  Organization,
  OrganizationTeam,
  OrganizationTeamInvoice,
  OrganizationUser
} from "../../../api/types";

import styles from "./style.module.scss";
import OrganizationMemberPage from "./organization-member";
import {
  OrganizationApiPage,
  OrganizationApiDetailPage
} from "./organization-api-key";
import OrganizationInfoPage from "./organization-info";
import OrganizationDatasetPage from "./organization-dataset";

export interface NormalizedOrganizationTeam {
  keys: string[];
  items: {
    [key: string]: {
      invoice: OrganizationTeamInvoice;
      users: OrganizationUser[];
    } & OrganizationTeam;
  };
}

function OrganizationDashboard() {
  const { slug } = useParams<{ slug: string }>();
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const history = useHistory();
  const { isAdmin, shouldConfirm } = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const [currentOrganization, setCurrentOrganization] =
    useState<Organization>();
  const [teamInvoices, setTeamInvoices] = useState<OrganizationTeamInvoice[]>(
    []
  );
  const [organizationUsers, setOrganizationUsers] = useState<
    OrganizationUser[]
  >([]);
  const [organizationTeams, setOrganizationTeams] = useState<
    OrganizationTeam[]
  >([]);
  const isMountRef = useRef<boolean>(false);
  useEffect(() => {
    isMountRef.current = true;
    return () => {
      isMountRef.current = false;
    };
  }, []);

  useEffect(() => {
    setIsLoading(true);
    getMyOrganizations().then(response => {
      setOrganizations(response.data.organizations);
      setIsLoading(false);
    });
  }, []);

  const currentPath = useMemo(() => {
    const { pathname } = history.location;
    return pathname;
  }, [history]);

  useEffect(() => {
    if (organizations && organizations.length !== 0 && slug.length !== 0) {
      const currentOrg = organizations.find(organization => {
        if (organization?.name) {
          return slugify(organization.name) === slug.toLowerCase();
        }
        return false;
      });
      if (!currentOrg) {
        history.replace("/account/organizations");
        return;
      }
      if (currentOrg?.is_team_based && !isAdmin) {
        history.replace(`/organizations/${slug}`);
        return;
      }
      if (shouldConfirm()) {
        history.replace("/account/organizations");
        return;
      }
      setCurrentOrganization(currentOrg);
      if (currentPath === `/organizations-db/${slug}`)
        history.replace(`/organizations-db/${slug}/organization-info`);
    }
  }, [organizations, slug]);

  const [isOrganizatinDataLoading, setIsOrganizatinDataLoading] =
    useState(false);

  useEffect(() => {
    const requestPromises = [];
    if (currentOrganization) {
      setIsOrganizatinDataLoading(true);
      requestPromises.push(requestInvoiceData(currentOrganization.id));
      if (currentOrganization.organization_user_ids?.length !== 0) {
        requestPromises.push(
          requestUsersData(currentOrganization.organization_user_ids)
        );
      }
      if (currentOrganization.organization_team_ids?.length !== 0) {
        requestPromises.push(
          requestTeamsData(currentOrganization.organization_team_ids)
        );
      }
      Promise.all(requestPromises)
        .catch(() => {
          NotificationHub.send(
            "danger",
            "Something went wrong, try again later"
          );
        })
        .finally(() => {
          setIsOrganizatinDataLoading(false);
        });
    }
  }, [currentOrganization]);
  const requestInvoiceData = (organizationId: number) =>
    getOrganizationTeamInvoices(organizationId).then(invoices => {
      if (isMountRef.current) {
        setTeamInvoices(invoices);
      }
    });

  const requestUsersData = (userIds: number[]) =>
    getOrganizationUsersByIds(userIds)
      .then(response => response.data.organization_users)
      .then(users => {
        if (isMountRef.current) {
          setOrganizationUsers(users);
        }
      });

  const requestTeamsData = (teamIds: number[]) =>
    getOrganizationTeams(teamIds)
      .then(response => response.data.organization_teams)
      .then(teams => {
        if (isMountRef.current) {
          setOrganizationTeams(teams);
        }
      });

  const requestOrganizationData = (organizationId: number) => {
    getOrganizationById(organizationId)
      .then(response => response.data.organization)
      .then(organization => {
        if (isMountRef.current) {
          setCurrentOrganization(organization);
        }
      });
  };

  // normalize data
  const listData = useMemo(() => {
    // associate users and invoices to teams
    const userMap: {
      items: { [key: string]: OrganizationUser };
      keys: string[];
    } = {
      keys: [],
      items: {}
    };
    organizationUsers.forEach(user => {
      userMap.keys.push(`${user.id}`);
      userMap.items[`${user.id}`] = { ...user, organization_team_ids: [] };
    });

    const invoiceMap: {
      items: { [key: string]: OrganizationTeamInvoice };
      keys: string[];
    } = {
      keys: [],
      items: {}
    };
    teamInvoices.forEach(invoice => {
      invoiceMap.keys.push(`${invoice.id}`);
      invoiceMap.items[`${invoice.id}`] = invoice;
    });

    const result: NormalizedOrganizationTeam = {
      keys: [],
      items: {}
    };
    organizationTeams.forEach(team => {
      result.keys.push(`${team.id}`);
      const invoiceId = `${team.organization_team_invoice_id}`;
      const invoice = invoiceMap.items[invoiceId];
      const userIds = team.organization_user_ids;
      const users: OrganizationUser[] = [];
      userIds.forEach((id: number) => {
        if (userMap.items[`${id}`] !== undefined) {
          users.push(userMap.items[`${id}`]);
          userMap.items[`${id}`].organization_team_ids.push(team.id);
        }
      });
      result.items[`${team.id}`] = {
        ...team,
        invoice,
        users
      };
    });
    return result;
  }, [organizationTeams, organizationUsers, teamInvoices]);

  const isOrgAdmin = useMemo<boolean>(() => {
    return currentOrganization?.role === "admin";
  }, [currentOrganization]);

  if (isLoading) {
    return null;
  }

  return (
    <main>
      <SidebarProvider expandedByDefault={true}>
        {isOrgAdmin && (
          <Sidebar className={styles.sidebar}>
            <NavLink
              to={`/organizations-db/${slug}/organization-info`}
              className={styles.navlink}
            >
              <SidebarButton
                iconClassName="fa-info"
                active={
                  history.location.pathname ===
                  `/organizations-db/${slug}/organization-info`
                }
              >
                DASHBOARD
              </SidebarButton>
            </NavLink>

            <NavLink
              to={`/organizations-db/${slug}/datasets`}
              className={styles.navlink}
            >
              <SidebarButton
                iconClassName="fa-database"
                active={history.location.pathname.startsWith(
                  `/organizations-db/${slug}/datasets`
                )}
              >
                DATASETS
              </SidebarButton>
            </NavLink>
            <NavLink
              to={`/organizations-db/${slug}/users`}
              className={styles.navlink}
            >
              <SidebarButton
                iconClassName="fa-user"
                active={history.location.pathname.startsWith(
                  `/organizations-db/${slug}/users`
                )}
              >
                MEMBERS
              </SidebarButton>
            </NavLink>
            <NavLink
              to={`/organizations-db/${slug}/api-keys`}
              className={styles.navlink}
            >
              <SidebarButton
                iconClassName="fa-key"
                active={history.location.pathname.startsWith(
                  `/organizations-db/${slug}/api-keys`
                )}
              >
                API KEYS
              </SidebarButton>
            </NavLink>
          </Sidebar>
        )}
        <SidebarContent sidebar={isOrgAdmin}>
          <Switch>
            <Route
              path={`/organizations-db/${slug}`}
              key={`/organizations-db/${slug}`}
              exact
              render={() => (
                <Redirect to={`/organizations-db/${slug}/organization-info`} />
              )}
            />
            {currentOrganization && !isOrganizatinDataLoading && (
              <>
                <Route
                  path={`/organizations-db/${slug}/organization-info`}
                  key={`/organizations-db/${slug}/organization-info`}
                >
                  <OrganizationInfoPage
                    currentOrganization={currentOrganization}
                    isOrgAdmin={isOrgAdmin}
                    teamInvoices={teamInvoices}
                    organizationUsers={organizationUsers}
                    listData={listData}
                    slug={slug}
                  />
                </Route>
                <Route
                  path={`/organizations-db/${slug}/datasets/:id?`}
                  key={`/organizations-db/${slug}/datasets/:id?`}
                >
                  <OrganizationDatasetPage
                    listData={listData}
                    slug={slug}
                    organizationUsers={organizationUsers}
                    requestOrganizationData={requestOrganizationData}
                    organizationId={currentOrganization.id}
                  />
                </Route>
                <Route
                  path={`/organizations-db/${slug}/users/:id?`}
                  key={`/organizations-db/${slug}/users/:id?`}
                >
                  <OrganizationMemberPage
                    organizationUsers={organizationUsers}
                    slug={slug}
                    listData={listData}
                    organizationId={currentOrganization.id}
                    requestOrganizationData={requestOrganizationData}
                  />
                </Route>
                <Route
                  path={`/organizations-db/${slug}/api-keys`}
                  key={`/organizations-db/${slug}/api-keys`}
                  exact
                >
                  <OrganizationApiPage
                    organizationId={currentOrganization.id}
                    slug={slug}
                  />
                </Route>
                <Route
                  path={`/organizations-db/${slug}/api-keys/:id`}
                  key={`/organizations-db/${slug}/api-keys/:id`}
                >
                  <OrganizationApiDetailPage
                    slug={slug}
                    organizationTeams={organizationTeams}
                  />
                </Route>
              </>
            )}
          </Switch>
        </SidebarContent>
      </SidebarProvider>
    </main>
  );
}

export default OrganizationDashboard;
