import React from "react";
import { Helmet } from "react-helmet-async";

import metaData from "../../translations/metadata/en-us.json";
import { MetaDataTranslation } from "../../api/types";

interface PageMetadataProps {
  metadataKey: string;
  name?: string;
  code?: string;
}

const PageMetadata = ({
  metadataKey,
  name = undefined,
  code = undefined
}: PageMetadataProps) => {
  let title = metaData.html.titles[metadataKey as keyof MetaDataTranslation];
  if (code) title = title.replace("{code}", code);
  if (name) title = title.replace("{name}", name);

  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
};

export default PageMetadata;
