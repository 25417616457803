// eslint-disable-next-line import/no-extraneous-dependencies
import { Integrations } from "@segment/analytics-next";

// INTEGRATIONS
const GOOGLE_ANALYTICS = "Google Analytics";
const MAILCHIMP = "MailChimp";
const LINKEDIN_INSIGHT_TAG = "LinkedIn Insight Tag";
const FACEBOOK_PIXEL = "Facebook Pixel";
const TWITTER_ADS = "Twitter Ads";
const MIXPANEL = "Mixpanel";
const ALGOLIA_INSIGHTS = "Algolia Insights";

export const ALGOLIA_INTEGRATION_ONLY: Integrations = {
  All: false,
  Integrations: { [ALGOLIA_INSIGHTS]: true }
};

// INTEGRATION SETTING GROUPS
const PERFORMANCE_INTEGRATIONS: string[] = [GOOGLE_ANALYTICS];
const FUNCTIONALITY_INTEGRATIONS: string[] = [MAILCHIMP];
const TARGETING_INTEGRATIONS: string[] = [
  LINKEDIN_INSIGHT_TAG,
  FACEBOOK_PIXEL,
  TWITTER_ADS,
  MIXPANEL
];

type PrivacySetting = {
  functionality: boolean;
  performance: boolean;
  targeting: boolean;
  accepted: boolean;
};

const formatActiveIntegrations = (
  activeIntegrations: string[]
): Integrations => {
  const formattedActiveIntegrations: Integrations = {};
  activeIntegrations.forEach(integration => {
    formattedActiveIntegrations[integration] = true;
  });
  formattedActiveIntegrations.All = false;

  return formattedActiveIntegrations;
};

export const convertSettingsToIntegrations = (
  privacySettings?: PrivacySetting
): Integrations => {
  if (!privacySettings) {
    // return defaults if not already configured
    return { All: true };
  }

  let integrationsList: string[] = [];
  if (privacySettings.performance) {
    integrationsList = [...integrationsList, ...PERFORMANCE_INTEGRATIONS];
  }
  if (privacySettings.functionality) {
    integrationsList = [...integrationsList, ...FUNCTIONALITY_INTEGRATIONS];
  }
  if (privacySettings.targeting) {
    integrationsList = [...integrationsList, ...TARGETING_INTEGRATIONS];
  }

  return formatActiveIntegrations(integrationsList);
};
