import React from "react";

import { IStructuredTextCallOutFields } from "../../../../../contentful/__generated__/types";
import "./style.scss";

type Props = {
  fields: IStructuredTextCallOutFields;
};

const CallOut: React.FC<Props> = ({ fields }) => {
  const { body, heading, textColor, backgroundImage } = fields;

  const sectionStyle = {
    backgroundImage: `url(https://${backgroundImage?.fields.file.url})`,
    color: textColor
  };

  return (
    <div
      style={sectionStyle}
      className={`callout-structured-section-wrapper ${
        backgroundImage ? "" : "callout-less-padding"
      } `}
    >
      <div className="callout-structured-section-content">
        <div className="callout-structured-sub-banner-container">
          <h3 className="callout-structured-sub-banner-header">{heading}</h3>
          <p
            className="callout-structured-sub-banner-body"
            style={{ color: textColor }}
          >
            {body}
          </p>
        </div>
      </div>
    </div>
  );
};

// eslint-disable-next-line import/prefer-default-export
export { CallOut as CallOutStructuredText };
