import React from "react";
import "./style.scss";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, MARKS, Node } from "@contentful/rich-text-types";

import { LinkButton } from "../../button/link";
import { IRichTextBasicFields } from "../../../../../contentful/__generated__/types";
import { ActionButton } from "../../button/action";

const Code = ({ children }: { children: any }) => (
  <code className="basic__rich-page-code">{children}</code>
);

const RICHTEXT_OPTIONS = {
  renderMark: {
    [MARKS.CODE]: (text: any) => <Code>{text}</Code>
  },
  renderNode: {
    [BLOCKS.EMBEDDED_ENTRY]: ({ data }: Node) => {
      const { theme, label, url } = data.target.fields;
      const { action } = data.target.fields;

      return action ? (
        <div className="basic__rich-button">
          <ActionButton fields={data.target.fields} />
        </div>
      ) : (
        <div className="basic__rich-button">
          <LinkButton theme={theme} label={label} url={url} />
        </div>
      );
    },
    [BLOCKS.EMBEDDED_ASSET]: ({ data }: Node) => {
      const { title, file } = data.target.fields;

      return <img src={`https://${file.url}`} alt={title} />;
    },
    [BLOCKS.HEADING_1]: (node: any) => {
      const { content } = node;

      return <h1 className="with-top-blue-line">{content[0].value}</h1>;
    }
  }
};

type Props = {
  fields: IRichTextBasicFields;
};

const Basic: React.FC<Props> = ({ fields }) => {
  const { body } = fields;

  return (
    <section className="basic__rich-page-section">
      <div className="basic__rich-fact-row">
        <div className="basic__rich-t-grid-column-wide">
          {!!body && documentToReactComponents(body, RICHTEXT_OPTIONS)}
        </div>
      </div>
    </section>
  );
};

// eslint-disable-next-line import/prefer-default-export
export { Basic as BasicRichText };
