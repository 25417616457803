import { AppAction, InternalCatalogueTokenRecord } from "./types";

import { Types } from "./index";

/**
 * disabling import/prefer-default-export here to export behaviours
 * consistent with other reducers
 */
// eslint-disable-next-line import/prefer-default-export
export const internalCatalogueTokenReducer = (
  state: InternalCatalogueTokenRecord[],
  action: AppAction
) => {
  switch (action.type) {
    case Types.SetInternalCatalogueToken: {
      const newCache = state.filter(
        entry =>
          entry.userId !== action.payload.userId ||
          entry.companyId !== action.payload.companyId
      );

      const newRecord: InternalCatalogueTokenRecord = {
        ...action.payload,
        get isValid() {
          return this.validUntil > Date.now();
        }
      };

      newCache.push(newRecord);
      return newCache;
    }
    case Types.RemoveInternalCatalogueToken:
      return state.filter(
        entry =>
          entry.userId !== action.payload.userId &&
          entry.companyId !== action.payload.companyId
      );
    case Types.ClearInternalCatalogueTokens:
      return [];
    default:
      return state;
  }
};
