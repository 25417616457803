import { Button, FormField, Modal, ModalHeader } from "@nef/core";
import React, { useState } from "react";

import { postLead } from "../../../api/api";
import { LeadRequest } from "../../../api/types";
import { useGlobalModalContext } from "../../../context/modal-context";
import "./style.scss";
import useTrackEvent from "../../../analytics/hooks/useTrackEvent";

const phoneCheck = (phone: string) => /^[0-9]+$/.test(phone);
export const emailCheck = (email: string) =>
  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);

const handleErrors = (
  name: string,
  email: string,
  company: string,
  phone: string
) => {
  const errors = {
    name: name === "" ? "Name - can't be blank" : "",
    email:
      email === ""
        ? "Email - can't be blank, email - is invalid"
        : !emailCheck(email)
        ? "Email - is invalid"
        : "",
    company: company === "" ? "Company - can't be blank" : "",
    phone:
      phone === ""
        ? "Phone - can't be blank, phone - is invalid"
        : phone.length < 9 || !phoneCheck(phone)
        ? "Phone - is invalid"
        : ""
  };

  return errors;
};

const ADPLeadForm = () => {
  const trackEvent = useTrackEvent();
  const { hideModal } = useGlobalModalContext();
  const [aum] = useState<any>([
    { value: 171126, title: "< $100m" },
    { value: 171127, title: "$100m - $499m" },
    { value: 171128, title: "$500m - $999m" },
    { value: 171129, title: "> $1b" },
    { value: 171130, title: "Not applicable" }
  ]);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    company: "",
    phone: "",
    aum: undefined,
    comments: undefined
  });

  const [formErrors, setFormErrors] = useState({
    name: "",
    email: "",
    company: "",
    phone: ""
  });

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const errors = handleErrors(
      formData?.name,
      formData?.email,
      formData?.company,
      formData?.phone
    );

    setFormErrors(errors);

    const leadRequestParams: LeadRequest = {
      name: formData?.name,
      email: formData?.email,
      company: formData?.company,
      phone: formData?.phone,
      aum: formData?.aum ? Number(formData?.aum) : null,
      comments: formData?.comments ?? null,
      "referral-url": window.location.href,
      referrer: window.document.referrer,
      key: "alt-data-contact-sales-cta",
      "interested-in": "Alternative Data",
      "lead-type": "Institutional Lead",
      title: null,
      "firm-type": null,
      reason: null,
      plan: null,
      "lead-category": null
    };

    const hasErrors = Object.values(errors).some(error => error);

    if (!hasErrors) {
      try {
        postLead(leadRequestParams)
          .then(() => {
            const properties = {
              form_data: JSON.stringify(leadRequestParams),
              submission_origin:
                "forms/institutional-lead@alt-data-request-access"
            };
            trackEvent("Sales Form Submitted", properties);
          })
          .catch(error => {
            return error;
          });

        setFormData({
          name: "",
          email: "",
          company: "",
          phone: "",
          aum: undefined,
          comments: undefined
        });
        setFormErrors({
          name: "",
          email: "",
          company: "",
          phone: ""
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log("data:::", error);
      }
      hideModal();
    }
  };

  return (
    <Modal size="sm" isOpen={true} toggle={hideModal} data-testid="ADPLeadForm">
      <ModalHeader toggle={hideModal} />
      <div className="modal-content">
        <div className="contact-sales-modal-container">
          <div className="contact-sales-modal-heading-container">
            <h4 className="contact-sales-modal-heading with-top-blue-line">
              For Investors
            </h4>
            <p className="contact-sales-modal-description">
              Contact us to learn more about our alternative data products.
            </p>
          </div>

          <form
            className="contact-sales-modal-form-container"
            onSubmit={handleFormSubmit}
          >
            {formErrors.name && (
              <p className="error-message">{formErrors.name}</p>
            )}
            <FormField
              id="name"
              name="name"
              type="text"
              placeholder="Name"
              value={formData.name}
              onChange={handleInputChange}
            />

            {formErrors.email && (
              <p className="error-message">{formErrors.email}</p>
            )}
            <FormField
              id="email"
              name="email"
              type="text"
              placeholder="Email"
              value={formData.email}
              onChange={handleInputChange}
            />

            {formErrors.company && (
              <p className="error-message">{formErrors.company}</p>
            )}
            <FormField
              type="text"
              placeholder="Company"
              name="company"
              id="company"
              value={formData.company}
              onChange={handleInputChange}
            />

            {formErrors.phone && (
              <p className="error-message">{formErrors.phone}</p>
            )}
            <FormField
              type="tel"
              placeholder="Phone number"
              name="phone"
              id="phone"
              value={formData.phone}
              onChange={handleInputChange}
            />

            <FormField
              type="select"
              name="aum"
              id="aum"
              value={formData.aum}
              onChange={handleInputChange}
            >
              <option
                className="placeholder-text"
                value={undefined}
                placeholder="AUM"
                selected
                disabled
              >
                AUM
              </option>
              {aum?.map((option: { value: number; title: string }) => (
                <option value={option?.value} key={option?.value}>
                  {option?.title}
                </option>
              ))}
            </FormField>

            <FormField
              id="comments"
              name="comments"
              type="textarea"
              cols={100}
              rows={5}
              placeholder="Comments"
              value={formData.comments}
              onChange={handleInputChange}
            />

            <Button type="submit" style={{ width: "100%" }}>
              Submit
            </Button>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default ADPLeadForm;
