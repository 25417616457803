import useTrackEvent from "./useTrackEvent";

export const useTrackSignUpClick = () => {
  const trackEvent = useTrackEvent();

  const trackEventFn = (productCode = null) =>
    trackEvent("Clicked Sign Up", {
      Path: window.location.pathname,
      Title: document.title,
      Product: productCode
    });
  return trackEventFn;
};

export const useTrackExternalDocsClick = () => {
  const trackEvent = useTrackEvent();

  const trackEventFn = (link: string, productCode?: string) =>
    trackEvent("Visited Docs", {
      Path: window.location.pathname,
      "Link Clicked": link,
      Product: productCode
    });
  return trackEventFn;
};
