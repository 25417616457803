import React from "react";
import {
  Modal,
  Button,
  ModalFooter,
  ModalBody,
  NotificationHub
} from "@nef/core";

import { agreeTermsOfUseById } from "../../api/api";
import { useGlobalModalContext } from "../../context/modal-context";

import "./style.scss";

export const AcceptTouModal = ({ children }: any) => {
  const { hideModal } = useGlobalModalContext();
  const acceptTermsAndCloseModal = async () => {
    try {
      await agreeTermsOfUseById(orgId?.toString());
    } catch (e) {
      NotificationHub.send(
        "danger",
        "Unfortunately, something went wrong. Please refresh this page and try again."
      );
    }
    hideModal();
    window.location.reload();
  };

  const { name, orgName, orgId } = children.props;

  return (
    <Modal size="md" isOpen={true} onBlur={acceptTermsAndCloseModal}>
      <div className="accept-tou-wrapper">
        <ModalBody>
          <h3 className="greeting">Hi {name},</h3>
          <p className="modal-message">
            To gain access to the premium data available to you through your
            association with {orgName}, you must accept the{" "}
            <a
              href={`/organizations/${orgName
                ?.split(" ")
                ?.join("-")}/terms-of-use`}
              className="terms-and-conditions-link"
              target="_blank"
              rel="noreferrer"
            >
              terms and conditions
            </a>
            .
          </p>
        </ModalBody>

        <ModalFooter>
          <Button
            size="md"
            key="confirm"
            onClick={acceptTermsAndCloseModal}
            data-testid="agree-button"
          >
            I agree to the terms
          </Button>{" "}
        </ModalFooter>
      </div>
    </Modal>
  );
};

export default AcceptTouModal;
