import React from "react";

import "./style.scss";

type Equity = {
  date: string;
  ranking: string;
  ticker: string;
  activity: string;
  recommendation: string;
};

type UrebTableProps = {
  currentDate: string;
  equities: Equity[];
  label?: string;
};

const UrebTable = ({
  currentDate,
  equities,
  label = "Top traded retail equities for "
}: UrebTableProps) => {
  // User login must exist
  const userIsLoggedIn = false;
  const equityIcon = (equity: any) => {
    switch (equity) {
      case equity.ranking > equity.previous_ranking:
        return (
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/retail-equity-benchmark/Icons/Arrow-red.svg"`}
            className="ranking-indicator"
            alt="Rank down"
          />
        );
      case equity.ranking < equity.previous_ranking:
        return (
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/retail-equity-benchmark/Icons/Arrow-green.svg"`}
            className="ranking-indicator"
            alt="Rank up"
          />
        );
      default:
        return (
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/retail-equity-benchmark/Icons/neutral-blk-bar.svg`}
            className="ranking-indicator ranking-indicator--neutral"
            alt="Same rank"
          />
        );
    }
  };

  return (
    <div data-testid="ureb-table" id="scroll-point" className="table-wrapper">
      <h1 className="table-header">
        {label}{" "}
        <span data-testid="date" className="table-header--blue">
          {currentDate}
        </span>
      </h1>
      <p className="table-body">Updated daily at 6am EST.</p>
      <div className="table">
        <table>
          <col style={{ width: "250px" }} />
          <col style={{ width: "170px" }} />
          <col style={{ width: "190px" }} />
          <col style={{ width: "175px" }} />
          <tr>
            <th>Rank</th>
            <th>Ticker</th>
            <th>Activity</th>
            <th>Buy/Sell</th>
          </tr>
          {equities.map(topEquity => {
            const parsedFloat = parseFloat(topEquity.activity);
            const floatAsPercentage = parsedFloat * 100;
            const roundedPercentage = Math.round(floatAsPercentage * 100) / 100;

            const capitalizedFirstLetter = topEquity.recommendation
              .charAt(0)
              .toUpperCase();
            const lowercaseBody = topEquity.recommendation
              .substring(1)
              .toLowerCase();
            const capitalizedRecommendation =
              capitalizedFirstLetter + lowercaseBody;

            return (
              <tr data-testid="equity" key={topEquity.ticker}>
                <td>
                  <span>{topEquity.ranking}</span>
                  {equityIcon(topEquity)}
                </td>
                <td>
                  <strong>{topEquity.ticker}</strong>
                </td>
                <td>{roundedPercentage}</td>
                <td>{capitalizedRecommendation}</td>
              </tr>
            );
          })}
        </table>

        {!userIsLoggedIn && (
          <div className="table-teaser">
            <img
              className="table-image"
              src={`${process.env.PUBLIC_URL}/assets/images/retail-equity-benchmark/teaser-img.png`}
              alt="blurred table sample"
            />
            <div className="table-banner">
              <h2 className="banner-header">view the next 6-10 now</h2>
              <div className="buttons">
                {/* Implement redirection logic for this section */}
                <a
                  href="login"
                  className="button b-button b-button--white-empty"
                >
                  log in
                </a>
                <a href="sign-up" className="button b-button b-button--yellow">
                  sign up
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UrebTable;
