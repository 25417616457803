import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { trackPageEvent } from "../analytics";
import { useSegmentIntegrationContext } from "../../context/segment-context";

const useTrackCurrentPage = () => {
  const location = useLocation();
  const integrations = useSegmentIntegrationContext();

  useEffect(() => {
    const { title } = document;
    const url = `${window.location.protocol}//${window.location.hostname}${window.location.pathname}`;
    const path = window.location.pathname;
    const properties = { title, url, path };

    trackPageEvent(properties, integrations);
  }, [location]);
};

export default useTrackCurrentPage;
