import React, { useState } from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

import { IAccordionRichTextFields } from "../../../../../contentful/__generated__/types";

import "./style.scss";

type Props = {
  fields: IAccordionRichTextFields;
};

const THEMES = [
  { name: "Dark", class: "dark-background" },
  { name: "Light", class: "light-background" }
];

const RichText: React.FC<Props> = ({ fields }) => {
  const { theme, items } = fields;

  const themeClass = THEMES.find(t => t.name === theme)?.class;

  const [expanded, setExpanded] = useState(Array(items?.length).fill(false));
  const toggleSection = (index: number) => {
    const newExpanded = [...expanded];
    newExpanded[index] = !newExpanded[index];
    setExpanded(newExpanded);
  };

  return (
    <section className="accordion-richtext-wrapper">
      <div className={`accordion-rich-text ${themeClass}`}>
        <div className="accordion-rich-text-container">
          <dl className="accordion-rich-text-border">
            <div className="grid-left">
              {!!items &&
                items.map((item, index) => {
                  return (
                    index % 2 === 0 && (
                      <div
                        className="accordion-rich-text-item accordion-left"
                        key={item.sys.id}
                        onClick={() => toggleSection(index)}
                        aria-expanded={expanded[index]}
                      >
                        <dt className="accordion-rich-text-button-container">
                          <button
                            type="button"
                            className="reset-button accordion-button"
                          >
                            <h3 className="accordion-rich-text-heading">
                              {item.fields.heading}
                            </h3>
                            <div
                              className="accordion-rich-text-header__icon"
                              aria-expanded={!expanded[index]}
                            />
                          </button>
                        </dt>

                        <dd
                          className="accordion-rich-text-content"
                          aria-expanded={!expanded[index]}
                        >
                          {!!item.fields.body &&
                            documentToReactComponents(item.fields.body)}
                        </dd>
                      </div>
                    )
                  );
                })}
            </div>

            <div className="grid-right">
              {!!items &&
                items.map((item, index) => {
                  return (
                    index % 2 !== 0 && (
                      <div
                        className="accordion-rich-text-item accordion-right"
                        key={item.sys.id}
                        onClick={() => toggleSection(index)}
                        aria-expanded={expanded[index]}
                      >
                        <dt className="accordion-rich-text-button-container">
                          <button
                            type="button"
                            className="reset-button accordion-button"
                          >
                            <h3 className="accordion-rich-text-heading">
                              {item.fields.heading}
                            </h3>
                            <div
                              className="accordion-rich-text-header__icon"
                              aria-expanded={!expanded[index]}
                            />
                          </button>
                        </dt>

                        <dd
                          className="accordion-rich-text-content"
                          aria-expanded={!expanded[index]}
                        >
                          {!!item.fields.body &&
                            documentToReactComponents(item.fields.body)}
                        </dd>
                      </div>
                    )
                  );
                })}
            </div>
          </dl>
        </div>
      </div>
    </section>
  );
};

// eslint-disable-next-line import/prefer-default-export
export { RichText as AccordionRichText };
