import React from "react";
import { Route } from "react-router-dom";

import useAuth from "../../hooks/useAuth";

import NdlLoginRedirect from "./NdlLoginRedirect";

const ProtectedRoute = ({ component: Component, ...rest }: any) => {
  const { authenticated } = useAuth();

  return (
    <Route
      {...rest}
      render={(props): any => {
        if (!authenticated()) {
          return <NdlLoginRedirect />;
        }

        return <Component {...rest} {...props} />;
      }}
    />
  );
};

export default ProtectedRoute;
