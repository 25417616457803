import React from "react";

import { ICtaButtonFields } from "../../../../../contentful/__generated__/types";
import { ActionButton } from "../../button/action";
import { LinkButton } from "../../button/link";
import "./style.scss";

type Props = {
  fields: ICtaButtonFields;
};

const THEMES = [
  { name: "Blue to Purple", class: "cta__button-background__blue_purple" },
  { name: "Green to Blue", class: "cta__button-background__green_blue" }
];

const Button: React.FC<Props> = ({ fields }) => {
  const { theme, heading1, body, primaryButton, secondaryButton, label } =
    fields;

  const themeClass = THEMES.find(t => t.name === theme)?.class;
  const className = `cta-button__banner ${themeClass}`;

  return (
    <div className="cta-button">
      <div className="cta-button__wrapper">
        <div className={className}>
          <h4 className="cta-button__create-header">{label}</h4>
          <h2 className="cta-button__banner-header">{heading1}</h2>
          <p className="cta-button__banner-body">{body}</p>
          <div className="cta-button__buttons">
            {primaryButton?.fields ? (
              "action" in primaryButton?.fields ? (
                <div className="header__action-button">
                  <ActionButton fields={primaryButton?.fields} />
                </div>
              ) : (
                <LinkButton
                  theme={primaryButton?.fields?.theme}
                  label={primaryButton?.fields?.label}
                  url={primaryButton?.fields?.url}
                />
              )
            ) : null}
            {secondaryButton?.fields ? (
              "action" in secondaryButton?.fields ? (
                <div className="header__action-button">
                  <ActionButton fields={secondaryButton?.fields} />
                </div>
              ) : (
                <LinkButton
                  theme={secondaryButton?.fields?.theme}
                  label={secondaryButton?.fields?.label}
                  url={secondaryButton?.fields?.url}
                />
              )
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

// eslint-disable-next-line import/prefer-default-export
export { Button as CTAButton };
