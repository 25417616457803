import React, { useState } from "react";
import {
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormField,
  NotificationHub
} from "@nef/core";

import { deleteOrganizationApiKey } from "../../../api/api";
import { useGlobalModalContext } from "../../../context/modal-context";

type Props = {
  apiKeyId: string;
  apiKeyName: string;
  finishCallback?: () => void;
};

const DeleteApiKeyModal = ({ apiKeyId, apiKeyName, finishCallback }: Props) => {
  const { hideModal } = useGlobalModalContext();
  const [disabled, setDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const deleteApiKeyAndCloseModal = async () => {
    setIsLoading(true);
    try {
      await deleteOrganizationApiKey(apiKeyId);
      NotificationHub.send("primary", "Your api key has been deleted.");
    } catch (e) {
      NotificationHub.send("danger", "Unable to delete api key.");
    }
    setIsLoading(false);
    hideModal();
    finishCallback?.();
  };

  const hideOnOutsideClick = () => {
    hideModal();
  };

  const validateApiName = (name: string) => {
    if (apiKeyName === name) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  return (
    <Modal size="md" isOpen={true} toggle={() => hideOnOutsideClick()}>
      <ModalHeader>Delete API Key?</ModalHeader>
      <ModalBody>
        <p>This will permanently delete the API Key "{apiKeyName}".</p>
        <p>* Type the name of the API key to confirm.</p>
        <FormField
          type="text"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            validateApiName(event?.target?.value)
          }
        />
      </ModalBody>
      <ModalFooter>
        <Button
          key="cancel"
          outline={true}
          onClick={hideModal}
          data-testid="agree-button"
        >
          Cancel
        </Button>
        <Button
          key="confirm"
          onClick={deleteApiKeyAndCloseModal}
          isLoading={isLoading}
          disabled={disabled}
          data-testid="agree-button"
        >
          Yes
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteApiKeyModal;
