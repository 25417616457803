import React, { useEffect, useState, useMemo, useRef } from "react";
import { NavLink, useParams } from "react-router-dom";
import * as Tooltip from "@radix-ui/react-tooltip";
import { InfoCircledIcon } from "@radix-ui/react-icons";
import { Button, NotificationHub } from "@nef/core";
import moment from "moment";

import {
  getOrganizationTeamUsers,
  removeUserFromOrganizationTeam
} from "../../../../api/api";
import {
  MODAL_TYPES,
  useGlobalModalContext
} from "../../../../context/modal-context";
import ConfirmationModal from "../../../../components/modals/ConfirmationModal";
import type {
  OrganizationTeamUser,
  OrganizationUser
} from "../../../../api/types";
import styles from "../style.module.scss";
import type { NormalizedOrganizationTeam } from "../index";
import UserIcon from "../../../../components/modals/add-users-org-dataset/components/user-icon/UserIcon";

export default function OrganizationDatasetPage({
  listData = { keys: [], items: {} },
  slug,
  organizationUsers = [],
  requestOrganizationData,
  organizationId
}: {
  listData: NormalizedOrganizationTeam;
  slug: string;
  organizationUsers: OrganizationUser[];
  requestOrganizationData: (_: number) => void;
  organizationId: number;
}) {
  const { showModal } = useGlobalModalContext();
  const { id } = useParams<{ id?: string }>();
  const currentDataset = useMemo(() => {
    if (id) {
      return listData.items[id];
    }
    return null;
  }, [id, listData]);

  const mapForOrgUserToOrgTeamUserRef = useRef<Record<string, string>>({});
  useEffect(() => {
    mapForOrgUserToOrgTeamUserRef.current = {};
    if (
      !currentDataset ||
      currentDataset.organization_team_user_ids.length === 0
    )
      return;

    getOrganizationTeamUsers(currentDataset.organization_team_user_ids).then(
      response =>
        response.data.organization_team_users.forEach(
          (teamUser: OrganizationTeamUser) => {
            const userId = `${teamUser.organization_user_id}`;
            const teamUserId = `${teamUser.id}`;
            mapForOrgUserToOrgTeamUserRef.current[userId] = teamUserId;
          }
        )
    );
  }, [currentDataset]);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const currentDeleteUserIdRef = useRef<string | null>(null);

  const handleConfirm = () => {
    setIsModalOpen(false);
    if (currentDeleteUserIdRef.current && id) {
      removeUserFromOrganizationTeam(currentDeleteUserIdRef.current)
        .then(() => {
          NotificationHub.send("primary", "Delete user access successfully!", {
            onClick: () => NotificationHub.dismiss(),
            position: "top-right",
            closeOnClick: true
          });
          requestOrganizationData(organizationId);
        })
        .catch(() => {
          NotificationHub.send(
            "danger",
            "Sorry, delete user access failed, please try again",
            {
              onClick: () => NotificationHub.dismiss(),
              position: "top-right",
              closeOnClick: true
            }
          );
        });
    }

    currentDeleteUserIdRef.current = null;
  };

  return (
    <div className={styles.datasetPage}>
      <div className={styles.datasetList}>
        {listData.keys.map(key => {
          const team = listData.items[key];
          return (
            <NavLink
              to={`/organizations-db/${slug}/datasets/${team.id}`}
              className={styles.datasetItem}
              activeClassName={styles.active}
              key={team.id}
            >
              <div
                className={`${styles.status} ${
                  team.invoice?.trial ? styles.blue : styles.green
                }`}
              >
                {team.invoice?.trial ? "Trial" : "Subscription"}
              </div>
              <div className={styles.productName}>{team.name}</div>
            </NavLink>
          );
        })}
      </div>
      {currentDataset && (
        <Tooltip.Provider delayDuration={500}>
          <div className={styles.datasetContent}>
            <div className={styles.datasetInfo}>
              <div
                className={`${styles.status} ${
                  currentDataset.invoice?.trial ? styles.blue : styles.green
                }`}
              >
                {currentDataset.invoice?.trial ? "Trial" : "Subscription"}
              </div>
              <h2 className={styles.datasetName}>{currentDataset.name}</h2>
              <div className={`${styles.sectionInfo} ${styles.sectionFlex}`}>
                <div className={styles.sectionItem}>
                  <h3 className={styles.sectionTitle}>HISTORY OPTION</h3>
                  <div>{currentDataset.invoice?.historyOption || "N/A"}</div>
                </div>
                <div className={styles.sectionItem}>
                  <h3 className={styles.sectionTitle}>SUBSCRIPTION PERIOD</h3>
                  <div>
                    {moment(currentDataset.invoice?.startDate).format("ll")}
                    {" - "}
                    {currentDataset.invoice?.yearsCommitment
                      ? moment(currentDataset.invoice?.startDate)
                          .add(currentDataset.invoice.yearsCommitment, "y")
                          .format("ll")
                      : "N/A"}
                  </div>
                </div>
              </div>
              <div className={styles.sectionInfo}>
                <div>
                  <Tooltip.Root>
                    <h3 className={styles.sectionTitle}>
                      BUSINESS CONTACTS
                      <Tooltip.Trigger asChild>
                        <InfoCircledIcon className={styles.infoIcon} />
                      </Tooltip.Trigger>
                    </h3>

                    <Tooltip.Portal>
                      <Tooltip.Content className={styles.tooltipContent}>
                        Contact information Nasdaq Data Link has on file as the
                        primary business contact. This is who will be contacted
                        by our sales and finance team for account related
                        issues.
                        <Tooltip.Arrow className={styles.tooltipArrow} />
                      </Tooltip.Content>
                    </Tooltip.Portal>
                  </Tooltip.Root>
                  <div>
                    {currentDataset.invoice?.businessContacts.map(
                      (v: string) => (
                        <div key={`business-contact-${v}`}>{v}</div>
                      )
                    )}
                  </div>
                </div>
              </div>

              <div className={styles.sectionInfo}>
                <Tooltip.Root>
                  <h3 className={styles.sectionTitle}>
                    TECHNICAL CONTACTS
                    <Tooltip.Trigger asChild>
                      <InfoCircledIcon className={styles.infoIcon} />
                    </Tooltip.Trigger>
                  </h3>

                  <Tooltip.Portal>
                    <Tooltip.Content className={styles.tooltipContent}>
                      Contact information of non-Nasdaq Data Link users who need
                      to be notified if there is any interruption in the
                      delivery of this dataset. All members with access are
                      notified by default. Please contact your account manager
                      to modify this list.
                      <Tooltip.Arrow className={styles.tooltipArrow} />
                    </Tooltip.Content>
                  </Tooltip.Portal>
                </Tooltip.Root>
                <div>
                  {currentDataset.invoice?.technicalContacts.map(
                    (v: string) => (
                      <div key={`technical-contact-${v}`}>{v}</div>
                    )
                  )}
                </div>
              </div>
            </div>
            <div className={styles.listContainer}>
              <div className={styles.subHeaderContainer}>
                {currentDataset.users && currentDataset.users.length !== 0 ? (
                  <div className={`${styles.subHeader} with-top-blue-line`}>
                    Members With Access
                  </div>
                ) : (
                  <div className={`${styles.subHeader} with-top-blue-line`}>
                    No user has access for this dataset
                  </div>
                )}

                <Button
                  id="add-user-button"
                  onClick={() => {
                    showModal(MODAL_TYPES.ADD_USERS_TO_ORG_DATASET, {
                      allUsersInOrg: organizationUsers,
                      allUsersInCurrentTeam: currentDataset.users,
                      orgTeamId: currentDataset.id,
                      slug,
                      onAddUsersAndCloseModal: () =>
                        requestOrganizationData(organizationId)
                    });
                  }}
                >
                  + ADD USERS
                </Button>
              </div>
              {currentDataset.users && currentDataset.users.length !== 0 && (
                <div className={styles.userList}>
                  {currentDataset.users
                    .filter((user: OrganizationUser) => !user.api_client)
                    .map((user: OrganizationUser) => (
                      <div key={user.id} className={styles.userRow}>
                        <div className={styles.memberItemInfo}>
                          <UserIcon fullName={String(user.full_name)} />

                          <div>
                            <p className={styles.memberItemName}>
                              {user.full_name || "N/A"}
                              {!user.invitation_accepted && (
                                <div className={styles.pending}>Pending</div>
                              )}
                            </p>
                            <p className={styles.memberItemDesc}>
                              {user.email}
                            </p>
                          </div>
                        </div>

                        <Button
                          color="link"
                          outline
                          size="sm"
                          onClick={() => {
                            setIsModalOpen(true);
                            currentDeleteUserIdRef.current =
                              mapForOrgUserToOrgTeamUserRef.current[user.id];
                          }}
                        >
                          REMOVE
                        </Button>
                      </div>
                    ))}
                </div>
              )}
            </div>

            <ConfirmationModal
              isOpen={isModalOpen}
              onConfirm={handleConfirm}
              onDismiss={() => {
                setIsModalOpen(false);
              }}
              title="REMOVE USER FROM DATA PRODUCT"
              question="Are you sure you want to remove this user from this data product?"
              confirmText="YES, REMOVE"
              dismissText="NO, CANCEL"
            />
          </div>
        </Tooltip.Provider>
      )}
    </div>
  );
}
