import React, { useState } from "react";
import {
  Modal,
  Button,
  ModalFooter,
  ModalHeader,
  ModalBody,
  NotificationHub,
  FormField
} from "@nef/core";
// eslint-disable-next-line import/no-unresolved, import/no-extraneous-dependencies
import { CopyToClipboard } from "react-copy-to-clipboard";

import { useGlobalModalContext } from "../../../context/modal-context";
import { createOrganizationApiKey } from "../../../api/api";
import "./style.scss";

type Props = {
  organizationUserId: string | number;
};

const NewOrgApiKeyModal = ({ organizationUserId }: Props) => {
  const { hideModal } = useGlobalModalContext();
  const [isLoading, setIsLoading] = useState(false);
  const [apiName, setApiName] = useState("");
  const [generatedApiKey, setGeneratedApiKey] = useState<string | undefined>(
    undefined
  );

  const validateAlphanumeric = (name: string): boolean => {
    const matches = name.match(/[a-zA-Z0-9\s]/g);
    if (matches == null || !(matches.join("") === name)) {
      return false;
    }
    return true;
  };

  const createAPIKeyAndCloseModal = async () => {
    const validated = validateAlphanumeric(apiName);
    if (!validated) {
      NotificationHub.send(
        "danger",
        "Only alphanumeric characters are permitted"
      );
      return;
    }

    setIsLoading(true);
    try {
      const apiKey = await createOrganizationApiKey(
        apiName,
        organizationUserId
      );
      setGeneratedApiKey(apiKey.data.attributes.token);
      NotificationHub.send("primary", "Key successfully created.");
    } catch (e) {
      NotificationHub.send("danger", "Unable to create api key.");
    }
    setIsLoading(false);
  };

  const onCopyNotification = () => {
    if (generatedApiKey) {
      NotificationHub.send("primary", "Key copied to clipboard!");
    }
  };

  const hideOnOutsideClick = () => {
    hideModal();
  };

  return (
    <Modal size="md" isOpen={true} toggle={() => hideOnOutsideClick()}>
      <ModalHeader>New API Key</ModalHeader>
      <ModalBody>
        <div className="api-key-code">
          <p className="api-key-code__title">API key name</p>
          <FormField
            type="text"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setApiName(event?.target?.value)
            }
          />
        </div>
        <div className="api-key-code">
          <div className="api-key-code__title">API Key code</div>
          <CopyToClipboard
            text={generatedApiKey || ""}
            onCopy={onCopyNotification}
          >
            <button type="button" className="api-key-code__button">
              <div className="api-key-code__api-key-box">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/icons/copy-api-key.svg`}
                  alt="Bookmarks icon"
                  className="api-key-code__copy-icon"
                />
                <div className="api-key-code__api-key">
                  {generatedApiKey || "Will be generated upon submission"}
                </div>
              </div>
            </button>
          </CopyToClipboard>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button key="confirm" outline={true} onClick={hideModal}>
          Cancel
        </Button>
        {generatedApiKey ? (
          <Button key="confirm" onClick={hideModal}>
            Done
          </Button>
        ) : (
          <Button
            key="confirm"
            onClick={createAPIKeyAndCloseModal}
            isLoading={isLoading}
          >
            Submit
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default NewOrgApiKeyModal;
