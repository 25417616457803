import React from "react";
import { Modal, Button, ModalHeader, ModalFooter } from "@nef/core";

import { useGlobalModalContext } from "../../context/modal-context";
import useAuth from "../../hooks/useAuth";
import { touchSessionStatus } from "../../api/api";

const SessionTimeoutModal = ({
  expired,
  activate
}: {
  expired: boolean;
  activate?: () => void;
}) => {
  const { hideModal, store } = useGlobalModalContext();
  const { modalProps } = store || {};
  const { title } = modalProps || {};
  const { logout } = useAuth();

  const continueSession = () => {
    activate?.();
    touchSessionStatus().then(hideModal);
  };
  const logoutSession = () => {
    logout({
      to: "/login"
    });
    hideModal();
  };

  return (
    <Modal title={title || "Session Timeout Modal"} size="md" isOpen={true}>
      {expired ? (
        <>
          <ModalHeader>Your session has expired</ModalHeader>
          <ModalFooter>
            <Button size="md" color="primary" onClick={logoutSession}>
              Close
            </Button>
          </ModalFooter>
        </>
      ) : (
        <>
          <ModalHeader>Your session is about to expire</ModalHeader>
          <ModalFooter>
            <Button size="md" color="primary" onClick={continueSession}>
              Continue
            </Button>
            <Button size="md" color="link" onClick={logoutSession}>
              Logout
            </Button>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};

export default SessionTimeoutModal;
