import React from "react";

import styles from "./ModalCloseButton.module.scss";

interface ModalCloseButtonProps {
  closeModal: () => void;
}

const ModalCloseButton = ({ closeModal }: ModalCloseButtonProps) => {
  return (
    <span onClick={closeModal} className={styles.button}>
      X
    </span>
  );
};

export default ModalCloseButton;
