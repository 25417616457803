import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import React from "react";

import { IStructuredTextQuoteFields } from "../../../../../contentful/__generated__/types";
import "./style.scss";

type Props = {
  fields: IStructuredTextQuoteFields;
};

const THEMES = [
  { name: "Dark", class: "quote-dark-background" },
  { name: "Light", class: "quote-light-background" }
];

const Quote: React.FC<Props> = ({ fields }) => {
  const { theme, body, heading, label, source } = fields;

  const themeClass = THEMES.find(t => t.name === theme)?.class;
  return (
    <div className={`structured-text-quote ${themeClass}`}>
      <div className="quote-container">
        <div className="quote-heading-container">
          {!!label && <p className="quote-label">{label}</p>}
          <h1 className="quote-heading">{heading}</h1>
        </div>

        <div className="quote-body-container">
          <div className="quote-icon-container">
            <svg
              className="quote-icon"
              viewBox="0 0 40 31"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0.211761 30.7503H15.5527V21.1622C15.5127 14.6104 17.1107 9.81632 19.8673 5.18208L10.7586 0.547852C5.76485 4.70268 0.17181 13.3319 0.211761 21.1622V30.7503ZM20.1869 30.7503H35.5278V21.1622C35.4879 14.6104 37.0859 9.81632 39.8424 5.18208L30.7338 0.547852C25.74 4.70268 20.147 13.3319 20.1869 21.1622V30.7503Z" />
            </svg>
          </div>
          <div className="quote-without-icon">
            <div className="quote-body">
              {!!body && documentToReactComponents(body)}
            </div>
            <p className="quote-source with-top-black-line">{source}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

// eslint-disable-next-line import/prefer-default-export
export { Quote as QuoteStructuredText };
