import React, { useState, FormEvent, useEffect } from "react";
import { Modal, Button, FormField } from "@nef/core";

import { useGlobalModalContext } from "../../context/modal-context";
import {
  HelpscoutArticle,
  HelpscoutCategory,
  Product,
  SupportRequest,
  User
} from "../../api/types";
import {
  createSupportRequest,
  getHelpscoutArticles,
  getHelpscoutCategories,
  getMyInfo
} from "../../api/api";

interface SupportRequestModalProps {
  isLicensePage: boolean;
  product?: Product;
}

export const SupportRequestModal = ({ product }: SupportRequestModalProps) => {
  const { hideModal } = useGlobalModalContext();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [comment, setComment] = useState("");
  const [articles, setArticles] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [categoryId, setCategoryId] = useState<number | null>(null);
  const [categories, setCategories] = useState([]);
  const [referrer, setReferrer] = useState("");
  const [isLicensePage, setIsLicensePage] = useState(false);
  const [productCode, setProductCode] = useState<string | null>(null);
  const [productType, setProductType] = useState<string | null>(null);
  const [user, setUser] = useState<User>({
    first_name: "",
    email: "",
    job_title: "",
    saml_first_login: false,
    api_key: "",
    apiKey: "",
    planned_data_usage_short_form: "",
    administrator: false,
    roles_array: [],
    user_type: "",
    business_type_short_form: "",
    assets_under_management_short_form: ""
  });

  useEffect(() => {
    getHelpscoutArticles().then(data => {
      const orderedArticles = data
        .sort(
          (a: HelpscoutArticle, b: HelpscoutArticle) =>
            b["hs-popularity"] - a["hs-popularity"]
        )
        .slice(0, 5);
      setArticles(orderedArticles);
    });
  }, []);

  useEffect(() => {
    getHelpscoutCategories().then(data => {
      setCategories(data);
    });
  }, []);

  useEffect(() => {
    getMyInfo().then(response => {
      setUser(response.data.user);
    });
  }, []);

  useEffect(() => {
    if (user.first_name) {
      setName(user.first_name);
    }
    setEmail(user.email);
  }, [user]);

  useEffect(() => {
    setReferrer(document.referrer);
  }, []);

  useEffect(() => {
    if (product) {
      setIsLicensePage(product.isLicensePage);
      setProductCode(product.code || null);
      setProductType(product.prodType || null);

      let licenseCategoryId = null;

      categories.forEach((category: HelpscoutCategory) => {
        if (category["hs-name"] === "Licensing") {
          licenseCategoryId = category.id;
        }
      });
      if (licenseCategoryId) {
        setCategoryId(licenseCategoryId);
      }
    }
  }, [product, categories]);

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const supportRequestParams: SupportRequest = {
      name,
      email,
      comment,
      categoryId,
      referrer,
      productCode,
      productType
    };

    createSupportRequest(supportRequestParams).catch(error => {
      return error;
    });
  };

  return (
    <Modal
      size="lg"
      isOpen={true}
      toggle={hideModal}
      data-testid="supportRequestModal"
    >
      <section className="support-request">
        <h1 className="support-request__title with-top-blue-line">
          Have a question?
        </h1>
        <p className="support-request__subtitle">
          Please fill out all the fields. We will get back to you within one to
          two business days.
        </p>

        <form onSubmit={onSubmit}>
          <div className="support-request__content">
            <div className="support-request__form">
              <FormField
                id="name"
                name="name"
                type="text"
                placeholder="Name"
                value={name}
                onChange={e => setName((e.target as HTMLInputElement).value)}
              />

              <FormField
                id="email"
                name="email"
                type="text"
                placeholder="Email"
                value={email}
                onChange={e => setEmail((e.target as HTMLInputElement).value)}
              />

              {!isLicensePage && (
                <FormField
                  id="categoryId"
                  name="categoryId"
                  type="select"
                  placeholder="Category"
                  value={categoryName}
                  onChange={e => {
                    categories.forEach((category: HelpscoutCategory) => {
                      if (
                        category["hs-name"] ===
                        (e.target as HTMLInputElement).value
                      ) {
                        setCategoryName(category["hs-name"]);
                        setCategoryId(parseInt(category.id, 10));
                      }
                    });
                  }}
                >
                  <option value="" disabled>
                    Category
                  </option>
                  {categories.map((category: HelpscoutCategory) => (
                    <option value={category["hs-name"]} key={category.id}>
                      {category["hs-name"]}
                    </option>
                  ))}
                </FormField>
              )}

              <FormField
                id="comment"
                name="comment"
                type="text"
                placeholder="Comment"
                value={comment}
                onChange={e => setComment((e.target as HTMLInputElement).value)}
              />

              <Button type="submit" style={{ width: "100%" }}>
                Submit
              </Button>
            </div>

            {!isLicensePage && (
              <div className="articles">
                <h4 className="articles__header">Popular Articles</h4>
                <ul className="articles__content">
                  {articles.map((article: HelpscoutArticle) => (
                    <li className="articles__link" key={article.id}>
                      <a
                        href={article["hs-public-url"]}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {article["hs-name"]}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </form>
      </section>
    </Modal>
  );
};

export default SupportRequestModal;
