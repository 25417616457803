import React from "react";
import { Modal, Button, ModalBody, ModalFooter } from "@nef/core";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

import { useGlobalModalContext } from "../../../context/modal-context";
import parsePages from "../../../utils/parse-utils";
import "./style.scss";

type Props = {
  teamName: string;
  linkHref: string;
  markdown: any;
};

const DatasetDocumentationModal = ({ teamName, linkHref, markdown }: Props) => {
  const { hideModal } = useGlobalModalContext();
  const parsedPages = parsePages(markdown);

  const hideOnOutsideClick = () => {
    hideModal();
  };

  return (
    <Modal size="md" isOpen={true} toggle={() => hideOnOutsideClick()}>
      <div className="dataset-documentation__header">
        <p className="dataset-documentation__title with-top-blue-line">
          {teamName}
        </p>
        <a href={linkHref}>
          <Button>Open Dataset</Button>
        </a>
      </div>
      <ModalBody>
        {parsedPages.map((page: any) => {
          return (
            <>
              <div className="dataset-documentation__org-doc-section">
                <ReactMarkdown>{page.parent.line}</ReactMarkdown>
                <ReactMarkdown remarkPlugins={[remarkGfm]}>
                  {page.content}
                </ReactMarkdown>
                {page.lines.map((line: string) => {
                  return (
                    <ReactMarkdown key={page.id} remarkPlugins={[remarkGfm]}>
                      {line}
                    </ReactMarkdown>
                  );
                })}
              </div>
              {page.pages.map((subPage: any) => {
                return (
                  <div
                    key={subPage.id}
                    className="dataset-documentation__org-doc-section"
                  >
                    <ReactMarkdown remarkPlugins={[remarkGfm]}>
                      {subPage.parent.line}
                    </ReactMarkdown>
                    <ReactMarkdown remarkPlugins={[remarkGfm]}>
                      {subPage.content}
                    </ReactMarkdown>
                  </div>
                );
              })}
            </>
          );
        })}
      </ModalBody>
      <ModalFooter>
        <Button key="confirm" onClick={hideModal} data-testid="agree-button">
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DatasetDocumentationModal;
