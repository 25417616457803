import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { Flex, Tag } from "@nef/core";
import moment from "moment";

import type {
  Organization,
  OrganizationTeamInvoice,
  OrganizationUser
} from "../../../../api/types";
import styles from "../style.module.scss";
import type { NormalizedOrganizationTeam } from "../index";

export default function OrganizationInfoPage({
  currentOrganization,
  isOrgAdmin = false,
  teamInvoices = [],
  organizationUsers = [],
  listData = { keys: [], items: {} },
  slug
}: {
  currentOrganization: Organization;
  isOrgAdmin: boolean;
  teamInvoices: OrganizationTeamInvoice[];
  organizationUsers: OrganizationUser[];
  listData: NormalizedOrganizationTeam;
  slug: string;
}) {
  // info
  const [trialCount, subscribedCount] = useMemo<[number, number]>(() => {
    const trial = teamInvoices.filter(invoice => invoice.trial).length;
    const subscribed: number = teamInvoices.length - trial;
    return [trial, subscribed];
  }, [teamInvoices]);

  const [activeMemberCount, pendingMemberCount] = useMemo<
    [number, number]
  >(() => {
    const activeCount = organizationUsers.filter(
      user => user.accepted_on
    ).length;
    const pendingCount = organizationUsers.length - activeCount;
    return [activeCount, pendingCount];
  }, [organizationUsers]);
  return (
    <div className={styles.orgInfoPage}>
      <h2 className={`with-top-blue-line ${styles.organizationName}`}>
        {currentOrganization.name}
      </h2>
      {isOrgAdmin ? (
        <>
          <Flex>
            <Flex flexDirection="column">
              <span className={styles.countTitle}>Datasets</span>
              {trialCount !== 0 && (
                <span>
                  <span className={styles.countInfo}>{trialCount}</span>
                  <span className={`${styles.label} ${styles.blueLabel}`}>
                    Trials
                  </span>
                </span>
              )}
              {subscribedCount !== 0 && (
                <span>
                  <span className={styles.countInfo}>{subscribedCount}</span>
                  <span className={`${styles.label} ${styles.greenLabel}`}>
                    Subscribed
                  </span>
                </span>
              )}
            </Flex>
            <Flex flexDirection="column" className={styles.countRight}>
              <span className={styles.countTitle}>Members</span>
              {activeMemberCount !== 0 && (
                <span>
                  <span className={styles.countInfo}>{activeMemberCount}</span>
                  <span className={`${styles.label} ${styles.redLabel}`}>
                    Active
                  </span>
                </span>
              )}
              {pendingMemberCount !== 0 && (
                <span>
                  <span className={styles.countInfo}>{pendingMemberCount}</span>
                  <span className={styles.label}>Pending</span>
                </span>
              )}
            </Flex>
          </Flex>
          <div className={styles.tableContainer}>
            <Flex className={styles.tableHeader}>
              <div className={styles["flex-2"]}>DATASET</div>
              <div className={styles["flex-2"]}>
                BUSINESS & TECHNICAL CONTACT
              </div>
              <div className={styles["flex-1"]}>MEMBERS</div>
              <div className={styles["flex-1"]}>HISTORY OPTION</div>
              <div className={styles["flex-1-5"]}>SUBSCRIPTION PERIOD</div>
              <div className={styles["flex-1"]}>STATUS</div>
              <div className={styles["flex-1"]}>ACTION</div>
            </Flex>

            {listData.keys.map(key => {
              const team = listData.items[key];
              return (
                <Flex
                  className={styles.tableCard}
                  key={team.id}
                  alignItems="center"
                >
                  <div className={styles["flex-2"]}>
                    <Link
                      to={`/organizations-db/${slug}/datasets/${team.id}`}
                      className={styles.tableName}
                    >
                      {team.name}
                    </Link>
                  </div>
                  <div className={styles["flex-2"]}>
                    <div>
                      <span className={`${styles.label} ${styles.blueLabel}`}>
                        B:
                      </span>
                      <span className={styles.tableText}>
                        {team.invoice?.businessContacts?.[0]}
                      </span>
                    </div>
                    <div>
                      <span className={`${styles.label} ${styles.redLabel}`}>
                        T:
                      </span>
                      <span className={styles.tableText}>
                        {team.invoice?.technicalContacts?.[0]}
                      </span>
                    </div>
                  </div>
                  <div className={styles["flex-1"]}>
                    <div>
                      {
                        team.users.filter(
                          (user: OrganizationUser) => user?.accepted_on
                        ).length
                      }
                      <span className={styles.tableText}>Active</span>
                    </div>
                    <div>
                      {
                        team.users.filter(
                          (user: OrganizationUser) => !user?.accepted_on
                        ).length
                      }
                      <span className={styles.tableText}>Pending</span>
                    </div>
                  </div>
                  <div className={styles["flex-1"]}>
                    {team.invoice?.historyOption || "N/A"}
                  </div>
                  <div className={styles["flex-1-5"]}>
                    {moment(team.invoice?.startDate).format("ll")}
                    {" - "}
                    {team.invoice?.yearsCommitment
                      ? moment(team.invoice?.startDate)
                          .add(team.invoice.yearsCommitment, "y")
                          .format("ll")
                      : "N/A"}
                  </div>
                  <div className={styles["flex-1"]}>
                    {team.invoice?.trial ? "Trial" : "Subscription"}
                  </div>
                  <div className={styles["flex-1"]}>
                    {!team.invoice?.trial && (
                      <Link
                        to={`/organizations-db/${slug}/datasets/${team.id}`}
                      >
                        Open Dataset
                      </Link>
                    )}
                  </div>
                </Flex>
              );
            })}
          </div>
        </>
      ) : (
        <>
          {listData.keys.length !== 0 ? (
            <div className={styles.tableContainer}>
              <Flex className={styles.tableHeader}>
                <div className={styles["flex-2"]}>DATASET</div>
                <div className={styles["flex-2"]}>
                  BUSINESS & TECHNICAL CONTACT
                </div>
                <div className={styles["flex-1"]}>HISTORY OPTION</div>
                <div className={styles["flex-1"]}>STATUS</div>
                <div className={styles["flex-1"]} />
              </Flex>

              {listData.keys.map(key => {
                const team = listData.items[key];
                return (
                  <Flex
                    className={styles.tableCard}
                    key={team.id}
                    alignItems="center"
                  >
                    <div className={styles["flex-2"]}>
                      {team.invoice ? (
                        <Link
                          to={`/${
                            team.invoice.productType === "Source"
                              ? "data"
                              : "databases"
                          }/${team.invoice.productId}/#documentation`}
                        >
                          {team.name}
                        </Link>
                      ) : (
                        <span className={styles.tableName}>{team.name}</span>
                      )}
                    </div>
                    <div className={styles["flex-2"]}>
                      <div>
                        <span className={styles.tableText}>
                          {team.invoice?.businessContacts?.[0]}
                        </span>
                      </div>
                    </div>
                    <div className={styles["flex-1"]}>
                      {team.invoice?.historyOption || "N/A"}
                    </div>
                    <div className={styles["flex-1"]}>
                      <Tag color="primary">
                        {team.invoice?.trial ? "Trial" : "Active"}
                      </Tag>
                    </div>
                    <div className={styles["flex-1"]}>
                      {/* The link is not functional because we dont handle rendering the product page by product ID at the moment */}
                      {team.invoice && !team.invoice.trial && (
                        <Link
                          to={`/${
                            team.invoice.productType === "Source"
                              ? "data"
                              : "databases"
                          }/${team.invoice.productId}`}
                        >
                          OPEN DATASET
                        </Link>
                      )}
                    </div>
                  </Flex>
                );
              })}
            </div>
          ) : (
            <div className={styles.noAccess}>
              <h3 className={styles.noAccessTitle}>
                You don't have access to any data products.
              </h3>
              <p>
                To use your organization’s data products, an admin needs to
                grant you access.
              </p>
            </div>
          )}
        </>
      )}
    </div>
  );
}
