import React, { createContext, useContext, useEffect, useMemo } from "react";
import { useCookies } from "react-cookie";
import ReactDOM from "react-dom";
import pick from "lodash/pick";

import useQuery from "../hooks/useQuery";

const UTM_PARAMS = [
  "utm_programid",
  "utm_campaign",
  "utm_medium",
  "utm_channel",
  "utm_source",
  "utm_term",
  "utm_content"
];
interface UTMProviderProps {
  children?: React.ReactNode;
}
const UTMContext = createContext<{
  [_: string]: string;
}>({});

const UTMProvider = ({ children }: UTMProviderProps) => {
  const query = useQuery();
  const [cookie, setCookie] = useCookies<string>(UTM_PARAMS);
  // store utm values to cookie storage
  useEffect(() => {
    // react-cookies does not support updating multiple cookies value in one pass
    // using unstable_batchesUpdates to prevent the unnecessary rerendering
    ReactDOM.unstable_batchedUpdates(() => {
      UTM_PARAMS.forEach(param => {
        const value = query.get(param);
        if (value !== null) {
          setCookie(param, value);
        }
      });
    });
  }, [query, setCookie]);

  const utmMap = useMemo(() => {
    return pick(cookie, UTM_PARAMS);
  }, [cookie]);

  return <UTMContext.Provider value={utmMap}>{children}</UTMContext.Provider>;
};

function useUTMValue() {
  const context = useContext(UTMContext);
  if (!context) {
    throw new Error("useUTMValue must used within a UTMProvider");
  }
  return context;
}

export { UTMProvider, useUTMValue };
