// eslint-disable-next-line import/no-extraneous-dependencies
import { Integrations } from "@segment/analytics-next";

import { REACT_APP_SEGMENT_WRITE_KEY } from "../appConfig";
import { getCookie } from "../helpers/cookie-helpers";
import LocalStorageService from "../services/localStorage";

import { AnalyticsWindow } from "./types";

const SOURCE = "quandl.com";

declare let window: AnalyticsWindow;

export type IdentityOptions = {
  username?: string;
  firstName?: string;
  lastName?: string;
  anonymous_id?: string | null;
};

export const trackEvent = (
  event: string,
  properties = {},
  integrations: Integrations
) => {
  const segmentProperties = {
    ...properties,
    referrer: window.document.referrer,
    source: SOURCE
  };

  if (REACT_APP_SEGMENT_WRITE_KEY === "") {
    console.log(event, segmentProperties, { integrations }); // eslint-disable-line no-console
    return;
  }

  window.analytics.track(event, segmentProperties, { integrations });
};

export const trackUser = (
  identityOptions: IdentityOptions,
  integrations: Integrations,
  userId?: string
) => {
  const userIdentity = userId || "anonymous";
  if (REACT_APP_SEGMENT_WRITE_KEY === "") {
    console.log(userIdentity, identityOptions, { integrations }); // eslint-disable-line no-console
    return;
  }

  window.analytics.identify(userIdentity, identityOptions, { integrations });
};

export const trackAlgoliaEvent = (
  event: string,
  eventType: string,
  integrations: Integrations
) => {
  const algoliaInsights = LocalStorageService.getAlgoliaInsights();

  if (algoliaInsights) {
    const trackingProperties = {
      ...algoliaInsights,
      eventType
    };
    if (REACT_APP_SEGMENT_WRITE_KEY === "") {
      console.log(event, trackingProperties, { integrations }); // eslint-disable-line no-console
      return;
    }

    window.analytics.track(event, trackingProperties, { integrations });
  }
};

export const trackPageEvent = (properties = {}, integrations: Integrations) => {
  if (REACT_APP_SEGMENT_WRITE_KEY === "") {
    console.log("Loaded a Page", properties, { integrations }); // eslint-disable-line no-console
    return;
  }

  window.analytics.page("Loaded a Page", properties, { integrations });
};

export const identifyUser = (id?: string, traits?: { [key: string]: any }) => {
  if (REACT_APP_SEGMENT_WRITE_KEY === "") {
    console.log(id, traits); // eslint-disable-line no-console
    return;
  }

  window.analytics.identify(id, traits);
};

// Retrieve the anonymous cookie ID.
export const anonymousSegmentId = () => {
  const anonymousCookie = getCookie("ajs_anonymous_id").trim();
  if (anonymousCookie) {
    return anonymousCookie.substring(1, anonymousCookie.length - 2);
  }
  return null;
};
