import { useRouteMatch } from "react-router-dom";

import { NDL_URL, SAML_STRATEGY_ENABLED } from "../../appConfig";

const NdlLoginRedirect = () => {
  const routeMatch = useRouteMatch();

  if (SAML_STRATEGY_ENABLED) {
    const samlLoginUrl = new URL(`${NDL_URL}/users/saml/sign_in`);
    window.location.assign(samlLoginUrl.href);
  } else {
    const ndlLoginUrl = new URL(`${NDL_URL}/login`);
    ndlLoginUrl.searchParams.append("from", `${routeMatch.url}`);

    window.location.assign(ndlLoginUrl.href);
  }

  return null;
};

export default NdlLoginRedirect;
