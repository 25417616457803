import React from "react";

import { ILinkFields } from "../../../../../contentful/__generated__/types";

type Props = {
  fields: ILinkFields;
};

const Basic: React.FC<Props> = ({ fields }) => {
  return (
    <a href={fields.url} className="footer-bottom-link">
      {fields.label}
    </a>
  );
};

// eslint-disable-next-line import/prefer-default-export
export { Basic as BasicLink };
