import React from "react";
import { useLocation } from "react-router-dom";

import { ICategoryNavigationIconFields } from "../../../../../../contentful/__generated__/types";
import "./style.scss";

type Props = {
  fields: ICategoryNavigationIconFields;
};

const Icon: React.FC<Props> = ({ fields }) => {
  const location = useLocation();
  const slug = location.pathname.substring(1);

  const { label, navigationLink } = fields;

  return (
    <div className="nav-icon nav-icon__menu--sticky">
      <div className="nav-icon__menu  nav-icon__menu--collapsed">
        <div className="nav-icon__menu-inner">
          <div className="nav-icon__content">
            <div className="nav-icon__jump ">{label}</div>
            {!!navigationLink &&
              navigationLink.map(nav => {
                return (
                  <div key={nav.sys.id} className="nav-links__options">
                    <a
                      href={nav.fields.url}
                      className={
                        slug === nav.fields.url
                          ? "nav-links__link-wrapper nav-links__link-wrapper--active"
                          : "nav-links__link-wrapper"
                      }
                    >
                      <div className="nav-links__link-content">
                        <img
                          className="nav-links__icon"
                          src={`https://${nav.fields.icon?.fields.file.url}`}
                          alt="institutional icon"
                        />
                        <p className="nav-links__link">{nav.fields.label}</p>
                      </div>
                    </a>
                    {navigationLink[navigationLink.length - 1] !== nav && (
                      <div className="nav-icon__divider">|</div>
                    )}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

// eslint-disable-next-line import/prefer-default-export
export { Icon as IconCategoryNavigation };
