import { useCookies } from "react-cookie";

const useGetAnonymousSegmentId = () => {
  const [cookie] = useCookies<string>(["ajs_anonymous_id"]);

  const anonymousCookie = cookie?.ajs_anonymous_id;
  if (anonymousCookie) {
    return anonymousCookie.substring(1, anonymousCookie.length - 2);
  }
  return null;
};

export default useGetAnonymousSegmentId;
