import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useMemo
} from "react";
import { useLocation } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import throttle from "lodash/throttle";

import { getSessionStatus, touchSessionStatus } from "../api/api";
import useAuth from "../hooks/useAuth";

import { useGlobalModalContext, MODAL_TYPES } from "./modal-context";

const SESSION_CHECK_RATE = 60000; // check the ttl from wikiposit every minute, value in ms
const SESSION_WARNING_TIMEOUT = 2 * 60; // show warning when the ttl is under two minutes

interface SessionTimeoutProviderProps {}
const SessionTimeoutContext = createContext<
  | {
      setSessionActivityDetected: () => void;
    }
  | undefined
>(undefined);

const SessionTimeoutProvider: React.FC<SessionTimeoutProviderProps> = ({
  children
}) => {
  const { authenticated } = useAuth();
  const isAuthenticated = useMemo(() => {
    return authenticated();
  }, [authenticated]);
  const { store, showModal } = useGlobalModalContext();
  const activityDetected = useRef(false);

  const resetSessionActivityDetected = useCallback(() => {
    activityDetected.current = false;
  }, []);

  const setSessionActivityDetected = useCallback(() => {
    activityDetected.current = true;
  }, []);

  const throttleSetSessionActivityDetected = useCallback(
    throttle(setSessionActivityDetected, 10000),
    [setSessionActivityDetected]
  );

  const handleSessionStatus = useCallback(
    (ttl: number, activityDetectedValue: boolean) => {
      if (ttl <= 0) {
        showModal?.(MODAL_TYPES.SESSION_TIMEOUT_MODAL, {
          expired: true,
          activate: setSessionActivityDetected
        });
        return;
      }

      if (activityDetectedValue) {
        touchSessionStatus().then(resetSessionActivityDetected);
      } else {
        if (ttl > SESSION_WARNING_TIMEOUT) return;
        showModal?.(MODAL_TYPES.SESSION_TIMEOUT_MODAL, {
          expired: false,
          // modal is not in the context, pass the activate to the modal
          // to allow counting 'continue' as user interaction
          activate: setSessionActivityDetected
        });
      }
    },
    // not list showModel as dependencies because its created every time it rendered
    [resetSessionActivityDetected, setSessionActivityDetected]
  );

  const timeoutHandlerRef = useRef<ReturnType<typeof setTimeout>>();

  const setSessionStatus = useCallback(
    (firstTime = false) => {
      if (timeoutHandlerRef.current) {
        clearTimeout(timeoutHandlerRef.current);
      }
      if (!isAuthenticated) return;
      if (firstTime) {
        getSessionStatus().then(ttl => handleSessionStatus(ttl, true));
      }
      timeoutHandlerRef.current = setTimeout(() => {
        if (isAuthenticated) {
          getSessionStatus().then(ttl =>
            handleSessionStatus(ttl, activityDetected.current)
          );
        }
        setSessionStatus();
      }, SESSION_CHECK_RATE);
    },
    [isAuthenticated, handleSessionStatus]
  );

  useEffect(() => {
    setSessionStatus(true);
    return () => {
      if (timeoutHandlerRef.current) {
        clearTimeout(timeoutHandlerRef.current);
        timeoutHandlerRef.current = undefined;
      }
    };
  }, [setSessionStatus]);

  // capture window scroll event
  useEffect(() => {
    if (isAuthenticated) {
      window.addEventListener("scroll", throttleSetSessionActivityDetected, {
        passive: true
      });
    }
    return () =>
      window.removeEventListener("scroll", throttleSetSessionActivityDetected);
  }, [isAuthenticated, throttleSetSessionActivityDetected]);

  // react router transition event
  const location = useLocation();
  useEffect(() => {
    setSessionActivityDetected();
  }, [location.pathname, location.search, setSessionActivityDetected]);

  // modal event
  const previousModalStore = useRef<null | {
    modalType: string | null;
  }>(null);
  useEffect(() => {
    if (isEmpty(store)) return;
    // when open a modal and the modal is not a session timeout modal
    if (
      store.modalType !== MODAL_TYPES.SESSION_TIMEOUT_MODAL &&
      store.modalType !== null
    ) {
      setSessionActivityDetected();
    }
    // when close a modal and the modal is not a session timeout modal
    if (
      previousModalStore.current?.modalType !==
        MODAL_TYPES.SESSION_TIMEOUT_MODAL &&
      store.modalType === null
    ) {
      setSessionActivityDetected();
    }
    previousModalStore.current = store;
  }, [store, setSessionActivityDetected]);

  useEffect(() => {
    function visibilitychangeHandle() {
      if (!document.hidden) {
        throttleSetSessionActivityDetected();
      }
    }
    document.addEventListener("visibilitychange", visibilitychangeHandle);
    return () => {
      document.removeEventListener("visibilitychange", visibilitychangeHandle);
    };
  }, []);

  if (!isAuthenticated) {
    return (
      // extra div here to match the authenticated page to make sure there is no visual defect between two states
      <div>
        <SessionTimeoutContext.Provider
          value={{
            setSessionActivityDetected
          }}
        >
          {children}
        </SessionTimeoutContext.Provider>
      </div>
    );
  }

  return (
    <div
      // capture all the internal scroll event, scroll in modal, etc
      onScrollCapture={throttleSetSessionActivityDetected}
      // capture all the user's clicks
      onClickCapture={setSessionActivityDetected}
    >
      <SessionTimeoutContext.Provider
        value={{
          setSessionActivityDetected
        }}
      >
        {children}
      </SessionTimeoutContext.Provider>
    </div>
  );
};

function useSessionTimeout() {
  const context = useContext(SessionTimeoutContext);
  if (!context) {
    throw new Error(
      "useSessionTimeout must used within a SessionTimeoutProvider"
    );
  }
  return context;
}

export { SessionTimeoutProvider, useSessionTimeout };
