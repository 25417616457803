import React, { useState, ReactNode } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";

import AuthContext, { AuthenticatedUser } from "../../context/auth";
import LocalStorageService from "../../services/localStorage";
import {
  loginUser,
  userSignup,
  getRecentProducts,
  signOutUser,
  getMyInfo
} from "../../api/api";
import { client } from "../../api/users/client";
import useTrackUser from "../../analytics/hooks/useTrackUser";
import useTrackEvent from "../../analytics/hooks/useTrackEvent";

interface AuthProviderProps {
  children?: ReactNode;
}

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [authenticatedUser, setAuthenticatedUser] = useState<AuthenticatedUser>(
    LocalStorageService.getUser()
  );

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const invited = queryParams.get("invited");

  const [, , removeCookie] = useCookies();
  const trackUser = useTrackUser();
  const trackEvent = useTrackEvent();
  const history = useHistory<{ path?: string }>();
  const inviteToken = queryParams.get("invite");
  const getUserInfo = async () => {
    // don't fetch or redirect if the user is new
    if (invited) return;
    try {
      const {
        data: { user }
      } = await getMyInfo();
      if (LocalStorageService.getInviteToken()) {
        const token = LocalStorageService.getInviteToken();
        LocalStorageService.removeInviteToken();
        window.location.replace(
          `${process.env.REACT_APP_MANAGER_PUBLIC_URL}/accept-invite?token=${token}`
        );
      }
      LocalStorageService.saveUser(user, trackUser);
      client.defaults.headers.common["X-API-Token"] = user.api_key;
      setAuthenticatedUser(user);
      trackEvent("Logged In", {
        submission_origin: "login",
        user_type: user.user_type,
        Path: "login",
        business_type:
          user.user_type === "business" && user.business_type_short_form
            ? user.business_type_short_form
            : undefined
      });
    } catch (error) {
      history.push("/institutional-investors");
    }
  };

  const redirectToSamlLogin = (samlLoginEmail: string) => {
    const url = `${process.env.REACT_APP_LOGIN_HOST}/web/users/saml_login_strategy?email=${samlLoginEmail}`;
    if (inviteToken) {
      LocalStorageService.saveInviteToken(inviteToken);
    }
    window.location.replace(url);
  };

  const login = async (email: string, password: string) => {
    return loginUser(email, password).then(() => {
      const token = LocalStorageService.getInviteToken();

      if (token) {
        LocalStorageService.removeInviteToken();
        window.location.replace(
          `${process.env.REACT_APP_MANAGER_PUBLIC_URL}/accept-invite?token=${token}`
        );
      } else {
        history.push("/");
      }
    });
  };

  const signup = async (info: any, redirectTo?: string) => {
    return userSignup(info).then(response => {
      const user = response.data;
      const { user: userData } = user;
      LocalStorageService.saveUser(userData, trackUser);
      client.defaults.headers.common["X-API-Token"] = user.api_key;
      setAuthenticatedUser(userData);

      const token = LocalStorageService.getInviteToken();

      if (token) {
        LocalStorageService.removeInviteToken();
        window.location.replace(
          `${process.env.REACT_APP_MANAGER_PUBLIC_URL}/accept-invite?token=${token}`
        );
      } else {
        history.push(redirectTo || "/");
      }
    });
  };

  const logout = (
    config = {
      to: "/institutional-investors"
    }
  ) => {
    signOutUser().catch(() => removeCookie("_wikiposit_session_v8"));
    setAuthenticatedUser(null);
    LocalStorageService.removeUser();
    delete client.defaults.headers.common["X-API-Token"];
    history.push(config.to || "/");
  };

  const authenticated = () => {
    return !!authenticatedUser;
  };

  const getProducts = () => {
    return getRecentProducts().then(response => {
      client.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
      client.defaults.headers.common["Access-Control-Allow-Methods"] =
        "GET, POST, PUT, DELETE";
      client.defaults.headers.common["Access-Control-Allow-Headers"] =
        "Authorization";

      return response;
    });
  };

  return (
    <AuthContext.Provider
      value={{
        authenticatedUser,
        setAuthenticatedUser,
        getUserInfo,
        redirectToSamlLogin,
        authenticated,
        login,
        signup,
        logout,
        getProducts
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
