import { AppAction } from "./types";

import { Types, ActionMap } from "./index";

type ProductType = {
  id: number;
  code: string;
  productType: string;
  name: string;
  premium: boolean;
  description: string;
  datasets: any;
  datatables: any;
};

type ProductPayload = {
  [Types.Create]: {
    id: number;
    code: string;
    productType: string;
    name: string;
    premium: boolean;
    description: string;
    datasets: any;
    datatables: any;
  };
  [Types.Delete]: {
    id: number;
  };
  [Types.Pick]: {
    id: number;
  };
};

export type ProductActions =
  ActionMap<ProductPayload>[keyof ActionMap<ProductPayload>];

export const productReducer = (state: ProductType[], action: AppAction) => {
  switch (action.type) {
    case Types.Create:
      return [
        ...state,
        {
          id: action.payload.id,
          code: action.payload.code,
          productType: action.payload.productType,
          name: action.payload.name,
          premium: action.payload.premium,
          description: action.payload.description,
          datasets: action.payload.datasets,
          datatables: action.payload.datatables
        }
      ];
    case Types.Delete:
      return [...state.filter(product => product.id !== action.payload.id)];
    case Types.Pick:
      return [...state.filter(product => product.id === action.payload.id)];
    default:
      return state;
  }
};
