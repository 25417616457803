import React from "react";
import { Redirect } from "react-router-dom";

const ProductSubrouteRedirect = () => {
  const path = window.location.pathname;
  const subRoutes = path.split("/");
  const finalSegment = subRoutes[subRoutes.length - 1];
  if (finalSegment === "data" || finalSegment === "overview") {
    subRoutes.pop();
  }

  const newPath = subRoutes
    .join("/")
    .replace("/documentation", "#documentation")
    .replace(/\/usage.*/, "#usage");

  return <Redirect to={newPath} />;
};

export default ProductSubrouteRedirect;
