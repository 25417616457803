import React, { useContext } from "react";
import moment from "moment";

import "./style.scss";
import { BasicLink } from "../contentful-page/link/basic";
import { ActionLink } from "../contentful-page/link/action";
import { ContentfulContext } from "../../context/contentful-context";
import {
  ILinkFields,
  ILinkActionFields
} from "../../../contentful/__generated__/types";

type FooterProps = {
  isAuthenticated?: boolean;
  isFabricFeature?: boolean;
};

const Footer = ({
  isAuthenticated = false,
  isFabricFeature = false
}: FooterProps) => {
  const userIsAuthenticatedAndFabricFeature =
    isAuthenticated && isFabricFeature;
  const currentYear = moment().format("YYYY");

  const entry = useContext(ContentfulContext);
  // eslint-disable-next-line react/destructuring-assignment
  const value = entry.filter(
    (item: any) => item.sys.contentType.sys.id === "footer"
  )[0];

  const legalLinks = value?.fields.legalLinks;
  const linkColumns = value?.fields.linkColumns;
  const imageLogo = `${process.env.PUBLIC_URL}/assets/images/logos/nasdaq-logo.svg`;

  return (
    <div className="footer-container">
      {userIsAuthenticatedAndFabricFeature ? (
        <div>null</div>
      ) : (
        <div className="footer-left-section">
          <div className="footer-columns">
            {linkColumns?.map(
              (column: {
                sys: { id: string };
                fields: { name: string; links: any[] };
              }) => {
                return (
                  <div key={column.sys.id} className="footer-column">
                    <h5 className="footer-title">{column.fields.name}</h5>
                    <ul className="footer-links">
                      {column.fields.links.map(link => {
                        return (
                          <li key={link.sys.id} className="footer-item">
                            <a
                              className="footer-link"
                              href={link.fields.url}
                              target={
                                link.fields.openInNewWindow ? "_blank" : "_self"
                              }
                              rel="noreferrer"
                            >
                              {link.fields.label}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                );
              }
            )}
          </div>
          <div className="footer-bottom-links">
            <ul className="footer-bottom-row">
              {legalLinks?.map(
                (link: {
                  sys: { id: string; contentType: { sys: { id: string } } };
                  fields: ILinkFields | ILinkActionFields;
                }) => (
                  <li
                    key={link.sys.id}
                    className="footer-item footer-item--grey"
                  >
                    {link.sys.contentType.sys.id === "link" ? (
                      <BasicLink fields={link.fields as ILinkFields} />
                    ) : (
                      <ActionLink fields={link.fields as ILinkActionFields} />
                    )}
                  </li>
                )
              )}
            </ul>
          </div>
        </div>
      )}
      <div className="footer-right-section">
        <img src={imageLogo} alt="nasdaq logo" />
        <div className="logo-text">
          © {currentYear}, Nasdaq, Inc. All Rights Reserved
        </div>
      </div>
    </div>
  );
};

export default Footer;
