import { useSegmentIntegrationContext } from "../../context/segment-context";
import { IdentityOptions, trackUser } from "../analytics";
import useGetAnonymousSegmentId from "../useGetAnonymousSegmentId";

export type UserTracking = {
  identityOptions: Pick<IdentityOptions, "username" | "firstName" | "lastName">;
};

const useTrackUser = () => {
  const integrations = useSegmentIntegrationContext();
  const anonymousSegmentId = useGetAnonymousSegmentId();

  const trackUserFn = ({ identityOptions }: UserTracking, userId: string) => {
    trackUser(
      { ...identityOptions, anonymous_id: anonymousSegmentId },
      integrations,
      userId
    );
  };

  return trackUserFn;
};

export default useTrackUser;
