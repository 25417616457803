import React, { useEffect, useState } from "react";
import moment from "moment";

import { getTopEquities } from "../../../api/api";
import UrebTable from "../../retail-trading-activity-tracker/components/ureb-table";
import { ITopTradesBasicFields } from "../../../../contentful/__generated__/types";

type Props = {
  fields: ITopTradesBasicFields;
};

const TopTrades: React.FC<Props> = ({ fields }) => {
  const DISPLAY_DATE_FORMAT = "MMMM D, Y";
  const [topEquities, setTopEquities] = useState<any[]>([]);
  const [currentDate, setCurrentDate] = useState(
    moment().subtract(1, "days").format(DISPLAY_DATE_FORMAT)
  );

  useEffect(() => {
    getTopEquities().then(async (response: any) => {
      const equities = await response.data.top_equities;
      setTopEquities(equities);
    });
  }, []);

  useEffect(() => {
    if (topEquities.length) {
      // display a default date/placeholder if the top equities data is somehow missing
      setCurrentDate(moment().subtract(1, "days").format(DISPLAY_DATE_FORMAT));
    }

    const firstEquity = topEquities[0];
    setCurrentDate(
      moment(firstEquity?.date, "YYYY-MM-DD").format(DISPLAY_DATE_FORMAT)
    );
  }, [topEquities]);

  return (
    <UrebTable
      currentDate={currentDate}
      equities={topEquities}
      label={fields.label}
    />
  );
};

export default TopTrades;
