import React from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

import { ITableFields } from "../../../../../contentful/__generated__/types";

import "./style.scss";

type Props = {
  fields: ITableFields;
};

const Table: React.FC<Props> = ({ fields }) => {
  return (
    <div
      className={`table__wrapper ${
        fields.equalWidthColumns ? "equalWidthColumns" : ""
      }`}
    >
      {fields.showNameDescription && (
        <>
          <h4 className="table__name">{fields.name}</h4>
          {fields.description && (
            <p className="table__description">{fields.description}</p>
          )}
        </>
      )}
      {documentToReactComponents(fields.table)}
    </div>
  );
};

export default Table;
