import React from "react";
import "./style.scss";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { INLINES, Node } from "@contentful/rich-text-types";

import { IRichTextMediaFields } from "../../../../../contentful/__generated__/types";

const OPTIONS = {
  renderNode: {
    [INLINES.HYPERLINK]: (node: Node, children: any) => {
      const { data } = node;
      return (
        <a className="rich-text-media-hyperlink" href={data.uri}>
          {children}
        </a>
      );
    }
  }
};

type Props = {
  fields: IRichTextMediaFields;
};

const Media: React.FC<Props> = ({ fields }) => {
  const { media, body, mediaPosition } = fields;

  return (
    <section className="rich-text-media-description-video">
      <div className="rich-text-media-video-container">
        {mediaPosition === "Left" && (
          <div className="rich-text-media-video">
            <img
              src={`https://${media?.fields.file.url}`}
              alt={media?.fields.title}
            />
          </div>
        )}

        <div className="rich-text-media-video-description">
          {!!body && documentToReactComponents(body, OPTIONS)}
        </div>

        {mediaPosition === "Right" && (
          <div className="rich-text-media-video">
            <img
              src={`https://${media?.fields.file.url}`}
              alt={media?.fields.title}
            />
          </div>
        )}
      </div>
    </section>
  );
};

// eslint-disable-next-line import/prefer-default-export
export { Media as MediaRichText };
