import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NotificationHub
} from "@nef/core";

import { useGlobalModalContext } from "../../../context/modal-context";
import { deleteMyAccount } from "../../../api/api";
import useAuth from "../../../hooks/useAuth";

function DeleteAccountModal() {
  const { hideModal } = useGlobalModalContext();
  const { logout } = useAuth();

  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = (event: React.FormEvent) => {
    event.preventDefault();
    setIsDeleting(true);
    deleteMyAccount()
      .then(() => {
        NotificationHub.send("success", "You account has been deleted");
        hideModal();
        logout({ to: "/" });
      })
      .catch(() => {
        NotificationHub.send(
          "danger",
          "We could not delete your account at the moment, please try again later."
        );
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  return (
    <Modal
      size="sm"
      isOpen={true}
      toggle={hideModal}
      data-testid="DeleteAccountModal"
    >
      <ModalHeader toggle={hideModal}>Delele Account</ModalHeader>
      <ModalBody>
        This action will permanently delete your Nasdaq Data Link account. Would
        you like to proceed?
      </ModalBody>
      <form onSubmit={handleDelete}>
        <ModalFooter>
          <Button type="button" onClick={hideModal}>
            Cancel
          </Button>
          <Button type="submit" color="danger" isLoading={isDeleting}>
            Delete
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}

export default DeleteAccountModal;
