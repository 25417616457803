import { useState, useCallback } from "react";

import { getMyBookmarks } from "../api/api";
import { Bookmark, BookmarkTaggableType } from "../api/types";

export interface UseBookmark {
  bookmarks: Bookmark[];
  addBookmark: (newBookmark: Bookmark) => void;
  initializeBookmarks: () => Promise<void>;
  deleteBookmark: (bookmarkId: number) => void;
  isBookmarkTagged: (
    taggableId: number,
    taggableType: BookmarkTaggableType
  ) => boolean;
}

function useBookmark(): UseBookmark {
  const [bookmarks, setBookmarks] = useState<Bookmark[]>([]);

  const initializeBookmarks = useCallback(() => {
    return getMyBookmarks().then((response: any) => {
      setBookmarks(response.data.bookmarks);
    });
  }, []);

  const addBookmark = useCallback((newBookmark: Bookmark) => {
    setBookmarks(prevBookmarks => [...prevBookmarks, newBookmark]);
  }, []);

  const deleteBookmark = useCallback((bookmarkId: number) => {
    setBookmarks(prevBookmarks =>
      prevBookmarks.filter(bookmark => bookmark.taggable_id !== bookmarkId)
    );
  }, []);

  const isBookmarkTagged = useCallback(
    (taggableId: number, taggableType: string) =>
      bookmarks.some(
        b => b.taggable_id === taggableId && b.taggable_type === taggableType
      ),
    [bookmarks]
  );

  return {
    bookmarks,
    addBookmark,
    initializeBookmarks,
    deleteBookmark,
    isBookmarkTagged
  };
}

export default useBookmark;
