import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import React, { useEffect, useState } from "react";

import { IFeaturesSlideshowFields } from "../../../../../contentful/__generated__/types";
import "./style.scss";

type Props = {
  fields: IFeaturesSlideshowFields;
};

const Slideshow: React.FC<Props> = ({ fields }) => {
  const [activeFeature, setActiveFeature] = useState<any>(
    fields?.features && fields.features[0]
  );
  const [shouldAnimate, setShouldAnimate] = useState<boolean>(false);

  useEffect(() => {
    setShouldAnimate(true);

    const timeoutId = setTimeout(() => {
      setShouldAnimate(false);
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [activeFeature]);

  return (
    <div className="slideshow-fabric-content">
      <div className="slideshow__marketing-features scroll-scene scroll-scene-initial scroll-scene-active">
        <div className="slideshow__wrapper">
          <div className="slideshow__features-wrapper">
            <div className="slideshow__features-list">
              {!!fields &&
                fields?.features?.map(feature => {
                  return (
                    <div
                      key={feature.sys.id}
                      className={
                        activeFeature.sys.id === feature.sys.id
                          ? "slideshow__feature slideshow__feature--active"
                          : "slideshow__feature"
                      }
                      onClick={() => setActiveFeature(feature)}
                    >
                      <img
                        className="slideshow__feature__icon"
                        src={`https://${feature.fields.icon?.fields.file.url}`}
                        alt={feature.fields.icon?.fields.title}
                      />
                      <p className="slideshow__feature__title">
                        {feature.fields.heading}
                      </p>
                      <p
                        className={
                          activeFeature.sys.id === feature.sys.id
                            ? "slideshow__feature__body slideshow__feature__active-body"
                            : "slideshow__feature__body"
                        }
                      >
                        {documentToReactComponents(activeFeature.fields.body)}
                      </p>
                    </div>
                  );
                })}
            </div>
            <div
              className="slideshow__active-feature-wrapper"
              data-scroll-scene--trace-border="wrapper"
            >
              <div className="slideshow__active-feature">
                <div className="slideshow__active-feature__header">
                  <h3 className="slideshow__active-feature__title">
                    {activeFeature.fields.heading}
                  </h3>
                  <img
                    className="slideshow__active-feature__icon"
                    src={`https://${activeFeature.fields.icon.fields.file.url}`}
                    alt={activeFeature.fields.icon?.fields.title}
                  />
                </div>
                <div
                  className={`slideshow__active-feature__body ${
                    shouldAnimate ? "slideshow__fadeInDown" : ""
                  }`}
                >
                  {documentToReactComponents(activeFeature.fields.body)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// eslint-disable-next-line import/prefer-default-export
export { Slideshow as FeaturesSlideshow };
