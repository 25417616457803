import React, { useState } from "react";
import { Alert } from "@nef/core";
import { useCookies } from "react-cookie";

import { useGlobalModalContext } from "../../../context/modal-context";
import "./style.scss";
import PrivacySettingsService from "../../../services/privacySettings";
import { PrivacySetting } from "../../../api/types";
import useAuth from "../../../hooks/useAuth";

const PrivacySettingModal = () => {
  const [cookie] = useCookies<string>(["privacy_setting"]);
  const privacySettingsCookie = cookie?.privacy_setting;
  const [performance, setPerformance] = useState<boolean>(
    privacySettingsCookie?.performance ?? true
  );
  const [functionality, setFunctionality] = useState<boolean>(
    privacySettingsCookie?.functionality ?? true
  );
  const [targeting, setTargeting] = useState<boolean>(
    privacySettingsCookie?.targeting ?? true
  );
  const [alert, setAlert] = useState<string>("");
  const { hideModal } = useGlobalModalContext();
  const [, setPrivacySettings] = useState<PrivacySetting>(
    privacySettingsCookie
  );
  const [, setHasUpdatedPrivacy] = useState<boolean>(false);
  const { authenticated } = useAuth();
  const isAuthenticated = authenticated();

  const submitPrivacy = async () => {
    PrivacySettingsService.updatePrivacySetting(
      {
        performance,
        functionality,
        targeting,
        accepted: true
      },
      setPrivacySettings,
      setAlert,
      setHasUpdatedPrivacy,
      isAuthenticated
    );
    hideModal();
  };

  const onChangeSetting = (event: any) => {
    switch (event.target.name) {
      case "performance":
        setPerformance(event.target.value === "true");
        break;
      case "functionality":
        setFunctionality(event.target.value === "true");
        break;
      case "targeting":
        setTargeting(event.target.value === "true");
        break;
      default:
        break;
    }
  };

  return (
    <div
      data-testid="privacy-setting-modal"
      role="dialog"
      className="privacySettingModal__wrapper"
    >
      {!!alert.length && <Alert color="danger">{alert}</Alert>}
      <h3 className="privacySettingModal__header with-top-blue-line">
        Website Data Collection Preferences
      </h3>
      <table>
        <thead className="privacySettingModal__table-head">
          <tr>
            <th>Allow</th>
            <th>Category</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody className="privacySettingModal__table-body">
          <tr>
            <td>N/A</td>
            <td>Strictly Necessary Cookies</td>
            <td className="privacySettingModal__table-body-content">
              Because these cookies are strictly necessary to deliver the
              functionality, you cannot refuse them without significantly
              impacting how Nasdaq Data Link functions. You can set your browser
              to block or alert you about these cookies, but some parts of the
              site will not then work. These cookies do not store any personally
              identifiable information.
            </td>
          </tr>

          <tr data-testid="privacy-setting-radio">
            <td>
              <label className="privacySettingModal__table-body-label">
                <input
                  type="radio"
                  name="performance"
                  checked={performance}
                  value="true"
                  onClick={onChangeSetting}
                  data-testid={`privacy-setting-active-${performance}`}
                />
                <span>Yes</span>
              </label>
              <label className="privacySettingModal__table-body-label">
                <input
                  type="radio"
                  name="performance"
                  value="false"
                  checked={!performance}
                  onClick={onChangeSetting}
                />
                <span>No</span>
              </label>
            </td>
            <td>Performance Cookies</td>
            <td className="privacySettingModal__table-body-content">
              These cookies allow us to count visits and traffic sources so we
              can measure and improve the performance of our site. They help us
              to know which pages are popular and see how visitors move around
              the Sites. If you do not allow these cookies, we will be less able
              to optimize the site’s performance. All information these cookies
              collect is aggregated and anonymous.
            </td>
          </tr>

          <tr data-testid="privacy-setting-radio">
            <td>
              <label className="privacySettingModal__table-body-label">
                <input
                  type="radio"
                  name="functionality"
                  value="true"
                  checked={functionality}
                  onClick={onChangeSetting}
                  data-testid={`privacy-setting-active-${functionality}`}
                />
                <span>Yes</span>
              </label>
              <label className="privacySettingModal__table-body-label">
                <input
                  type="radio"
                  name="functionality"
                  value="false"
                  checked={!functionality}
                  onClick={onChangeSetting}
                />
                <span>No</span>
              </label>
            </td>
            <td>Functionality Cookies</td>
            <td className="privacySettingModal__table-body-content">
              These cookies enable the website to provide enhanced functionality
              and personalization. If you do not allow these cookies some
              services may not function properly.
            </td>
          </tr>

          <tr data-testid="privacy-setting-radio">
            <td>
              <label className="privacySettingModal__table-body-label">
                <input
                  type="radio"
                  name="targeting"
                  value="true"
                  checked={targeting}
                  onClick={onChangeSetting}
                  data-testid={`privacy-setting-active-${targeting}`}
                />
                <span>Yes</span>
              </label>
              <label className="privacySettingModal__table-body-label">
                <input
                  type="radio"
                  name="targeting"
                  value="false"
                  checked={!targeting}
                  onClick={onChangeSetting}
                />
                <span>No</span>
              </label>
            </td>
            <td>Targeting Cookies</td>
            <td className="privacySettingModal__table-body-content">
              These cookies may be used to build a profile of your interests and
              show you relevant advertisements on other sites. If you do not
              allow these cookies, you will experience less targeted
              advertising.
            </td>
          </tr>
        </tbody>
      </table>
      <div className="privacySettingModal__button">
        <button
          type="button"
          className="b-button b-button--blue"
          data-testid="privacy-button-save"
          onClick={submitPrivacy}
        >
          save
        </button>
      </div>
    </div>
  );
};

export default PrivacySettingModal;
