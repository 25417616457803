import React from "react";

import { ITechnologiesBasicFields } from "../../../../contentful/__generated__/types";
import "./style.scss";

type Props = {
  fields: ITechnologiesBasicFields;
};

const Basic: React.FC<Props> = ({ fields }) => {
  return (
    <section className="contentful-top-tech-section">
      <div className="page-section">
        <div className="fact-row mb-4">
          <h3 className="__aaee5__content-titles with-top-blue-line">
            {fields.heading1}
          </h3>
        </div>

        <div className="fact-row __aaee5__display-restriction mb-4">
          <div className="grid __aaee5__width-restriction grid--with-no-padding">
            <div className="grid__column grid__column-link grid__column--1-of-5 u-padding-grid-column">
              <a href="/tools/api">
                {" "}
                <div className="supported-item __aaee5__background-tool-overview">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/home-marketing/icons/tools/api.svg`}
                    alt="Nasdaq Data Link API"
                    title="Nasdaq Data Link API"
                  />
                  <h4 className="__aaee5__tool-list-blue">API</h4>
                </div>
              </a>{" "}
            </div>

            <div className="grid__column grid__column--1-of-5 u-padding-grid-column">
              <a href="/tools/r">
                {" "}
                <div className="supported-item __aaee5__background-tool-overview">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/home-marketing/icons/tools/r.svg`}
                    alt="R package"
                    title="R package"
                  />
                  <h4 className="__aaee5__tool-list-blue">R</h4>
                </div>
              </a>{" "}
            </div>

            <div className="grid__column grid__column--1-of-5 u-padding-grid-column">
              <a href="/tools/python">
                {" "}
                <div className="supported-item __aaee5__background-tool-overview">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/home-marketing/icons/tools/python.svg`}
                    alt="Python library"
                    title="Python library"
                  />
                  <h4 className="__aaee5__tool-list-blue">Python</h4>
                </div>
              </a>{" "}
            </div>

            <div className="grid__column grid__column--1-of-5 u-padding-grid-column">
              <a href="/tools/excel">
                {" "}
                <div className="supported-item __aaee5__background-tool-overview">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/home-marketing/icons/tools/excel.svg`}
                    alt="Excel add-in"
                    title="Excel add-in"
                  />
                  <h4 className="__aaee5__tool-list-blue">Excel</h4>
                </div>
              </a>{" "}
            </div>

            <div className="grid__column grid__column--1-of-5 u-padding-grid-column">
              <a
                href="https://github.com/quandl/quandl-ruby"
                className=" grid__column--fixed grid__column--wider"
              >
                <div className="supported-item __aaee5__background-tool-overview">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/home-marketing/icons/tools/ruby.svg`}
                    alt="Ruby support"
                    title="Ruby support"
                  />
                  <h4 className="__aaee5__tool-list-blue">Ruby</h4>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="fact-row mb-4 link-boxes">
        <div className="grid__column">
          <h3 className="u-margin-bottom-20 __aaee5__content-titles with-top-blue-line">
            {fields.heading2}
          </h3>
          <div className="u-vertically-spaced u-margin-bottom-40">
            <section className="section-padding-quarter border-bottom u-vertically-spaced__item">
              <div className="u-cross-axis-left-aligned link-boxes">
                <h4 className="u-margin-bottom-10 __aaee5__library-list">
                  Scientific and Mathematical Language Libraries
                </h4>
                <div className="u-horizontally-spaced">
                  <a
                    href="http://www.eviews.com/Addins/addins.shtml"
                    target="_blank"
                    rel="noreferrer"
                    className="u-horizontally-spaced__item aaee5__tool-list-black"
                  >
                    Eviews
                  </a>
                  <a
                    href="http://www.maplesoft.com/products/maple/Features/Quandl.aspx"
                    target="_blank"
                    rel="noreferrer"
                    className="u-horizontally-spaced__item __aaee5__tool-list-black"
                  >
                    Maple
                  </a>
                  <a
                    href="https://github.com/quandl/Matlab"
                    target="_blank"
                    rel="noreferrer"
                    className="u-horizontally-spaced__item __aaee5__tool-list-black"
                  >
                    Matlab
                  </a>
                  <a
                    href="https://github.com/tradingjunkie/OctaveQuandlInterface"
                    target="_blank"
                    rel="noreferrer"
                    className="u-horizontally-spaced__item __aaee5__tool-list-black"
                  >
                    Octave
                  </a>
                  <a
                    href="http://support.sas.com/documentation/cdl/en/etsug/67525/HTML/default/viewer.htm#etsug_sasequan_overview.htm"
                    target="_blank"
                    rel="noreferrer"
                    className="u-horizontally-spaced__item __aaee5__tool-list-black"
                  >
                    SAS
                  </a>
                  <a
                    href="https://ideas.repec.org/c/boc/bocode/s457638.html"
                    target="_blank"
                    rel="noreferrer"
                    className="u-horizontally-spaced__item __aaee5__tool-list-black"
                  >
                    Stata
                  </a>
                  <a
                    href="http://community.wolfram.com/groups/-/m/t/540228?_19_redirect=http%3A%2F%2Fcommunity.wolfram.com%2Fcontent%3Fp_p_id%3D3%26p_p_lifecycle%3D0%26p_p_state%3Dmaximized%26p_p_mode%3Dview%26_3_groupId%3D0%26_3_keywords%3Dquandl%26_3_struts_action%3D%252Fsearch%252Fsearch%26_3_redirect%3D%252Fweb%252Fcommunity%252Fcontent%253FcurTag%253Dmathematica%252520add%252520ons%26_3_y%3D0%26_3_x%3D0"
                    target="_blank"
                    rel="noreferrer"
                    className="u-horizontally-spaced__item __aaee5__tool-list-black"
                  >
                    Mathematica
                  </a>
                </div>
              </div>
            </section>
            <section className="section-padding-quarter border-bottom u-vertically-spaced__item">
              <div className="u-cross-axis-left-aligned services link-boxes">
                <h4 className="u-margin-bottom-10 __aaee5__library-list">
                  General Purpose Language Libraries
                </h4>
                <div className="u-horizontally-spaced u-horizontally-spaced--narrow">
                  <a
                    href="https://github.com/lppkarl/Quandl.NET"
                    target="_blank"
                    rel="noreferrer"
                    className="u-horizontally-spaced__item __aaee5__tool-list-black"
                  >
                    C#
                  </a>
                  <a
                    href="https://github.com/zafuer/QuandlAPI_C"
                    target="_blank"
                    rel="noreferrer"
                    className="u-horizontally-spaced__item __aaee5__tool-list-black"
                  >
                    C/C++
                  </a>
                  <a
                    href="https://github.com/keorn/clj-quandl-api"
                    target="_blank"
                    rel="noreferrer"
                    className="u-horizontally-spaced__item __aaee5__tool-list-black"
                  >
                    Clojure
                  </a>
                  <a
                    href="https://github.com/Laeeth/d-quandl"
                    target="_blank"
                    rel="noreferrer"
                    className="u-horizontally-spaced__item __aaee5__tool-list-black"
                  >
                    D
                  </a>
                  <a
                    href="https://github.com/pvdbrand/quandl-api"
                    target="_blank"
                    rel="noreferrer"
                    className="u-horizontally-spaced__item __aaee5__tool-list-black"
                  >
                    Haskell
                  </a>
                  <a
                    href="http://quandl4j.org/"
                    target="_blank"
                    rel="noreferrer"
                    className="u-horizontally-spaced__item __aaee5__tool-list-black"
                  >
                    Java
                  </a>
                  <a
                    href="https://github.com/iamtrask/quandl-java"
                    target="_blank"
                    rel="noreferrer"
                    className="u-horizontally-spaced__item __aaee5__tool-list-black"
                  >
                    Java (lite)
                  </a>
                  <a
                    href="https://github.com/milktrader/Quandl.jl"
                    target="_blank"
                    rel="noreferrer"
                    className="u-horizontally-spaced__item __aaee5__tool-list-black"
                  >
                    Julia
                  </a>
                  <a
                    href="https://github.com/jaredbroad/Quandl.NET"
                    target="_blank"
                    rel="noreferrer"
                    className="u-horizontally-spaced__item __aaee5__tool-list-black"
                  >
                    .NET
                  </a>
                  <a
                    href="https://github.com/normanjoyner/node-quandl"
                    target="_blank"
                    rel="noreferrer"
                    className="u-horizontally-spaced__item __aaee5__tool-list-black"
                  >
                    NodeJS
                  </a>
                  <a
                    href="https://github.com/DannyBen/php-quandl"
                    target="_blank"
                    rel="noreferrer"
                    className="u-horizontally-spaced__item __aaee5__tool-list-black"
                  >
                    PHP
                  </a>
                </div>
              </div>
            </section>
            <section className="section-padding-quarter border-bottom u-vertically-spaced__item">
              <div className="u-cross-axis-left-aligned services link-boxes">
                <h4 className="u-margin-bottom-10 __aaee5__library-list">
                  Other tools
                </h4>
                <div className="u-horizontally-spaced u-horizontally-spaced--narrow">
                  <a
                    href="https://github.com/quandl/quandl-google-spreadsheet-add-on"
                    target="_blank"
                    rel="noreferrer"
                    className="u-horizontally-spaced__item __aaee5__tool-list-black"
                  >
                    Google Sheets
                  </a>
                  <a
                    href="http://www.unityjdbc.com/quandl/quandl_jdbc.php"
                    target="_blank"
                    rel="noreferrer"
                    className="u-horizontally-spaced__item __aaee5__tool-list-black"
                  >
                    JDBC Driver
                  </a>
                  <a
                    href="https://github.com/SpreadServe/SSAddin"
                    target="_blank"
                    rel="noreferrer"
                    className="u-horizontally-spaced__item __aaee5__tool-list-black"
                  >
                    SpreadServe
                  </a>
                </div>
              </div>
            </section>
            <section className="section-padding-quarter border-bottom u-vertically-spaced__item">
              <div className="u-cross-axis-left-aligned services link-boxes">
                <h4 className="u-margin-bottom-10 __aaee5__library-list">
                  Finance and Trading Platforms
                </h4>
                <div className="u-horizontally-spaced u-horizontally-spaced--narrow">
                  <a
                    href="https://www.amibroker.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="u-horizontally-spaced__item __aaee5__tool-list-black"
                  >
                    AmiBroker
                  </a>
                  <a
                    href="https://www.quantopian.com"
                    target="_blank"
                    rel="noreferrer"
                    className="u-horizontally-spaced__item __aaee5__tool-list-black"
                  >
                    Quantopian
                  </a>
                  <a
                    href="https://www.synvero.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="u-horizontally-spaced__item __aaee5__tool-list-black"
                  >
                    Synvero
                  </a>
                  <a
                    href="https://tickblaze.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="u-horizontally-spaced__item __aaee5__tool-list-black"
                  >
                    Tickblaze
                  </a>
                  <a
                    href="https://www.tradingview.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="u-horizontally-spaced__item __aaee5__tool-list-black"
                  >
                    TradingView
                  </a>
                  <a
                    href="http://www.wealth-lab.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="u-horizontally-spaced__item __aaee5__tool-list-black"
                  >
                    Wealth-Lab
                  </a>
                  <a
                    href="http://wootrader.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="u-horizontally-spaced__item __aaee5__tool-list-black"
                  >
                    WooTrader
                  </a>
                </div>
              </div>
            </section>
            <section className="section-padding-quarter border-bottom u-vertically-spaced__item">
              <div className="u-cross-axis-left-aligned services link-boxes">
                <h4 className="u-margin-bottom-10 __aaee5__library-list">
                  Visualization and Analytics Platforms
                </h4>
                <div className="u-horizontally-spaced u-horizontally-spaced--narrow">
                  <a
                    href="https://www.modeanalytics.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="u-horizontally-spaced__item __aaee5__tool-list-black"
                  >
                    Mode
                  </a>
                </div>
              </div>
            </section>
            <section className="section-padding-quarter border-bottom u-vertically-spaced__item">
              <div className="u-cross-axis-left-aligned services link-boxes">
                <h4 className="u-margin-bottom-10 __aaee5__library-list">
                  Applications Using Nasdaq Data Link Data
                </h4>
                <div className="u-horizontally-spaced u-horizontally-spaced--narrow">
                  <a
                    href="https://www.accompany.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="u-horizontally-spaced__item __aaee5__tool-list-black"
                  >
                    Accompany
                  </a>
                  <a
                    href="http://www.augurworks.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="u-horizontally-spaced__item __aaee5__tool-list-black"
                  >
                    Augurworks
                  </a>
                  <a
                    href="http://www.ayasdi.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="u-horizontally-spaced__item __aaee5__tool-list-black"
                  >
                    Ayasdi
                  </a>
                  <a
                    href="http://bluvisor.com/home"
                    target="_blank"
                    rel="noreferrer"
                    className="u-horizontally-spaced__item __aaee5__tool-list-black"
                  >
                    BluVisor
                  </a>
                  <a
                    href="https://www.clearmacro.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="u-horizontally-spaced__item __aaee5__tool-list-black"
                  >
                    ClearMacro
                  </a>
                  <a
                    href="https://www.cloud9trader.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="u-horizontally-spaced__item __aaee5__tool-list-black"
                  >
                    Cloud9Trader
                  </a>
                  <a
                    href="https://www.commodity-screen.com"
                    target="_blank"
                    rel="noreferrer"
                    className="u-horizontally-spaced__item __aaee5__tool-list-black"
                  >
                    Commodity Screen
                  </a>
                  <a
                    href="https://www.crudecharts.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="u-horizontally-spaced__item __aaee5__tool-list-black"
                  >
                    CrudeCharts
                  </a>
                  <a
                    href="http://www.datasimply.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="u-horizontally-spaced__item __aaee5__tool-list-black"
                  >
                    Data Simply
                  </a>
                  <a
                    href="https://www.domo.com"
                    target="_blank"
                    rel="noreferrer"
                    className="u-horizontally-spaced__item __aaee5__tool-list-black"
                  >
                    Domo
                  </a>
                  <a
                    href="http://www.econometricstudios.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="u-horizontally-spaced__item __aaee5__tool-list-black"
                  >
                    Econometric Studios
                  </a>
                  <a
                    href="https://www.esharesinc.com"
                    target="_blank"
                    rel="noreferrer"
                    className="u-horizontally-spaced__item __aaee5__tool-list-black"
                  >
                    eShares
                  </a>
                  <a
                    href="http://www.fna.ca/"
                    target="_blank"
                    rel="noreferrer"
                    className="u-horizontally-spaced__item __aaee5__tool-list-black"
                  >
                    FNA
                  </a>
                  <a
                    href="http://www.genovest.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="u-horizontally-spaced__item __aaee5__tool-list-black"
                  >
                    Genovest
                  </a>
                  <a
                    href="http://www.investglass.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="u-horizontally-spaced__item __aaee5__tool-list-black"
                  >
                    InvestGlass
                  </a>
                  <a
                    href="https://www.kensho.com"
                    target="_blank"
                    rel="noreferrer"
                    className="u-horizontally-spaced__item __aaee5__tool-list-black"
                  >
                    Kensho
                  </a>
                  <a
                    href="http://www.llhdata.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="u-horizontally-spaced__item __aaee5__tool-list-black"
                  >
                    LLH Data
                  </a>
                  <a
                    href="http://saplumira.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="u-horizontally-spaced__item __aaee5__tool-list-black"
                  >
                    Lumira
                  </a>
                  <a
                    href="https://www.money.net/"
                    target="_blank"
                    rel="noreferrer"
                    className="u-horizontally-spaced__item __aaee5__tool-list-black"
                  >
                    Money.net
                  </a>
                  <a
                    href="http://www.portfoliostrat.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="u-horizontally-spaced__item __aaee5__tool-list-black"
                  >
                    PortfolioStrat
                  </a>
                  <a
                    href="http://www.premise.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="u-horizontally-spaced__item __aaee5__tool-list-black"
                  >
                    Premise
                  </a>
                  <a
                    href="http://www.profitspi.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="u-horizontally-spaced__item __aaee5__tool-list-black"
                  >
                    Profitspi
                  </a>
                  <a
                    href="https://www.prudena.com"
                    target="_blank"
                    rel="noreferrer"
                    className="u-horizontally-spaced__item __aaee5__tool-list-black"
                  >
                    Prudena
                  </a>
                  <a
                    href="https://www.quantcha.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="u-horizontally-spaced__item __aaee5__tool-list-black"
                  >
                    Quantcha
                  </a>
                  <a
                    href="https://www.quantifiedalpha.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="u-horizontally-spaced__item __aaee5__tool-list-black"
                  >
                    Quantified Alpha
                  </a>
                  <a
                    href="http://www.sentieo.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="u-horizontally-spaced__item __aaee5__tool-list-black"
                  >
                    Sentieo
                  </a>
                  <a
                    href="http://www.stockrover.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="u-horizontally-spaced__item __aaee5__tool-list-black"
                  >
                    Stock Rover
                  </a>
                  <a
                    href="http://www.tangramsolutions.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="u-horizontally-spaced__item __aaee5__tool-list-black"
                  >
                    Tangram Solutions
                  </a>
                  <a
                    href="https://github.com/thalesians/pythalesians/"
                    target="_blank"
                    rel="noreferrer"
                    className="u-horizontally-spaced__item __aaee5__tool-list-black"
                  >
                    The Thalesians
                  </a>
                  <a
                    href="https://www.tiingo.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="u-horizontally-spaced__item __aaee5__tool-list-black"
                  >
                    Tiingo
                  </a>
                  <a
                    href="http://www.tradelegs.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="u-horizontally-spaced__item __aaee5__tool-list-black"
                  >
                    Tradelegs
                  </a>
                  <a
                    href="https://www.tradeo.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="u-horizontally-spaced__item __aaee5__tool-list-black"
                  >
                    Tradeo
                  </a>
                  <a
                    href="http://www.updata.co.uk/"
                    target="_blank"
                    rel="noreferrer"
                    className="u-horizontally-spaced__item __aaee5__tool-list-black"
                  >
                    Updata
                  </a>
                </div>
              </div>
            </section>
          </div>
          <section className="u-vertically-spaced__item">
            <h5 className="__aaee5__footer">
              Built something using Nasdaq Data Link? Let us know and we will
              list you here.
            </h5>
          </section>
        </div>
      </div>
    </section>
  );
};

// eslint-disable-next-line import/prefer-default-export
export { Basic as TechnologiesBasic };
