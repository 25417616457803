import {
  Button,
  FormField,
  FormRadioCheckboxButton,
  Modal,
  ModalHeader
} from "@nef/core";
import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import "./style.scss";
import {
  SubmitHandler,
  useForm,
  Controller,
  Control,
  useWatch
} from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { getCountries, getEnchiridionData, userSignup } from "../../../api/api";
import { useGlobalModalContext } from "../../../context/modal-context";
import { AUMController } from "../../adp-signup-form";

const BUY_SIDE_INSTITUTION_OPTIONS = "8";
const USA_VALUE = "United States";

type Country = {
  id: number;
  name: string;
  sort_order: number;
};

type EnchiridionType = {
  title: string;
  "sort-order": number;
  "short-form": string;
  id?: string;
  type?: string;
};

const schema = yup
  .object({
    firstName: yup
      .string()
      .max(50, "first name is too long")
      .required("Enter first name"),
    lastName: yup
      .string()
      .max(50, "last name is too long")
      .required("Enter last name"),
    organizationName: yup.string().required("Enter company name"),
    country: yup.string().required("Select country"),
    email: yup
      .string()
      .required("Enter email")
      .min(3, "must be at least 3 characters long")
      .email("Invalid email"),
    phone: yup.string().required("Enter phone number"),
    jobTitle: yup.string().required("Enter job title"),
    businessType: yup.string().required("Select your company's business type"),
    assetsUnderManagement: yup.string().when("businessType", {
      is: BUY_SIDE_INSTITUTION_OPTIONS,
      then: _schema => _schema.required("Select your company's AUM"),
      otherwise: _schema => _schema
    }),
    numberOfEmployees: yup
      .string()
      .required("Select your company's number of employees"),
    dataUsage: yup.string().required("Select the data usage"),
    recaptcha: yup.string().required("Captcha is invalid"),
    allowOptin: yup.bool(),

    isAgree: yup.boolean().oneOf([true], "MUST BE ACCEPTED")
  })
  .required();

type FormType = yup.InferType<typeof schema>;

function OptinController({ control }: { control: Control<any> }) {
  const country = useWatch({
    control,
    name: "country"
  });

  if (!country || country === "" || country === "United States") return null;
  return (
    <Controller
      name="allowOptin"
      control={control}
      defaultValue={false}
      render={({ field: { onChange, value, name } }) => (
        <FormRadioCheckboxButton
          id="allowOptin"
          name={name}
          type="checkbox"
          label="To comply with local law Nasdaq needs your permission to send you relevant information. If you do not opt-in now, we can`t send you emails."
          checked={value}
          optional={true}
          onChange={onChange}
          data-testid="signupform_optIn"
        />
      )}
    />
  );
}

function ADPSignupModal() {
  const [countries, setCountries] = useState<Country[]>([]);
  useEffect(() => {
    try {
      getCountries().then(res =>
        setCountries(
          res.sort((a: Country, b: Country) =>
            a.sort_order > b.sort_order ? 1 : -1
          )
        )
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("data:::", error);
    }
  }, []);
  const [
    [
      businessDataUsage = [],
      assetsUnderManagement = [],
      businessType = [],
      numberOfEmployees = []
    ],
    setEnchiridionData
  ] = useState<EnchiridionType[][]>([]);
  useEffect(() => {
    const enchiridionDataList = [
      "business_planned_data_usages",
      "assets_under_management",
      "business_types",
      "number_of_employees"
    ];

    try {
      Promise.all(
        enchiridionDataList.map(data => getEnchiridionData(data))
      ).then(enchiridionData => {
        setEnchiridionData(enchiridionData);
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("data:::", error);
    }
  }, []);

  const onSubmit: SubmitHandler<FormType> = data => {
    const payload = {
      user_type: "business",
      first_name: data.firstName,
      last_name: data.lastName,
      organization_name: data.organizationName,
      address_country: data.country,
      email: data.email,
      phone: data.phone,
      job_title: data.jobTitle,
      business_type_id: data.businessType,
      assets_under_management_id:
        data.businessType === BUY_SIDE_INSTITUTION_OPTIONS
          ? data.assetsUnderManagement
          : null,
      number_of_employee_id: data.numberOfEmployees,
      planned_data_usage_ids: [data.dataUsage],
      recaptcha_response: data.recaptcha,
      optin_newsletter: data.country === USA_VALUE ? true : data.allowOptin
    };

    try {
      userSignup(payload).catch(error => {
        return error;
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("data:::", error);
    }

    hideModal();
  };

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  const { hideModal } = useGlobalModalContext();

  return (
    <Modal
      size="lg"
      isOpen={true}
      toggle={hideModal}
      data-testid="ADPSignupModal"
    >
      <ModalHeader toggle={hideModal} />
      <div className="adp-signup-modal">
        <div className="formHeaderContainer" data-testid="adp-signup">
          <h4 className="formHint">For Business Users Only</h4>
          <span className="with-top-blue-line" />
          <h3 className="formHead">Create a Business Account</h3>
          <p className="formHeadSub">
            Alternative data is reserved for business account holders only.
          </p>
        </div>
        <form className="formItemContainer" onSubmit={handleSubmit(onSubmit)}>
          <div className="formItem">
            <Controller
              name="firstName"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value, name } }) => (
                <FormField
                  id="firstName"
                  name={name}
                  value={value}
                  onChange={onChange}
                  label="FIRST NAME"
                  type="text"
                  placeholder="Type here..."
                  data-testid="signupForm_firstName"
                  invalid={!!errors.firstName}
                  feedback={errors.firstName?.message}
                />
              )}
            />
          </div>

          <div className="formItem">
            <Controller
              name="lastName"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value, name } }) => (
                <FormField
                  id="lastName"
                  name={name}
                  label="LAST NAME"
                  type="text"
                  placeholder="Type here..."
                  value={value}
                  onChange={onChange}
                  data-testid="signupForm_lastName"
                  invalid={!!errors.lastName}
                  feedback={errors.lastName?.message}
                />
              )}
            />
          </div>

          <div className="formItem">
            <Controller
              name="organizationName"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value, name } }) => (
                <FormField
                  id="organizationName"
                  name={name}
                  label="COMPANY NAME"
                  type="text"
                  placeholder="Type here..."
                  value={value}
                  onChange={onChange}
                  data-testid="signupForm_organizationName"
                  invalid={!!errors.organizationName}
                  feedback={errors.organizationName?.message}
                />
              )}
            />
          </div>
          <div className="formItem">
            <Controller
              name="country"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value, name } }) => (
                <FormField
                  id="country"
                  name={name}
                  label="COUNTRY"
                  type="select"
                  placeholder="Select..."
                  value={value}
                  onChange={onChange}
                  data-testid="signupForm_country"
                  invalid={!!errors.country}
                  feedback={errors.country?.message}
                >
                  <option value="" disabled>
                    Select...
                  </option>
                  {countries.map((item: any) => (
                    <option value={item.name} key={item.id}>
                      {item.name}
                    </option>
                  ))}
                </FormField>
              )}
            />
          </div>
          <div className="formItem">
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value, name } }) => (
                <FormField
                  id="email"
                  name={name}
                  label="BUSINESS EMAIL"
                  type="text"
                  placeholder="Type here..."
                  value={value}
                  onChange={onChange}
                  data-testid="signupForm_email"
                  invalid={!!errors.email}
                  feedback={errors.email?.message}
                />
              )}
            />
          </div>
          <div className="formItem">
            <Controller
              name="phone"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value, name } }) => (
                <FormField
                  id="phone"
                  name={name}
                  label="BUSINESS PHONE"
                  type="text"
                  placeholder="Type here..."
                  invalid={!!errors.phone}
                  feedback={errors.phone?.message}
                  value={value}
                  onChange={onChange}
                  data-testid="signupForm_phone"
                />
              )}
            />
          </div>
          <div className="formItem">
            <Controller
              name="jobTitle"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value, name } }) => (
                <FormField
                  id="jobTitle"
                  name={name}
                  label="JOB TITLE"
                  type="text"
                  placeholder="Type here..."
                  value={value}
                  onChange={onChange}
                  data-testid="signupForm_jobTitle"
                  invalid={!!errors.jobTitle}
                  feedback={errors.jobTitle?.message}
                />
              )}
            />
          </div>
          <div className="formItem">
            <Controller
              name="businessType"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value, name } }) => (
                <FormField
                  id="businessType"
                  name={name}
                  label="Business Type"
                  type="select"
                  placeholder="Select..."
                  value={value}
                  onChange={onChange}
                  data-testid="signupForm_businessType"
                  invalid={!!errors.businessType}
                  feedback={errors.businessType?.message}
                >
                  <option value="" disabled defaultValue="">
                    Select...
                  </option>
                  {businessType.map((item: any) => (
                    <option value={item.id} key={item.id}>
                      {item.title}
                    </option>
                  ))}
                </FormField>
              )}
            />
          </div>
          <div className="formItem">
            <AUMController control={control} errors={errors}>
              {assetsUnderManagement.map((item: any) => (
                <option value={item.id} key={item.id}>
                  {item.title}
                </option>
              ))}
            </AUMController>
          </div>
          <div className="formItem">
            <Controller
              name="numberOfEmployees"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value, name } }) => (
                <FormField
                  id="numberOfEmployees"
                  name={name}
                  label="NUMBER OF EMPLOYEES"
                  type="select"
                  placeholder="Select..."
                  value={value}
                  onChange={onChange}
                  data-testid="signupForm_numberOfEmployees"
                  invalid={!!errors.numberOfEmployees}
                  feedback={errors.numberOfEmployees?.message}
                >
                  <option value="" disabled defaultValue="">
                    Select...
                  </option>
                  {numberOfEmployees.map((item: any) => (
                    <option value={item.id} key={item.id}>
                      {item.title}
                    </option>
                  ))}
                </FormField>
              )}
            />
          </div>
          <div className="formItem">
            <Controller
              name="dataUsage"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value, name } }) => (
                <FormField
                  id="dataUsage"
                  name={name}
                  label="HOW WILL YOU BE USING THIS DATA *"
                  type="select"
                  placeholder="Select..."
                  value={value}
                  onChange={onChange}
                  data-testid="signupForm_dataUsage"
                  invalid={!!errors.dataUsage}
                  feedback={errors.dataUsage?.message}
                >
                  <option value="" disabled defaultValue="">
                    Select...
                  </option>
                  {businessDataUsage.map((item: any) => (
                    <option value={item.id} key={item.id}>
                      {item.title}
                    </option>
                  ))}
                </FormField>
              )}
            />
          </div>
          <div className="formRow">
            <div className="formItem">
              <Controller
                name="recaptcha"
                control={control}
                defaultValue=""
                render={({ field: { onChange } }) => (
                  <>
                    <ReCAPTCHA
                      className={`${errors.recaptcha && "formError"}`}
                      sitekey={
                        process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY as string
                      }
                      onChange={onChange}
                      size="normal"
                    />
                    {errors.recaptcha?.message && (
                      <small color="muted" className="formErrorMsg">
                        {errors.recaptcha?.message}
                      </small>
                    )}
                  </>
                )}
              />
            </div>
            <div className="formItem">
              <div>
                <Controller
                  name="isAgree"
                  control={control}
                  defaultValue={false}
                  render={({ field: { onChange, value, name } }) => (
                    <FormRadioCheckboxButton
                      id="agree"
                      name={name}
                      type="checkbox"
                      label={
                        (
                          <span>
                            I have agreed to the{" "}
                            <a href="https://www.nasdaq.com/legal">terms</a> of
                            service and{" "}
                            <a href="https://www.nasdaq.com/privacy-statement">
                              privacy policy
                            </a>
                          </span>
                        ) as any
                      }
                      checked={value}
                      onChange={onChange}
                      data-testid="signupForm_termsOfService"
                      invalid={!!errors.isAgree}
                      feedback={errors.isAgree?.message}
                    />
                  )}
                />
              </div>
              <OptinController control={control} />
            </div>
          </div>
          <div className="oktaHint">
            <h3 className="oktaHintHead">Nasdaq OKTA</h3>
            <p className="oktaHintSub">
              Nasdaq Data Link is happy to announce we have migrated with
              Nasdaq's Signin. If you do not have Nasdaq user account, one will
              be created for you. Please keep an eye out for your activation
              email from Nasdaq Data Link.
            </p>
          </div>
          <div className="formSubmit">
            <Button
              block={true}
              type="submit"
              data-testid="signupForm_createAccount"
            >
              CREATE ACCOUNT
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default ADPSignupModal;
