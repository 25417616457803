import React, { useEffect, useState, useMemo, useRef } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  ArrowLeftIcon,
  PlusCircledIcon,
  CheckCircledIcon,
  Cross1Icon
} from "@radix-ui/react-icons";
import { Box, Button, Flex, NotificationHub } from "@nef/core";
import moment from "moment";
import CopyToClipboard from "react-copy-to-clipboard";
import { isEqual } from "lodash";

import {
  getOrganizationApiKeys,
  getOrganizationApiKey,
  updateOrganizationApiKeyTeam
} from "../../../../api/api";
import {
  MODAL_TYPES,
  useGlobalModalContext
} from "../../../../context/modal-context";
import type {
  OrganizationApiKey,
  OrganizationTeam
} from "../../../../api/types";
import styles from "../style.module.scss";

export function OrganizationApiPage({
  slug,
  organizationId
}: {
  slug: string;
  organizationId: number;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [apiKeys, setApiKeys] = useState<OrganizationApiKey[]>([]);
  const { showModal } = useGlobalModalContext();
  const isMountRef = useRef<boolean>(false);
  const requestData = (id: number) => {
    setIsLoading(true);
    getOrganizationApiKeys(id)
      .then(keys => {
        if (isMountRef.current) {
          setApiKeys(keys);
        }
      })
      .finally(() => {
        if (isMountRef.current) {
          setIsLoading(false);
        }
      });
  };
  useEffect(() => {
    requestData(organizationId);
  }, [organizationId]);

  useEffect(() => {
    isMountRef.current = true;
    return () => {
      isMountRef.current = false;
    };
  }, []);

  const onCopyNotification = (key: string) => {
    if (key) {
      NotificationHub.send("primary", "Key copied to clipboard!");
    }
  };

  if (isLoading) {
    return null;
  }

  return (
    <div className={styles.apiPage}>
      <h1 className={`${styles.apiTitle} with-top-blue-line`}>API Keys</h1>
      <div className={styles.apiFirstLine}>
        <p>Create and manage your Nasdaq Data Link API keys.</p>
        <Button
          onClick={() => {
            showModal(MODAL_TYPES.NEW_API_KEY_MODAL, {
              organizationId,
              callbackAfterCreated: () => {
                requestData(organizationId);
              }
            });
          }}
        >
          + New API Key
        </Button>
      </div>
      <div className={styles.apiTable}>
        <div className={styles.apiHeaderRow}>
          <div className={styles.apiHeaderName}>Name</div>
          <div className={styles.apiHeaderNumProduct}># OF PRODUCTS</div>
          <div className={styles.apiHeaderDateCreated}>DATE CREATE</div>
          <div className={styles.apiHeaderApiKey}>API KEY</div>
        </div>
        {apiKeys.map((key: OrganizationApiKey) => (
          <Link
            to={`/organizations-db/${slug}/api-keys/${key.id}`}
            key={key.id}
            className={styles.apiKeyRow}
          >
            <div className={styles.apiKeyName}>{key.name}</div>
            <div className={styles.apiKeyCount}>{key.count}</div>
            <div className={styles.apiKeyDate}>
              {moment(key.createdAt).format("MM/DD/YYYY")}
            </div>
            <div
              className={styles.apiKeyBox}
              onClickCapture={event => {
                event.preventDefault();
              }}
            >
              <CopyToClipboard text={key.token} onCopy={onCopyNotification}>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/icons/copy-api-key.svg`}
                  alt="Bookmarks icon"
                  className={styles.copyIcon}
                />
              </CopyToClipboard>
              <div
                className={styles.copyToken}
              >{`*****************${key.token.substring(15)}`}</div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export function OrganizationApiDetailPage({
  slug,
  organizationTeams
}: {
  slug: string;
  organizationTeams: OrganizationTeam[];
}) {
  const { id } = useParams<{
    id: string;
  }>();

  const [apiKeyDetail, setApiKeyDetail] = useState<OrganizationApiKey>();

  useEffect(() => {
    getOrganizationApiKey(id).then(setApiKeyDetail);
  }, [id]);
  const [entitlement, setEntitlement] = useState<Record<string, number>>({});
  const [defaultEntitlement, setDefaultEntitlement] =
    useState<Record<string, number>>(entitlement);
  useEffect(() => {
    const tmpEntitlement: Record<string, number> = {};

    setDefaultEntitlement({});
    if (apiKeyDetail) {
      apiKeyDetail.organizationTeamIds.forEach((teamId: number) => {
        const findIndex = organizationTeams.findIndex(
          (team: OrganizationTeam) => {
            return team.id === teamId;
          }
        );
        if (findIndex !== -1) {
          tmpEntitlement[`${teamId}`] = 1;
        }
      });
      setDefaultEntitlement(tmpEntitlement);
      setEntitlement(tmpEntitlement);
    }
  }, [id, apiKeyDetail, organizationTeams]);
  const toggleEntitlement = (teamId: string) => {
    const tmpEntitlement = { ...entitlement };
    if (tmpEntitlement[teamId]) {
      delete tmpEntitlement[teamId];
    } else {
      tmpEntitlement[teamId] = 1;
    }
    setEntitlement(tmpEntitlement);
  };

  const teamNameMap = useMemo<Record<string, string>>(() => {
    const result: Record<string, string> = {};
    organizationTeams.forEach((team: OrganizationTeam) => {
      if (entitlement[`${team.id}`]) {
        result[`${team.id}`] = team.name;
      }
    });
    return result;
  }, [organizationTeams, entitlement]);
  const history = useHistory();
  const onCopyNotification = (key: string) => {
    if (key) {
      NotificationHub.send("primary", "Key copied to clipboard!");
    }
  };
  const { showModal } = useGlobalModalContext();
  return (
    <div className={styles.apiDetailPage}>
      {apiKeyDetail && (
        <>
          <Link
            to={`/organizations-db/${slug}/api-keys`}
            className={styles.backLink}
          >
            <ArrowLeftIcon />
            <span className={styles.backLinkText}>BACK TO ALL API KEYS</span>
          </Link>
          <div className={styles.apiDetailHeadContainer}>
            <div className={styles.apiDetailHeadContent}>
              <div className={styles.apiDetailHeader}>
                <h2 className={`with-top-blue-line ${styles.apiDetailTitle}`}>
                  {apiKeyDetail.name}
                </h2>
                <Flex>
                  <Box marginRight={1}>
                    <b>Created On:</b>
                  </Box>
                  <Box marginLeft={2}>
                    {moment(apiKeyDetail.createdAt).format("MM/DD/YYYY")}
                  </Box>
                </Flex>
              </div>

              <div className={styles.apiKeyBox}>
                <CopyToClipboard
                  text={apiKeyDetail.token}
                  onCopy={onCopyNotification}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/icons/copy-api-key.svg`}
                    alt="Bookmarks icon"
                    className={styles.copyIcon}
                  />
                </CopyToClipboard>
                <div
                  className={styles.apiDetailCopyToken}
                >{`*****************${apiKeyDetail.token.substring(15)}`}</div>
              </div>
            </div>
            <div className={styles.apiDetailHeadAction}>
              <Button
                color="danger"
                onClick={() => {
                  showModal(MODAL_TYPES.DELETE_API_KEY_MODAL, {
                    apiKeyId: apiKeyDetail.id,
                    apiKeyName: apiKeyDetail.name,
                    finishCallback: () => {
                      history.replace(`/organizations-db/${slug}/api-keys`);
                    }
                  });
                }}
              >
                Delete
              </Button>
              {!isEqual(entitlement, defaultEntitlement) && (
                <Box marginLeft={1}>
                  <Button
                    onClick={() => {
                      const teamIds = Object.keys(entitlement);
                      updateOrganizationApiKeyTeam(id, teamIds).then(() => {
                        setDefaultEntitlement(entitlement);
                        NotificationHub.send(
                          "primary",
                          "Entitlement has been updated"
                        );
                      });
                    }}
                  >
                    Save Changes
                  </Button>
                </Box>
              )}
            </div>
          </div>
          <div>
            <Box paddingVertical={4}>
              <h3 className={`with-top-blue-line ${styles.apiDetailTitle}`}>
                Product Access
              </h3>
            </Box>
            <div className={styles.apiDetailPermissionControl}>
              <div className={styles.apiDetailProductList}>
                {organizationTeams.map((team: OrganizationTeam) => (
                  <div className={styles.apiDetailProductItem} key={team.id}>
                    <span className={styles.apiDetailProductText}>
                      {team.name}
                    </span>
                    <Button
                      color="link"
                      onClick={() => toggleEntitlement(`${team.id}`)}
                    >
                      <Box marginHorizontal={2}>
                        {entitlement[`${team.id}`] ? (
                          <CheckCircledIcon
                            className={`${styles.accessCheck} ${styles.accessIcon}`}
                          />
                        ) : (
                          <PlusCircledIcon className={styles.accessIcon} />
                        )}
                      </Box>
                    </Button>
                  </div>
                ))}
              </div>
              <div className={styles.apiDetailEntitlementList}>
                <div className={styles.apiDetailProductItem}>
                  <span
                    className={`${styles.apiDetailProductText} ${styles.entitlementTitle}`}
                  >
                    Entitlement
                  </span>
                </div>
                <div className={styles.entitlementBody}>
                  {Object.keys(teamNameMap).map(teamId => (
                    <div className={styles.entitlementItem} key={teamId}>
                      <span className={styles.entitlementItemText}>
                        {teamNameMap[teamId]}
                      </span>
                      <Button
                        color="link"
                        size="sm"
                        onClick={() => {
                          toggleEntitlement(`${teamId}`);
                        }}
                      >
                        <Cross1Icon className={styles.entitlementCloseIcon} />
                      </Button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
