import React, { useRef, useEffect } from "react";

import {
  IButtonActionFields,
  IFeaturesGridFields
} from "../../../../../contentful/__generated__/types";
import "./style.scss";
import { useTrackSignUpClick } from "../../../../analytics/hooks/useAnalyticsEvents";
import { ActionButton } from "../../button/action";

type Props = {
  fields: IFeaturesGridFields;
};

const THEMES = [
  { name: "Black/Green", class: "black-green-theme", backgroundColor: "black" },
  { name: "White/Black", class: "white-black-theme", backgroundColor: "white" },
  { name: "Transparent", class: "transparent-theme", backgroundColor: "none" },
  {
    name: "White/Black/Blue with Buttons",
    class: "white-black-blue-theme",
    backgroundColor: "white"
  }
];

const Grid: React.FC<Props> = ({ fields }) => {
  const trackSignUpClick = useTrackSignUpClick();
  const {
    features,
    icon,
    backgroundImage,
    theme,
    alignment,
    iconSizing,
    ctaIcon
  } = fields;

  const elementsRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add("feature__card__visible");
          observer.unobserve(entry.target);
        }
      });
    });

    document.querySelectorAll(".feature__card__div").forEach(element => {
      observer.observe(element);
    });
  }, []);

  const themeColor = THEMES.find(t => t.name === theme)?.backgroundColor;
  const themeClass = THEMES.find(t => t.name === theme)?.class;

  const background = {
    backgroundImage: `url(https://${backgroundImage?.fields.file.url})`,
    backgroundColor: themeColor
  };

  const gridClass = alignment === "Justify" ? "justify" : "";
  const iconClass = iconSizing === "Square" ? "square" : "";

  const handleCtaClick = (analyticsEventName?: string) => {
    if (analyticsEventName === "Clicked Sign Up") {
      trackSignUpClick();
    }
  };

  return (
    <div style={background} className="featuresgrid__section-wrapper">
      <div
        className={`section-content section-content--no-bottom-margin ${themeClass}`}
      >
        <div ref={elementsRef} className={`product-boxes ${gridClass}`}>
          {!!features &&
            features.map((feature, index) => {
              const buttonType =
                feature.fields.ctaButton?.sys.contentType.sys.id;
              return (
                <a
                  key={feature.sys.id}
                  href={
                    (buttonType === "button" &&
                      // @ts-ignore
                      feature.fields.ctaButton?.fields.url) ||
                    feature.fields.ctaUrl
                  }
                  className={
                    feature.fields.ctaUrl || feature.fields.ctaButton
                      ? "feature__card__link feature__card__div"
                      : "feature__card__div"
                  }
                  style={{ animationDelay: `${(index + 1) * 0.5}s` }}
                >
                  <div className="feature__card-wrapper">
                    <div
                      className={
                        feature.fields.ctaUrl || feature.fields.ctaButton
                          ? "feature__link__wrapper"
                          : "feature__wrapper"
                      }
                    >
                      {!!feature.fields.label && (
                        <h3 className="feature__status">
                          {feature.fields.label}
                        </h3>
                      )}
                      {icon && (
                        <div>
                          <img
                            src={`https://${feature.fields.icon?.fields.file.url}`}
                            alt={feature.fields.icon?.fields.title}
                            className={`feature__icon ${iconClass}`}
                          />
                        </div>
                      )}
                      <h3 className="feature__title with-top-white-line">
                        {feature.fields.heading}
                      </h3>
                      <p className="feature__body">{feature.fields.body}</p>
                      {feature.fields.ctaButton &&
                      buttonType === "buttonAction" ? (
                        <ActionButton
                          fields={
                            feature.fields.ctaButton
                              .fields as IButtonActionFields
                          }
                        />
                      ) : (
                        (!!feature.fields.ctaUrl ||
                          buttonType === "button") && (
                          <button
                            type="button"
                            className="feature__link"
                            onClick={() =>
                              handleCtaClick(
                                feature?.fields?.analyticsEventName
                              )
                            }
                          >
                            {feature.fields.ctaButton?.fields.label ??
                              feature.fields.ctaLabel}
                            {ctaIcon && (
                              <div className="feature__link__arrow">
                                {" "}
                                <svg
                                  viewBox="0 0 448 512"
                                  xmlns="http://www.w3.org/2000/svg"
                                  role="img"
                                  focusable="false"
                                  aria-hidden="true"
                                  data-icon="long-arrow-alt-right"
                                  data-prefix="fas"
                                  className="svg-inline--fa fa-long-arrow-alt-right fa-w-14"
                                >
                                  <path
                                    fill="currentColor"
                                    d="M313.941 216H12c-6.627 0-12 5.373-12 12v56c0 6.627 5.373 12 12 12h301.941v46.059c0 21.382 25.851 32.09 40.971 16.971l86.059-86.059c9.373-9.373 9.373-24.569 0-33.941l-86.059-86.059c-15.119-15.119-40.971-4.411-40.971 16.971V216z"
                                  />
                                </svg>
                              </div>
                            )}
                          </button>
                        )
                      )}
                    </div>
                  </div>
                </a>
              );
            })}
        </div>
      </div>
    </div>
  );
};

// eslint-disable-next-line import/prefer-default-export
export { Grid as FeaturesGrid };
