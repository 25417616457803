import React from "react";
import { Modal, Button } from "@nef/core";
import { useHistory } from "react-router-dom";

import { useGlobalModalContext } from "../../context/modal-context";
import ModalCloseButton from "../modal-close-button/ModalCloseButton";

import "./style.scss";

export const LoginToBookmarkModal = () => {
  const { hideModal } = useGlobalModalContext();
  const history = useHistory();

  const redirectToPage = (route: "sign-up" | "login") => {
    hideModal();
    history.push(`/${route}`);
  };

  return (
    <Modal
      size="md"
      isOpen={true}
      onBlur={hideModal}
      toggle={hideModal}
      data-testid="loginBookmarkModal"
    >
      <section
        data-testid="loginToBookmarkModal"
        className="login-to-bookmark-modal"
      >
        <ModalCloseButton closeModal={hideModal} />
        <p className="login-to-bookmark-modal__text">
          Create an account to bookmark your favorite data and more.
        </p>
        <Button
          key="confirm"
          onClick={() => redirectToPage("sign-up")}
          data-testid="loginBookmarkModal_login"
        >
          Create Free Account
        </Button>
        <Button
          key="confirm"
          onClick={() => redirectToPage("login")}
          data-testid="loginBookmarkModal_login"
        >
          Log In
        </Button>
      </section>
    </Modal>
  );
};

export default LoginToBookmarkModal;
