import React from "react";
import { Redirect, Route } from "react-router-dom";

import useAuth from "../../hooks/useAuth";
import useUser from "../../hooks/useUser";

const AdminRoute = ({ component: Component, ...rest }: any) => {
  const { authenticated } = useAuth();
  const { isAdmin } = useUser();

  const canAccess = authenticated() && isAdmin();

  return (
    <Route
      {...rest}
      render={(props): any => {
        if (!canAccess) {
          return <Redirect to="/" />;
        }

        return <Component {...rest} {...props} />;
      }}
    />
  );
};

export default AdminRoute;
