import React, { useEffect, useState } from "react";

import { IProductsListFields } from "../../../../../contentful/__generated__/types";
import "./style.scss";
import { getDataCollections } from "../../../../api/api";

type Props = {
  fields: IProductsListFields;
};

type ProductProps = {
  datatableCollection: {
    code: string;
    name: string;
    description: string;
  };
};

const ProductCard = ({ entry }: { entry: ProductProps }) => {
  const { code, name, description } = entry.datatableCollection;

  return (
    <div className="product-card">
      <div className="product-card__overview-wrapper assets-wrapper">
        <a
          href={`/databases/${code}`}
          className="product-card__overview-content"
        >
          <div className="product-card__header product-card__header--with-content">
            {name}
          </div>
          <p className="product-card__content assets-content">{description}</p>
          <div className="product-link">
            View Product{" "}
            <svg
              viewBox="0 0 448 512"
              xmlns="http://www.w3.org/2000/svg"
              role="img"
              focusable="false"
              aria-hidden="true"
              data-icon="long-arrow-alt-right"
              data-prefix="fas"
              className="svg-inline--fa"
            >
              <path
                fill="currentColor"
                d="M313.941 216H12c-6.627 0-12 5.373-12 12v56c0 6.627 5.373 12 12 12h301.941v46.059c0 21.382 25.851 32.09 40.971 16.971l86.059-86.059c9.373-9.373 9.373-24.569 0-33.941l-86.059-86.059c-15.119-15.119-40.971-4.411-40.971 16.971V216z"
              />
            </svg>
          </div>
        </a>
      </div>
    </div>
  );
};

const List: React.FC<Props> = ({ fields }) => {
  const { heading1, heading2, body, terms } = fields;
  const [entries, setEntries] = useState<any>([]);

  const fetchProductsData = async (fieldsObject: any) => {
    const productValues = Object.keys(fieldsObject)
      .filter(key => key.includes("product"))
      .map(key => fieldsObject[key]);

    const promises = productValues.map(async productCode => {
      const { data: dataCollections } = await getDataCollections(productCode);
      return dataCollections;
    });
    const allDataCollections = await Promise.all(promises);
    return allDataCollections;
  };

  useEffect(() => {
    fetchProductsData(fields).then(allDataCollections => {
      setEntries(allDataCollections);
    });
  }, [fields]);

  return (
    <div className="prod__section-wrapper">
      <div className="rectangle rectangle--pink rectangle--second" />

      <div className="section-content section-content--column">
        <h1 className="state-header">{heading1}</h1>
        <h2 className="state-subheader state-subheader--reduced-bottom-margin">
          {heading2}
        </h2>
        <p className="state-info-body state-info-body--increased-bottom-margin">
          {body}
        </p>

        {!!entries &&
          entries.map((entry: ProductProps, index: number) => {
            // eslint-disable-next-line react/no-array-index-key
            return <ProductCard entry={entry} key={index} />;
          })}

        <p className="terms">{terms}</p>
      </div>
    </div>
  );
};

// eslint-disable-next-line import/prefer-default-export
export { List as ProductsList };
