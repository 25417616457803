import React, { useState, createContext, useContext } from "react";

import CreateModal from "../components/create-modal";
import ContactModal from "../components/contact-modal";
import PlainModal from "../components/plain-modal";
import SessionTimeoutModal from "../components/session-timeout-modal";
import { AcceptTouModal } from "../components/accept-tou-modal";
import LoginToBookmarkModal from "../components/login-to-bookmark-modal";
import SubscribingToPlanModal from "../components/subscribing-to-plan-modal";
import RegistrationSuccessModal from "../components/registration-success-modal";
import SupportRequestModal from "../components/modals/support-request-modal";
import ADPLeadForm from "../components/modals/adp-lead-form";
import ADPSignupModal from "../components/modals/adp-signup-modal";
import AcceptWelcome from "../components/modals/accept-welcome";
import NewApiKeyModal from "../components/modals/request-api-key-modal";
import DeleteApiKeyModal from "../components/modals/delete-api-key-modal";
import NewOrgApiKeyModal from "../components/modals/request-org-api-key-modal";
import AddUsersToOrgDatasetModal from "../components/modals/add-users-org-dataset";
import InviteUsersToOrgModal from "../components/modals/invite-user-to-org-modal";
import DatasetDocumentationModal from "../components/modals/dataset-documentation-modal";
import OrganizationConfirmationModal from "../components/modals/organization-confirmation-modal";
import AlternativeLeadModal from "../components/modals/alternative-lead-modal";
import DeleteAccountModal from "../components/modals/delete-account-modal";

export const MODAL_TYPES = {
  CREATE_MODAL: "CREATE_MODAL",
  CONTACT_MODAL: "CONTACT_MODAL",
  PLAIN_MODAL: "PLAIN_MODAL",
  SESSION_TIMEOUT_MODAL: "SESSION_TIMEOUT_MODAL",
  ACCEPT_TOU_MODAL: "ACCEPT_TOU_MODAL",
  LOGIN_TO_BOOKMARK_MODAL: "LOGIN_TO_BOOKMARK_MODAL",
  SUPPORT_REQUEST_MODAL: "SUPPORT_REQUEST_MODAL",
  SUBSCRIBING_TO_PLAN_MODAL: "SUBSCRIBING_TO_PLAN_MODAL",
  REGISTRATION_SUCCESS_MODAL: "REGISTRATION_SUCCESS_MODAL",
  ADP_LEAD_FORM: "ADP_LEAD_FORM",
  ADP_SIGNUP_FORM: "ADP_SIGNUP_FORM",
  ESG_WELCOME: "ESG_WELCOME",
  ACCEPT_WELCOME: "ACCEPT_WELCOME",
  NEW_API_KEY_MODAL: "NEW_API_KEY_MODAL",
  DELETE_API_KEY_MODAL: "DELETE_API_KEY_MODAL",
  NEW_ORG_API_KEY_MODAL: "NEW_ORG_API_KEY_MODAL",
  ADD_USERS_TO_ORG_DATASET: "ADD_USERS_TO_ORG_DATASET",
  INVITE_USERS_TO_ORG_MODAL: "INVITE_USERS_TO_ORG_MODAL",
  DATASET_DOCS_MODAL: "DATASET_DOCS_MODAL",
  ORGANIZATION_CONFIRMATION_MODAL: "ORGANIZATION_CONFIRMATION_MODAL",
  ALTERNATIVE_LEAD_FORM: "ALTERNATIVE_LEAD_FORM",
  DELETE_ACCOUNT_MODAL: "DELETE_ACCOUNT_MODAL"
};

const MODAL_COMPONENTS: any = {
  [MODAL_TYPES.CREATE_MODAL]: CreateModal,
  [MODAL_TYPES.CONTACT_MODAL]: ContactModal,
  [MODAL_TYPES.PLAIN_MODAL]: PlainModal,
  [MODAL_TYPES.SESSION_TIMEOUT_MODAL]: SessionTimeoutModal,
  [MODAL_TYPES.ACCEPT_TOU_MODAL]: AcceptTouModal,
  [MODAL_TYPES.LOGIN_TO_BOOKMARK_MODAL]: LoginToBookmarkModal,
  [MODAL_TYPES.SUPPORT_REQUEST_MODAL]: SupportRequestModal,
  [MODAL_TYPES.SUBSCRIBING_TO_PLAN_MODAL]: SubscribingToPlanModal,
  [MODAL_TYPES.REGISTRATION_SUCCESS_MODAL]: RegistrationSuccessModal,
  [MODAL_TYPES.ADP_LEAD_FORM]: ADPLeadForm,
  [MODAL_TYPES.ADP_SIGNUP_FORM]: ADPSignupModal,
  [MODAL_TYPES.ALTERNATIVE_LEAD_FORM]: AlternativeLeadModal,
  [MODAL_TYPES.ACCEPT_WELCOME]: AcceptWelcome,
  [MODAL_TYPES.NEW_API_KEY_MODAL]: NewApiKeyModal,
  [MODAL_TYPES.DELETE_API_KEY_MODAL]: DeleteApiKeyModal,
  [MODAL_TYPES.NEW_ORG_API_KEY_MODAL]: NewOrgApiKeyModal,
  [MODAL_TYPES.ADD_USERS_TO_ORG_DATASET]: AddUsersToOrgDatasetModal,
  [MODAL_TYPES.INVITE_USERS_TO_ORG_MODAL]: InviteUsersToOrgModal,
  [MODAL_TYPES.DATASET_DOCS_MODAL]: DatasetDocumentationModal,
  [MODAL_TYPES.ORGANIZATION_CONFIRMATION_MODAL]: OrganizationConfirmationModal,
  [MODAL_TYPES.DELETE_ACCOUNT_MODAL]: DeleteAccountModal
};

type GlobalModalContext = {
  showModal: (modalType: string, modalProps?: any) => void;
  hideModal: () => void;
  store: any;
};

type ModalProps = {
  children?: React.ReactNode;
};

const initalState: GlobalModalContext = {
  showModal: () => {},
  hideModal: () => {},
  store: {}
};

const GlobalModalContext = createContext(initalState);
export const useGlobalModalContext = () => useContext(GlobalModalContext);

export const GlobalModal: React.FC<ModalProps> = ({ children }) => {
  const [store, setStore] = useState({});
  const { modalType, modalProps }: any = store || {};

  const showModal = (modalTypeToShow: string, modalPropsToShow: any = {}) => {
    setStore({
      ...store,
      modalType: modalTypeToShow,
      modalProps: modalPropsToShow
    });
  };

  const hideModal = () => {
    setStore({
      ...store,
      modalType: null,
      modalProps: {}
    });
  };

  const renderComponent = () => {
    const ModalComponent = MODAL_COMPONENTS[modalType];
    if (!modalType || !ModalComponent) {
      return null;
    }
    return <ModalComponent id="global-modal" {...modalProps} />;
  };

  return (
    <GlobalModalContext.Provider value={{ store, showModal, hideModal }}>
      {renderComponent()}
      {children}
    </GlobalModalContext.Provider>
  );
};
