import React, { useEffect, useState } from "react";
import {
  Button,
  FormField,
  Modal,
  ModalHeader,
  NotificationHub
} from "@nef/core";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";

import { useGlobalModalContext } from "../../../context/modal-context";
import useTrackEvent from "../../../analytics/hooks/useTrackEvent";
import { getEnchiridionData, postLead } from "../../../api/api";
import { AUMController } from "../../adp-signup-form";

import styles from "./style.module.scss";

const BUY_SIDE_INSTITUTION_OPTIONS = "8";

type EnchiridionType = {
  title: string;
  "sort-order": number;
  "short-form": string;
  id?: string;
  type?: string;
};

const REASON_OPTIONS = [
  "I am educating myself about alternative data",
  "I want to know what datasets Nasdaq Data Link offers",
  "I am looking for specific datasets I need for my business",
  "I am sourcing data on behalf of someone else"
];
const PLAN_OPTIONS = [
  "the next month",
  "the next six months",
  "the next 12 months",
  "I am already using it"
];

const formSchema = yup
  .object({
    name: yup.string().max(50, "name is too long").required("Enter name"),
    jobTitle: yup.string().required("Enter job title"),
    organizationName: yup.string().required("Enter company name"),
    businessType: yup.string().required("Select your company's business type"),
    email: yup
      .string()
      .required("Enter email")
      .min(3, "must be at least 3 characters long")
      .email("Invalid email"),
    phone: yup.string().required("Enter business phone number"),
    assetsUnderManagement: yup.number().when("businessType", {
      is: BUY_SIDE_INSTITUTION_OPTIONS,
      then: _schema => _schema.required("Select company's AUM"),
      otherwise: _schema =>
        _schema.transform((val, orig) => (orig === "" ? undefined : val))
    }),
    reasonForAccess: yup.string().required("Select the reason for access"),
    usagePlan: yup.string().required("Select the data usage")
  })
  .required();

function AlternativeLeadModal() {
  const trackEvent = useTrackEvent();
  const { hideModal } = useGlobalModalContext();
  const [[assetsUnderManagement = [], businessType = []], setEnchiridionData] =
    useState<EnchiridionType[][]>([]);
  useEffect(() => {
    const enchiridionDataList = ["assets_under_management", "business_types"];

    try {
      Promise.all(
        enchiridionDataList.map(data => getEnchiridionData(data))
      ).then(enchiridionData => {
        setEnchiridionData(enchiridionData);
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("data:::", error);
    }
  }, []);
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(formSchema)
  });
  const onSubmit: SubmitHandler<yup.InferType<typeof formSchema>> = data => {
    const payload = {
      aum: data.assetsUnderManagement || null,
      comments: null,
      company: data.organizationName,
      email: data.email,
      "firm-type": data.businessType,
      "interested-in": "Alternative Data",
      key: null,
      "lead-category": null,
      "lead-type": "Institution Lead",
      name: data.name,
      phone: data.phone,
      plan: data.usagePlan,
      reason: data.reasonForAccess,
      "referral-url": window.location.href,
      referrer: "",
      title: data.jobTitle
    };
    postLead(payload)
      .then(() => {
        NotificationHub.send(
          "success",
          "We have received your Alternative Data request."
        );
        hideModal();
      })
      .catch(() => {
        NotificationHub.send(
          "danger",
          "Something went wrong - please try again"
        );
      });
  };
  return (
    <Modal
      size="lg"
      isOpen={true}
      toggle={hideModal}
      data-testid="AlternativeLeadForm"
    >
      <ModalHeader toggle={hideModal} />
      <div>
        <div className={styles.headerContainer} data-testid="alternative-data">
          <h4 className={styles.formHint}>For Institutional Clients Only</h4>
          <span className="with-top-blue-line" />
          <h3 className={styles.formHead}>Request Access</h3>
          <p className={styles.formHeadSub}>
            Contact us to learn more about our alternative data products.
          </p>
        </div>
        <form
          className={styles.formItemContainer}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className={styles.formItem}>
            <Controller
              name="name"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value, name } }) => (
                <FormField
                  id="name"
                  name={name}
                  value={value}
                  onChange={onChange}
                  label="NAME"
                  type="text"
                  placeholder="Type here..."
                  data-testid="signupForm_name"
                  invalid={!!errors.name}
                  feedback={errors.name?.message}
                />
              )}
            />
          </div>

          <div className={styles.formItem}>
            <Controller
              name="jobTitle"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value, name } }) => (
                <FormField
                  id="jobTitle"
                  name={name}
                  label="JOB TITLE"
                  type="text"
                  placeholder="Type here..."
                  value={value}
                  onChange={onChange}
                  data-testid="signupForm_jobTitle"
                  invalid={!!errors.jobTitle}
                  feedback={errors.jobTitle?.message}
                />
              )}
            />
          </div>
          <div className={styles.formItem}>
            <Controller
              name="organizationName"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value, name } }) => (
                <FormField
                  id="organizationName"
                  name={name}
                  label="COMPANY NAME"
                  type="text"
                  placeholder="Type here..."
                  value={value}
                  onChange={onChange}
                  data-testid="signupForm_organizationName"
                  invalid={!!errors.organizationName}
                  feedback={errors.organizationName?.message}
                />
              )}
            />
          </div>
          <div className={styles.formItem}>
            <Controller
              name="businessType"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value, name } }) => (
                <FormField
                  id="businessType"
                  name={name}
                  label="BUSINESS TYPE"
                  type="select"
                  placeholder="Select..."
                  value={value}
                  onChange={onChange}
                  data-testid="signupForm_businessType"
                  invalid={!!errors.businessType}
                  feedback={errors.businessType?.message}
                >
                  <option value="" disabled defaultValue="">
                    Select...
                  </option>
                  {businessType.map((item: any) => (
                    <option value={item.id} key={item.id}>
                      {item.title}
                    </option>
                  ))}
                </FormField>
              )}
            />
          </div>
          <div className={styles.formItem}>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value, name } }) => (
                <FormField
                  id="email"
                  name={name}
                  label="BUSINESS EMAIL"
                  type="text"
                  placeholder="Type here..."
                  value={value}
                  onChange={onChange}
                  data-testid="signupForm_email"
                  invalid={!!errors.email}
                  feedback={errors.email?.message}
                />
              )}
            />
          </div>
          <div className={styles.formItem}>
            <Controller
              name="phone"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value, name } }) => (
                <FormField
                  id="phone"
                  name={name}
                  label="BUSINESS PHONE"
                  type="text"
                  placeholder="Type here..."
                  invalid={!!errors.phone}
                  feedback={errors.phone?.message}
                  value={value}
                  onChange={onChange}
                  data-testid="signupForm_phone"
                />
              )}
            />
          </div>
          <div className={styles.formItem}>
            <AUMController control={control} errors={errors}>
              {assetsUnderManagement.map((item: any) => (
                <option value={item.id} key={item.id}>
                  {item.title}
                </option>
              ))}
            </AUMController>
          </div>

          <div className={styles.formItem}>
            <Controller
              name="reasonForAccess"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value, name } }) => (
                <FormField
                  id="reasonForAccess"
                  name={name}
                  label="REASON FOR ACCESS"
                  type="select"
                  placeholder="Select..."
                  value={value}
                  onChange={onChange}
                  data-testid="signupForm_reasonForAccess"
                  invalid={!!errors.reasonForAccess}
                  feedback={errors.reasonForAccess?.message}
                >
                  <option value="" disabled defaultValue="">
                    Select...
                  </option>
                  {REASON_OPTIONS.map(option => (
                    <option value={option} key={option}>
                      {option}
                    </option>
                  ))}
                </FormField>
              )}
            />
          </div>
          <div className={styles.formItem}>
            <Controller
              name="usagePlan"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value, name } }) => (
                <FormField
                  id="usagePlan"
                  name={name}
                  label="I PLAN TO USE ALTERNATIVE DATA IN"
                  type="select"
                  placeholder="Select..."
                  value={value}
                  onChange={onChange}
                  data-testid="signupForm_usagePlan"
                  invalid={!!errors.usagePlan}
                  feedback={errors.usagePlan?.message}
                >
                  <option value="" disabled defaultValue="">
                    Select...
                  </option>
                  {PLAN_OPTIONS.map(option => (
                    <option value={option} key={option}>
                      {option}
                    </option>
                  ))}
                </FormField>
              )}
            />
          </div>
          <div className={styles.formSubmit}>
            <Button
              block={true}
              type="submit"
              data-testid="signupForm_createAccount"
            >
              Submit
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default AlternativeLeadModal;
