import React, { useEffect, useState } from "react";

import { IProductsGridFields } from "../../../../../contentful/__generated__/types";
import "./style.scss";
import { getDataCollections } from "../../../../api/api";

type Props = {
  fields: IProductsGridFields;
};

type ProductProps = {
  datatableCollection: {
    code: string;
    name: string;
    description: string;
  };
};

type ProductCardProps = {
  entry: ProductProps;
  index: number;
};

const ProductCard = ({ entry, index }: ProductCardProps) => {
  const { code, name, description } = entry.datatableCollection;

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(observerEntry => {
        if (observerEntry.isIntersecting) {
          observerEntry.target.classList.add("products-grid__card__visible");
          observer.unobserve(observerEntry.target);
        }
      });
    });

    const elementToObserve = document.getElementById(`product-card-${index}`);
    if (elementToObserve) {
      observer.observe(elementToObserve);
    }
  }, [index]);

  return (
    <div
      id={`product-card-${index}`}
      key={index}
      className="data-card"
      style={{ animationDelay: `${(index + 1) * 0.4}s` }}
    >
      <div className="main">
        <a href={`/databases/${code}`} className="link__content">
          <h4 className="title">{name}</h4>
          <p className="description">{description}</p>
          <div className="tag">
            <div className="tag--premium">PREMIUM</div>
            <div className="tag--sample-data">HAS SAMPLE DATA</div>
          </div>
        </a>
      </div>
    </div>
  );
};

const Grid: React.FC<Props> = ({ fields }) => {
  const { ctaHeading, ctaBody, ctaLinkLabel, ctaLinkUrl, ctaBackgroundImage } =
    fields;
  const [entries, setEntries] = useState<any>([]);

  const background = {
    backgroundImage: `url(https://${ctaBackgroundImage?.fields.file.url})`,
    animationDelay: `${(entries.length + 1) * 0.4}s`
  };

  useEffect(() => {
    const observer = new IntersectionObserver(observerEntries => {
      observerEntries.forEach(observerEntry => {
        if (observerEntry.isIntersecting) {
          observerEntry.target.classList.add(
            "products-grid__image-card__visible"
          );
          observer.unobserve(observerEntry.target);
        }
      });
    });

    document.querySelectorAll(".products-grid__image-card").forEach(element => {
      observer.observe(element);
    });
  }, []);

  const fetchProductsData = async (fieldsObject: any) => {
    const productValues = Object.keys(fieldsObject)
      .filter(key => key.includes("product"))
      .map(key => fieldsObject[key]);

    const promises = productValues.map(async productCode => {
      const { data: dataCollections } = await getDataCollections(productCode);
      return dataCollections;
    });
    const allDataCollections = await Promise.all(promises);
    return allDataCollections;
  };

  useEffect(() => {
    fetchProductsData(fields).then(allDataCollections => {
      setEntries(allDataCollections);
    });
  }, [fields]);

  return (
    <section className="contentful__products-grid-wrapper">
      <div className="wrapper featured-datasets__wrapper">
        {!!entries &&
          entries.map((entry: any, index: number) => {
            // eslint-disable-next-line react/no-array-index-key
            return <ProductCard entry={entry} index={index} key={index} />;
          })}
        <a
          id="image-card-id"
          href={ctaLinkUrl}
          className="products-grid__image-card"
          style={background}
        >
          <div className="main">
            <h4 className="alt-data-cta__title">{ctaHeading}</h4>
            <p className="alt-data-cta__description">{ctaBody}</p>
            <a href={ctaLinkUrl} className="alt-data-cta__link">
              {ctaLinkLabel}
              <img
                className="arrow--right"
                src={`${process.env.PUBLIC_URL}/assets/images/arrows/right-long-arrow-white.svg`}
                alt="long-right-arrow"
              />
            </a>
          </div>
        </a>
      </div>
    </section>
  );
};

// eslint-disable-next-line import/prefer-default-export
export { Grid as ProductsGrid };
