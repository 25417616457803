import React, { useState } from "react";
import {
  Modal,
  Button,
  ModalFooter,
  ModalHeader,
  ModalBody,
  NotificationHub,
  FormField
} from "@nef/core";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useGlobalModalContext } from "../../../context/modal-context";
import { createOrganizationApiKey } from "../../../api/api";

type Props = {
  organizationId: string;
  callbackAfterCreated: () => void;
};

const apiKeySchema = yup.object({
  apiName: yup.string().required()
});

const NewApiKeyModal = ({ organizationId, callbackAfterCreated }: Props) => {
  const { hideModal } = useGlobalModalContext();
  const [isLoading, setIsLoading] = useState(false);
  const {
    formState: { errors },
    register,
    reset,
    handleSubmit
  } = useForm({
    resolver: yupResolver(apiKeySchema)
  });
  const createAPIKeyAndCloseModal: SubmitHandler<
    yup.Asserts<typeof apiKeySchema>
  > = data => {
    setIsLoading(true);

    createOrganizationApiKey(data.apiName, organizationId)
      .then(() => {
        NotificationHub.send("primary", "Your api key has been updated.");
        reset();
        hideModal();
        callbackAfterCreated?.();
      })
      .catch(() => {
        NotificationHub.send("danger", "Unable to update api key.");
      })
      .finally(() => setIsLoading(false));
  };

  const hideOnOutsideClick = () => {
    hideModal();
  };

  const resetFormAndCloseModal = () => {
    reset();
    hideModal();
  };

  return (
    <Modal size="md" isOpen={true} toggle={() => hideOnOutsideClick()}>
      <ModalHeader>New API Key</ModalHeader>
      <ModalBody>
        <FormField
          {...register("apiName")}
          label="Api Name"
          type="text"
          min={undefined}
          max={undefined}
          invalid={!!errors?.apiName}
          feedback={errors?.apiName?.message as string}
        />
      </ModalBody>
      <ModalFooter>
        <Button outline onClick={resetFormAndCloseModal}>
          Cancel
        </Button>
        <Button
          key="confirm"
          color="danger"
          onClick={handleSubmit(createAPIKeyAndCloseModal)}
          isLoading={isLoading}
        >
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default NewApiKeyModal;
