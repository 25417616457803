import { getPrivacySetting } from "../api/api";
import JSONAPIDeserializer from "../api/deserializer";
import { PrivacySetting } from "../api/types";
import { setCookie } from "../helpers/cookie-helpers";

const PrivacySettingsService = {
  defaultSettings: () => {
    return {
      performance: true,
      functionality: true,
      targeting: true,
      accepted: false
    };
  },
  updatePrivacyInAPI: async (setPrivacySettings: any, setAlert: any) => {
    try {
      const { data } = await getPrivacySetting();
      new JSONAPIDeserializer({ typeAsAttribute: true })
        .deserialize(data)
        .then((result: any) => {
          setPrivacySettings(result);
        });
    } catch (err: any) {
      if (err.errors && err.errors[0] && err.errors[0].status === "404") {
        // if there is no privacy record for this user, create one
        setAlert(`get privacy setting error: ${err}, try again later`);
      }
    }
  },
  updatePrivacySetting: (
    setting: PrivacySetting,
    setPrivacySettings: any,
    setAlert: any,
    setHasUpdatedPrivacy: any,
    isAuthenticated: boolean
  ) => {
    if (isAuthenticated) {
      PrivacySettingsService.updatePrivacyInAPI(setPrivacySettings, setAlert);
      setCookie(JSON.stringify(setting));
    } else {
      if (setting.accepted && setting.accepted === true) {
        setHasUpdatedPrivacy(true);
        PrivacySettingsService.updatePrivacyInAPI(setPrivacySettings, setAlert);
      }
      setCookie(JSON.stringify(setting));
    }
  },
  acceptPrivacy: (
    setPrivacySettings: any,
    setAlert: any,
    setHasUpdatedPrivacy: any,
    isAuthenticated: boolean
  ) => {
    PrivacySettingsService.updatePrivacySetting(
      {
        performance: true,
        functionality: true,
        targeting: true,
        accepted: true
      },
      setPrivacySettings,
      setAlert,
      setHasUpdatedPrivacy,
      isAuthenticated
    );
    setHasUpdatedPrivacy(true);
  }
};

export default PrivacySettingsService;
