import React, { createContext, useContext } from "react";
import { useCookies } from "react-cookie";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Integrations } from "@segment/analytics-next";

import { convertSettingsToIntegrations } from "../analytics/integrations";

interface SegmentIntegrationProviderProps {
  children: React.ReactNode;
}

const SegmentIntegrationContext = createContext<Integrations>({ All: true });

const useSegmentIntegrationContext = () => {
  const context = useContext(SegmentIntegrationContext);
  if (!context) {
    throw new Error("Context must be used within a SegmentIntegrationContext");
  }
  return context;
};

const SegmentIntegrationProvider: React.FC<SegmentIntegrationProviderProps> = ({
  children
}) => {
  const [cookie] = useCookies<string>(["privacy_setting"]);
  const integrations = convertSettingsToIntegrations(cookie?.privacy_setting);

  return (
    <SegmentIntegrationContext.Provider value={integrations}>
      {children}
    </SegmentIntegrationContext.Provider>
  );
};

export {
  SegmentIntegrationProvider,
  SegmentIntegrationContext,
  useSegmentIntegrationContext
};
