import React from "react";

import { IGraphicTextStatisticFields } from "../../../../../contentful/__generated__/types";
import "./style.scss";

type Props = {
  fields: IGraphicTextStatisticFields;
};

const THEMES = [
  {
    name: "Blue to Purple",
    class: "graphic-text-stat-background__blue_purple"
  },
  { name: "Red to Purple", class: "graphic-text-stat-background__red_purple" }
];

const Statistics: React.FC<Props> = ({ fields }) => {
  const {
    image: imageField,
    statisticCaption,
    statisticText,
    textBoxBody,
    textBoxHeadline,
    theme
  } = fields;

  const themeClass = THEMES.find(t => t.name === theme)?.class;
  const className = `graphic-text-stat-text-item ${themeClass}`;

  return (
    <section className="stat__graphic-wrapper">
      <div className="graphic-text-stat-fact-row graphic-text-stat-fact-row--split">
        <div className="graphic-text-stat">
          <div className="graphic-text-stat-overlapping-items">
            <img
              src={`https://${imageField?.fields.file.url}`}
              alt={imageField?.fields.title}
              className="graphic-text-stat-image-item"
            />
            <div className={className}>
              <h1 className="graphic-text-stat-header">{textBoxHeadline}</h1>
              <p className="graphic-text-stat-body">{textBoxBody}</p>
            </div>
          </div>
        </div>

        <div className="graphic-text-stat-data-wrapper">
          <h1 className="graphic-text-stat-hero-data">{statisticText}</h1>
          <p className="graphic-text-stat-data-sub-item">{statisticCaption}</p>
        </div>
      </div>
    </section>
  );
};

// eslint-disable-next-line import/prefer-default-export
export { Statistics as StatisticsGraphicText };
