import React from "react";

import { ILinkActionFields } from "../../../../../contentful/__generated__/types";
import {
  MODAL_TYPES,
  useGlobalModalContext
} from "../../../../context/modal-context";
import PrivacySettingModal from "../../../privacy-setting/privacy-setting-panel";

type Props = {
  fields: ILinkActionFields;
};

const Action: React.FC<Props> = ({ fields }) => {
  const { showModal } = useGlobalModalContext();
  const openPrivacySettingsModal = () => {
    showModal(MODAL_TYPES.PLAIN_MODAL, {
      children: <PrivacySettingModal />
    });
  };

  const openAction = (action: string) => {
    switch (action) {
      case "Open Privacy Settings Modal":
        return openPrivacySettingsModal;
      default:
        return () => {};
    }
  };

  return (
    <div onClick={openAction(fields.action)} className="footer-bottom-link">
      {fields.label}
    </div>
  );
};

// eslint-disable-next-line import/prefer-default-export
export { Action as ActionLink };
