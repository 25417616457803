import React from "react";
import "./style.scss";

type Props = {
  className?: string;
};

const Spinner: React.FC<Props> = ({ className = "" }) => {
  return (
    <div className={`lds-roller ${className}`}>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

export default Spinner;
