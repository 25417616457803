import React from "react";
import { HelmetProvider } from "react-helmet-async";

import Header from "../header";
import { BasicRichText } from "../rich-text/basic";
import { BackgroundRichText } from "../rich-text/background";
import { MediaRichText } from "../rich-text/media";
import FixedSidebox from "../rich-text/page-sidebox";
import {
  IHeader,
  ISideboxPageFields
} from "../../../../contentful/__generated__/types";
import Metadata from "../metadata";

import "./style.scss";

type Props = {
  entry: {
    fields: ISideboxPageFields;
  };
};

const Sidebox: React.FC<Props> = ({ entry }: { entry: any }) => {
  const { fields: headerFields } = entry.fields.header as IHeader;
  const { sidebox: sideboxFields } = entry.fields;
  const { fields: metadataFields } = entry.fields.metadata;

  const sectionComponents = entry.fields.sections.map((section: any) => {
    switch (section.sys.contentType.sys.id) {
      case "richTextMedia":
        return <MediaRichText key={section.sys.id} fields={section.fields} />;
      case "richTextBackground":
        return (
          <BackgroundRichText
            key={section.sys.id}
            fields={section.fields}
            sideBox
          />
        );
      case "richTextBasic":
        return <BasicRichText key={section.sys.id} fields={section.fields} />;
      default:
        return null;
    }
  });

  return (
    <HelmetProvider>
      <Metadata fields={metadataFields} />
      <main className="sidebox-main">
        <Header fields={headerFields} />
      </main>
      <section className="section-content">
        <div> {sectionComponents}</div>
        <FixedSidebox fields={sideboxFields} />
      </section>
    </HelmetProvider>
  );
};

// eslint-disable-next-line import/prefer-default-export
export { Sidebox as SideboxContentfulPage };
