import { trackEvent } from "../analytics";
import { useSegmentIntegrationContext } from "../../context/segment-context";

const useTrackEvent = () => {
  const integrations = useSegmentIntegrationContext();
  const trackEventFn = (event: string, properties = {}) =>
    trackEvent(event, properties, integrations);

  return trackEventFn;
};

export default useTrackEvent;
