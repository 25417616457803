import React, { createContext, useContext } from "react";

import useRecentURLs from "../hooks/useRecentURLs";

interface RecentURLsProviderProps {
  children: React.ReactNode;
}

const RecentURLsContext = createContext<[string, string] | null>(null);

const useRecentURLsContext = (): [string, string] => {
  const context = useContext(RecentURLsContext);
  if (!context) {
    throw new Error("useRecentURLs must be used within a RecentURLsProvider");
  }
  return context;
};

const RecentURLsProvider: React.FC<RecentURLsProviderProps> = ({
  children
}) => {
  const recentURLs = useRecentURLs();

  return (
    <RecentURLsContext.Provider value={recentURLs}>
      {children}
    </RecentURLsContext.Provider>
  );
};

export { RecentURLsProvider, RecentURLsContext, useRecentURLsContext };
