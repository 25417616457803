import React from "react";
import { Helmet } from "react-helmet-async";

import { IMetadataFields } from "../../../../contentful/__generated__/types";

type Props = {
  fields: IMetadataFields;
};

const Metadata: React.FC<Props> = ({ fields }) => {
  const excludeLinks = fields.excludeLinksFromSearchRankings
    ? "index"
    : "noindex";
  const hidePage = fields.hidePage ? "follow" : "nofollow";

  return (
    <Helmet>
      <title>{fields.title}</title>
      <link rel="canonical" href={window.location.href} />
      <meta name="description" content={fields.description} />
      <meta name="keywords" content={fields.keywords?.join(", ")} />

      <meta property="og:title" content={fields.title} />
      <meta property="og:description" content={fields.description} />
      <meta property="og:url" content={window.location.href} />
      <meta
        property="og:image"
        content={`https:${fields.image?.fields.file.url}`}
      />
      <meta property="og:type" content="article" />
      <meta property="og:site_name" content="Nasdaq Data Link" />

      <meta name="twitter:title" content={fields.title} />
      <meta name="twitter:description" content={fields.description} />
      <meta
        name="twitter:image"
        content={`https:${fields.image?.fields.file.url}`}
      />
      <meta name="twitter:site" content="@nasdaq" />
      <meta name="twitter:creator" content="@nasdaq" />

      <meta name="robots" content={`${excludeLinks}, ${hidePage}`} />
    </Helmet>
  );
};

export default Metadata;
