import axios, { AxiosResponse } from "axios";

import { API_ENDPOINT } from "../../appConfig";

interface ApiHeaders {
  Accept: string;
  "Referrer-Policy": string;
  "X-Requested-With": string;
  "Request-Source": string;
}

const userInfo = () => {
  return localStorage.getItem("user") !== null
    ? JSON.parse(localStorage.getItem("user") || "")
    : {};
};

const headers = () => {
  const defaultHeaders: ApiHeaders = {
    Accept: "*/*",
    "Referrer-Policy": "strict-origin-when-cross-origin",
    "X-Requested-With": "XMLHttpRequest",
    "Request-Source": "next"
  };

  return defaultHeaders;
};

const client = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    ...headers()
  }
});

client.interceptors.response.use((response: AxiosResponse) => {
  return response.data === undefined ? undefined : response;
});

export { client, userInfo };
