import React from "react";

import "./style.scss";
import { IButtonFields } from "../../../../../contentful/__generated__/types";

const THEMES = [
  { name: "Orange", class: "link_button__orange_white" },
  { name: "Blue", class: "link_button__blue_white" },
  { name: "White", class: "link_button__white" },
  { name: "Transparent", class: "link_button__transparent" },
  { name: "Yellow", class: "link_button__yellow" },
  { name: "White Border", class: "link_button__white-border" }
];

function Link({ theme, label, url }: IButtonFields) {
  const themeClass = THEMES.find(t => t.name === theme)?.class;
  const className = `link_button ${themeClass}`;

  return (
    <a href={url} className={className}>
      {label}
    </a>
  );
}

// eslint-disable-next-line import/prefer-default-export
export { Link as LinkButton };
