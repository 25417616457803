import React, { useEffect, useState, useMemo, useRef } from "react";
import { Link, NavLink, useHistory, useParams } from "react-router-dom";
import * as yup from "yup";
import { Button, EmptyState, FormTextArea, NotificationHub } from "@nef/core";
import moment from "moment";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import UserIcon from "../../../../components/modals/add-users-org-dataset/components/user-icon/UserIcon";
import {
  getOrganizationTeamUsers,
  removeUserFromOrganizationTeam,
  removeUserFromOrganization,
  inviteOrganizationUsers
} from "../../../../api/api";
import ConfirmationModal from "../../../../components/modals/ConfirmationModal";
import type {
  OrganizationTeamUser,
  OrganizationUser
} from "../../../../api/types";
import styles from "../style.module.scss";
import type { NormalizedOrganizationTeam } from "../index";

const formSchema = yup.object({
  emails: yup
    .array()
    .of(
      yup
        .string()
        .matches(
          // EMAIL_REGEX convert from wikiposit
          /^([\w+-].?)+@[\w\d-]+(\.[\w\d-]+)*\.[a-z]+$/
        )
        .required()
    )
    .min(1)
    .required()
    .transform(v => v.split(","))
});

export default function OrganizationMemberPage({
  organizationUsers,
  listData,
  slug,
  organizationId,
  requestOrganizationData
}: {
  organizationUsers: OrganizationUser[];
  slug: string;
  listData: NormalizedOrganizationTeam;
  organizationId: number;
  requestOrganizationData: (_: number) => void;
}) {
  const { id } = useParams<{ id?: string }>();
  const history = useHistory();

  const currentMember = useMemo(() => {
    if (id && organizationUsers && organizationUsers.length !== 0) {
      return organizationUsers.find(v => `${v.id}` === id) || null;
    }
    return null;
  }, [id, organizationUsers]);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const handleConfirm = () => {
    setIsModalOpen(false);
    if (currentMember) {
      removeUserFromOrganization(currentMember.id)
        .then(() => {
          NotificationHub.send("primary", "Delete user successfully!", {
            onClick: () => NotificationHub.dismiss(),
            position: "top-right",
            closeOnClick: true
          });
          requestOrganizationData(organizationId);
          history.push(`/organizations-db/${slug}/users`);
        })
        .catch(() => {
          NotificationHub.send(
            "danger",
            "Sorry, delete user failed, please try again",
            {
              onClick: () => NotificationHub.dismiss(),
              position: "top-right",
              closeOnClick: true
            }
          );
        });
    }
  };

  const [currentDeleteUserId, setCurrentDeleteUserId] = useState<string | null>(
    null
  );
  const handleRemoveConfirm = () => {
    if (currentDeleteUserId) {
      removeUserFromOrganizationTeam(currentDeleteUserId)
        .then(() => {
          NotificationHub.send("primary", "Delete data product successfully!", {
            onClick: () => NotificationHub.dismiss(),
            position: "top-right",
            closeOnClick: true
          });
          setCurrentDeleteUserId(null);
          requestOrganizationData(organizationId);
        })
        .catch(() => {
          NotificationHub.send(
            "danger",
            "Sorry, delete data product failed, please try again",
            {
              onClick: () => NotificationHub.dismiss(),
              position: "top-right",
              closeOnClick: true
            }
          );
        });
    }
  };

  const mapForTeamToOrgTeamUserRef = useRef<Record<string, string>>({});

  useEffect(() => {
    mapForTeamToOrgTeamUserRef.current = {};
    if (!currentMember || currentMember.organization_team_user_ids.length === 0)
      return;

    getOrganizationTeamUsers(currentMember.organization_team_user_ids).then(
      response =>
        response.data.organization_team_users.forEach(
          (teamUser: OrganizationTeamUser) => {
            const teamId = `${teamUser.organization_team_id}`;
            const teamUserId = `${teamUser.id}`;
            mapForTeamToOrgTeamUserRef.current[teamId] = teamUserId;
          }
        )
    );
  }, [currentMember]);

  const [isAddMemberModalOpen, setIsAddMemberModalOpen] = useState(false);

  const {
    formState: { errors },
    register,
    handleSubmit,
    setError,
    reset
  } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      emails: []
    }
  });
  const handleAddMemberConfirm: SubmitHandler<
    yup.Asserts<typeof formSchema>
  > = data => {
    inviteOrganizationUsers(organizationId, data.emails)
      .then(() => {
        reset();
        setIsAddMemberModalOpen(false);
        requestOrganizationData(organizationId);
        NotificationHub.send(
          "primary",
          "Invite organization user(s) successfully!",
          {
            onClick: () => NotificationHub.dismiss(),
            position: "top-right",
            closeOnClick: true
          }
        );
      })
      .catch(error => {
        setError("root", { type: "custom", message: error.message });
      });
  };

  return (
    <div className={styles.memberPage}>
      <div className={styles.memberList}>
        <div className={styles.memberButtonContainer}>
          <Button
            block
            onClick={() => {
              setIsAddMemberModalOpen(true);
            }}
          >
            + Invite Members
          </Button>
          <ConfirmationModal
            isOpen={isAddMemberModalOpen}
            onConfirm={handleSubmit(handleAddMemberConfirm)}
            onDismiss={() => {
              setIsAddMemberModalOpen(false);
            }}
            title="Invite your colleagues to use Nasdaq Data Link"
            question={
              <>
                <p className={styles.inviteDesc}>
                  Enter the email address of the users you would like to add to
                  your organization below. Invite multiple users by separating
                  emails with a comma.
                </p>

                <FormTextArea
                  {...register("emails")}
                  min={undefined}
                  max={undefined}
                  rows={3}
                  data-testid="emails"
                  invalid={!!errors?.emails}
                  feedback={errors?.emails && "Invalid email"}
                  placeholder="email@address.com, otheremail@sample.com, sample@email.com"
                  className={styles.inviteInput}
                />
              </>
            }
            confirmText="SEND INVITE(S)"
            dismissText="Cancel"
          />
        </div>
        {organizationUsers
          .filter(user => !user.api_client)
          .map(user => (
            <NavLink
              key={user.id}
              className={styles.memberItem}
              activeClassName={styles.active}
              to={`/organizations-db/${slug}/users/${user.id}`}
            >
              {user.full_name && <UserIcon fullName={String(user.full_name)} />}
              <div>
                <p className={styles.memberItemName}>
                  <span>{user.full_name || "N/A"}</span>
                  {!user.invitation_accepted && (
                    <>
                      <span className={styles.pending}>PENDING</span>
                      {moment().diff(moment(user.created_at), "days") > 2 && (
                        <span className={styles.pendingDay}>
                          {`- It's been ${moment().diff(
                            moment(user.created_at),
                            "days"
                          )} days. Follow up?`}
                        </span>
                      )}
                    </>
                  )}
                </p>

                <p className={styles.memberItemDesc}>{user.email}</p>
              </div>
            </NavLink>
          ))}
      </div>
      {currentMember && (
        <div className={styles.memberContent}>
          <div className={styles.memberInfoContainer}>
            <div>
              <h2 className={styles.memberName}>
                {currentMember.full_name || "N/A"}
              </h2>
              <p>{currentMember.email}</p>
            </div>
            <Button
              outline
              color=""
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              Remove from organization
            </Button>
            <ConfirmationModal
              isOpen={isModalOpen}
              onConfirm={handleConfirm}
              onDismiss={() => {
                setIsModalOpen(false);
              }}
              title="REMOVE USER FROM ORGAN"
              question="Are you sure you want to remove this user from your organization?"
              confirmText="YES, REMOVE"
              dismissText="NO, CANCEL"
            />
          </div>
          {currentMember.organization_team_ids.length !== 0 ? (
            <div className={styles.listWrapper}>
              <h2 className="with-top-blue-line">Product With Access</h2>
              {currentMember.organization_team_ids.map((teamId: number) => {
                if (!listData.items[`${teamId}`]) {
                  return null;
                }
                return (
                  <div key={teamId} className={styles.listRow}>
                    <p>{listData.items[`${teamId}`].name}</p>
                    <Button
                      size="sm"
                      color="link"
                      onClick={() => {
                        setCurrentDeleteUserId(
                          mapForTeamToOrgTeamUserRef.current[teamId] || null
                        );
                      }}
                    >
                      REMOVE
                    </Button>
                  </div>
                );
              })}
              <ConfirmationModal
                isOpen={!!currentDeleteUserId}
                onConfirm={handleRemoveConfirm}
                onDismiss={() => {
                  setCurrentDeleteUserId(null);
                }}
                title="REMOVE DATA PRODUCT FROM USER"
                question="Are you sure you want to remove this data product from this user?"
                confirmText="YES, REMOVE"
                dismissText="NO, CANCEL"
              />
            </div>
          ) : (
            <EmptyState
              icon="sloth"
              title="No access to any data is given to this user"
            >
              <p className={styles.emptyStateTitle}>
                No access to any data is given to this user
              </p>
              <p>
                You can provide a user access to data on{" "}
                <Link to={`/organizations-db/${slug}/datasets`}>Datasets</Link>{" "}
                tab on the left
              </p>
            </EmptyState>
          )}
        </div>
      )}
    </div>
  );
}
