import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import { createClient } from "contentful";

import ServerError from "../components/contentful-page/server-error";

const client = createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE_ID as string,
  accessToken: process.env.REACT_APP_CONTENTFUL_DELIVERY_TOKEN as string
});

const fetchContentfulNavigationEntries = async () => {
  try {
    const response = await axios.get(
      `https://${process.env.REACT_APP_CONTENTFUL_HOSTNAME}/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE_ID}/entries`,
      {
        params: {
          access_token: process.env.REACT_APP_CONTENTFUL_DELIVERY_TOKEN,
          "sys.contentType.sys.id[in]": "topNavigation,footer",
          "sys.id[in]": `${process.env.REACT_APP_CONTENTFUL_HEADER_NAVIGATION_ENTRY_ID},${process.env.REACT_APP_CONTENTFUL_FOOTER_NAVIGATION_ENTRY_ID}`,
          include: 10
        }
      }
    );

    const parsedData = await client.parseEntries(response.data);
    return parsedData.items;
  } catch (error) {
    return <ServerError />;
  }
};

const ContentfulContext = createContext<any[]>([]);

type AppProps = {
  children?: React.ReactNode;
};

const ContentfulProvider: React.FC<AppProps> = ({ children }) => {
  const [contentfulNavigationEntries, setContentfulNavigationEntries] =
    useState<any>([]);

  useEffect(() => {
    let isMounted = true;

    async function fetchData() {
      const entry = await fetchContentfulNavigationEntries();
      if (isMounted) {
        setContentfulNavigationEntries(entry);
      }
    }
    fetchData();

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <ContentfulContext.Provider value={contentfulNavigationEntries}>
      {children}
    </ContentfulContext.Provider>
  );
};

export { ContentfulContext, ContentfulProvider };
