import React, { createContext, useContext } from "react";

import useUser, { UseUserProps } from "../hooks/useUser";

interface UserProviderProps {
  children: React.ReactNode;
}

const UserContext = createContext<UseUserProps | null>(null);

const useUserContext = (): UseUserProps => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must used within a UserProvider");
  }
  return context;
};

const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const userState = useUser();

  return (
    <UserContext.Provider value={userState}>{children}</UserContext.Provider>
  );
};

export { UserProvider, UserContext, useUserContext };
