import { AppAction } from "./types";

import { Types, ActionMap } from "./index";

type planPayload = {
  [Types.CreatePlans]: {
    plans: any;
  };
};

export type PlanActions = ActionMap<planPayload>[keyof ActionMap<planPayload>];

export const planReducer = (state: any, action: AppAction) => {
  switch (action.type) {
    case Types.CreatePlans:
      return { ...state, plan: action.payload.plans };
    default:
      return state;
  }
};
