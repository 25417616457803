import { useState, useEffect, useCallback, useRef } from "react";
import jQuery from "jquery";

interface marketoProps {
  baseUrl: string;
  munchkinId: string;
  formId: string;
  callback?: (_: any) => void;
}

function appendScript(baseUrl: any, setScriptLoaded: any) {
  const script = document.createElement("script");
  script.src = `${baseUrl}/js/forms2/js/forms2.min.js`;
  script.onload = () => (window.MktoForms2 ? setScriptLoaded(true) : null);
  document.body.appendChild(script);
  return script;
}

function useMarketo({ baseUrl, munchkinId, formId, callback }: marketoProps) {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [formLoaded, setFormLoaded] = useState(false);
  const internalCallback = useCallback(
    form => {
      setFormLoaded(true);
      callback?.(form);
    },
    [callback]
  );

  const outsideLoaded = useRef(false);

  useEffect(() => {
    let script: HTMLScriptElement;
    if (window.MktoForms2) {
      // its already loaded outside
      outsideLoaded.current = true;
      setScriptLoaded(true);
    } else {
      script = appendScript(baseUrl, setScriptLoaded);
    }

    return () => {
      if (!outsideLoaded.current && script) {
        document.body.removeChild(script);
      }
    };
  }, [baseUrl]);

  useEffect(() => {
    if (scriptLoaded && !formLoaded) {
      window.MktoForms2.loadForm(baseUrl, munchkinId, formId, internalCallback);
      return undefined;
    }

    return () => {
      jQuery(`#mktoForm_${formId}`).empty();
      setFormLoaded(false);
    };
  }, [scriptLoaded, baseUrl, munchkinId, formId, internalCallback, formLoaded]);
}

export default useMarketo;
