import React from "react";
import { Modal } from "@nef/core";

import Spinner from "../spinner";

import styles from "./style.module.scss";

export const SubscribingToPlanModal = () => {
  return (
    <Modal size="md" isOpen={true}>
      <section className={styles.container}>
        <Spinner className="subscribing" />
        <p className={styles.wait}>Please wait</p>
        <p className={styles.hint}>We’re processing your subscription</p>
      </section>
    </Modal>
  );
};

export default SubscribingToPlanModal;
