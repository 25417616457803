import React from "react";
import { Modal, ModalHeader, ModalFooter, ModalBody, Button } from "@nef/core";

const DEFAULT_MODAL_OPTIONS = {
  title: "Confirmation",
  question: "Are you sure?",
  dismissText: "No",
  confirmText: "Yes"
};

interface ConfirmationModalProps {
  isOpen: boolean;
  title?: string;
  question?: string | React.ReactNode;
  confirmText?: string;
  dismissText?: string;
  onConfirm: () => void;
  onDismiss?: () => void;
}

const ConfirmationModal = ({
  isOpen,
  onConfirm,
  onDismiss,
  title = DEFAULT_MODAL_OPTIONS.title,
  question = DEFAULT_MODAL_OPTIONS.question,
  confirmText = DEFAULT_MODAL_OPTIONS.confirmText,
  dismissText = DEFAULT_MODAL_OPTIONS.dismissText
}: ConfirmationModalProps) => {
  return (
    <>
      <Modal isOpen={isOpen} toggle={onDismiss} className="modal-wrapper">
        <ModalHeader toggle={onDismiss} className="modal-header">
          {title}
        </ModalHeader>
        <ModalBody className="modal-body">{question}</ModalBody>
        <ModalFooter className="modal-footer">
          {onDismiss !== undefined && (
            <Button
              size="md"
              className="danger"
              color="light"
              onClick={onDismiss}
            >
              {dismissText}
            </Button>
          )}
          <Button size="md" onClick={onConfirm}>
            {confirmText}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ConfirmationModal;
