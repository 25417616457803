import React from "react";
import { Modal, Button } from "@nef/core";

import { useGlobalModalContext } from "../../context/modal-context";

const CreateModal = () => {
  const { hideModal, store } = useGlobalModalContext();
  const { modalProps } = store || {};
  const { title, confirmBtn } = modalProps || {};

  const handleModalToggle = () => {
    hideModal();
  };

  return (
    <Modal
      title={title || "Create Modal"}
      size="md"
      isOpen={true}
      onBlur={handleModalToggle}
    >
      <Button key="confirm" onClick={handleModalToggle}>
        {confirmBtn || "Confirm button"}
      </Button>
      <Button key="cancel" onClick={handleModalToggle}>
        Cancel
      </Button>
    </Modal>
  );
};

export default CreateModal;
