import React from "react";

import { ICtaLinkFields } from "../../../../../contentful/__generated__/types";
import { useTrackSignUpClick } from "../../../../analytics/hooks/useAnalyticsEvents";
import "./style.scss";

type Props = {
  fields: ICtaLinkFields;
};

const THEMES = [
  { name: "Blue to Purple", class: "cta__link-background__blue_purple" },
  { name: "Green to Blue", class: "cta__link-background__green_blue" }
];

const Link: React.FC<Props> = ({ fields }) => {
  const {
    theme,
    heading1,
    body,
    linkLabel,
    linkUrl,
    linkOpenInNewWindow,
    label
  } = fields;

  const trackSignUpClick = useTrackSignUpClick();

  const themeClass = THEMES.find(t => t.name === theme)?.class;
  const className = `cta-link-api-backround ${themeClass}`;

  return (
    <div className={className}>
      <h4 className="cta-link-create-header">{label}</h4>
      <h2 className="cta-link-sign-up-title">{heading1}</h2>
      <p className="cta-link-footer-text">{body}</p>
      <a
        href={linkUrl}
        target={linkOpenInNewWindow ? "_blank" : "_self"}
        rel="noreferrer"
        onClick={() => trackSignUpClick()}
      >
        <p className="cta-link-sign-up">
          {linkLabel}
          <img
            className="button__arrow--right"
            src={`${process.env.PUBLIC_URL}/assets/images/arrows/right-long-arrow-white.svg`}
            alt="long-right-arrow"
          />
        </p>
      </a>
    </div>
  );
};

// eslint-disable-next-line import/prefer-default-export
export { Link as CTALink };
