import React, { createContext, Dispatch, useEffect, useReducer } from "react";

import {
  planReducer,
  productReducer,
  planCategoriesReducer,
  organizationReducer,
  myOrganizationReducer,
  internalCatalogueTokenReducer,
  Types
} from "../reducer";
import { InternalCatalogueTokenRecord, AppAction } from "../reducer/types";
import useAuth from "../hooks/useAuth";

type ProductType = {
  id: number;
  code: string;
  productType: string;
  name: string;
  premium: boolean;
  description: string;
  datasets: any;
  datatables: any;
};

type MyOrganization = {
  organizationName: string;
};

export type InitialStateType = {
  products: ProductType[];
  plan: any;
  planCategories: any;
  myOrganizations: any;
  organizationDetails: any;
  organizationName: MyOrganization;
  organizationId: number | null;
  internalCatalogueTokenCache: InternalCatalogueTokenRecord[];
};

export const initialState = {
  products: [],
  plan: [],
  planCategories: [],
  myOrganizations: [],
  organizationDetails: [],
  organizationName: {
    organizationName: ""
  },
  organizationId: null,
  internalCatalogueTokenCache: []
};

const mainReducer = (
  {
    products,
    plan,
    planCategories,
    myOrganizations,
    organizationDetails,
    organizationName,
    organizationId,
    internalCatalogueTokenCache
  }: InitialStateType,
  action: AppAction
) => ({
  products: productReducer(products, action),
  plan: planReducer(plan, action),
  planCategories: planCategoriesReducer(planCategories, action),
  myOrganizations: organizationReducer(myOrganizations, action),
  organizationDetails: organizationReducer(organizationDetails, action),
  organizationName: myOrganizationReducer(organizationName, action),
  organizationId: myOrganizationReducer(organizationId, action),
  internalCatalogueTokenCache: internalCatalogueTokenReducer(
    internalCatalogueTokenCache,
    action
  )
});

const AppContext = createContext<{
  state: InitialStateType;
  dispatch: Dispatch<AppAction>;
}>({
  state: initialState,
  dispatch: () => null
});

type AppProps = {
  children?: React.ReactNode;
};

const AppProvider: React.FC<AppProps> = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, initialState);
  const { authenticatedUser } = useAuth();

  useEffect(() => {
    dispatch({
      type: Types.ClearInternalCatalogueTokens,
      payload: {}
    });
  }, [authenticatedUser?.id]);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
