import React, { useMemo } from "react";
import "./style.scss";

const COLOURS = ["teal", "blue", "red", "green", "orange"];

type Props = {
  fullName: string;
};

const UserIcon = ({ fullName }: Props) => {
  const iconColour = useMemo(() => {
    const nameNumber =
      [`${fullName}`].reduce((sum, char) => sum + +char.charCodeAt(0), 0) % 5;
    return COLOURS[nameNumber];
  }, [fullName]);

  const initials = (fullname: string): string => {
    const namesArray = fullname.split(" ");
    if (namesArray.length > 2) namesArray.splice(1, namesArray.length - 2);
    const n: string = namesArray.map(name => name[0]).join("");
    return n;
  };

  return (
    <div
      className={`user-icon user-icon__icon user-icon__icon--${iconColour}`}
      data-test-user-icon="icon"
    >
      <div className="user-icon__initials" data-test-user-icon="initial">
        {initials(fullName)}
      </div>
    </div>
  );
};

export default UserIcon;
