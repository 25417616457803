import React, { useCallback, useMemo } from "react";
import jQuery from "jquery";

import useAuth from "../../hooks/useAuth";
// eslint-disable-next-line import/extensions
import "../../vendor/marketo/forms2.min.js";
import { useGlobalModalContext } from "../../context/modal-context";
import useMarketo from "../../hooks/useMarketo";
import { useUTMValue } from "../../context/utm-context";
import { identifyUser, anonymousSegmentId } from "../../analytics/analytics";
import LocalStorageService from "../../services/localStorage";
import useTrackEvent from "../../analytics/hooks/useTrackEvent";

import "./style.scss";

declare global {
  interface Window {
    MktoForms2: any;
  }
}

const MarketoForm = ({ formId }: { formId: string }) => {
  const { hideModal } = useGlobalModalContext();
  const { authenticatedUser } = useAuth();
  const utmCookie = useUTMValue();
  const trackEvent = useTrackEvent();

  const webPageURL = useMemo(() => {
    const newReferrer = new URL(window.location.href);
    const params = newReferrer.searchParams;
    Object.entries(utmCookie).forEach(([key, value]) => {
      if (!params.has(key)) {
        params.append(key, value);
      }
    });
    return newReferrer.toString();
  }, [utmCookie]);

  const loadCallback = useCallback(
    form => {
      const fName = authenticatedUser?.first_name;
      const lName = authenticatedUser?.last_name;
      const email = authenticatedUser?.email;
      const regStatus = authenticatedUser ? "Registered" : "Not Registered";
      const userId = authenticatedUser?.id;
      const busType = authenticatedUser?.business_type_short_form;
      form.vals({
        webPageURL,
        ...(fName && fName.length > 0 && { FirstName: fName }),
        ...(lName && lName.length > 0 && { LastName: lName }),
        ...(email && { Email: email }),
        ...(userId && { NasdaqDataLink_ID__c: userId }),
        ...(busType && { NDL_Firm_Type__c: busType }),
        nDLRegistrationStatus: regStatus
      });
      // Add an onSuccess handler
      form.onSuccess(function handler() {
        const properties = {
          path: window.location.href,
          title: document.title,
          product: window.location.pathname.split("/")?.[2] || undefined,
          phone: jQuery("input.mktoTelField").val()
        };

        trackEvent("Contacted Sales", properties);

        const user = LocalStorageService.getUser();
        identifyUser(user?.id, {
          username: user?.username,
          firstName: user?.first_name,
          lastName: user?.last_name,
          email: user?.email,
          anonymous_id: anonymousSegmentId()
        });

        // Analytics option logic missing here as there should be a refactor to marketo form
        hideModal();
        return false;
      });

      if (form.getValues().Email) {
        jQuery("input.mktoField#Email").prop("disabled", true);
      }

      jQuery("input.mktoField").each(function handler() {
        checkForInput(this);
      });

      jQuery("input.mktoField").on(
        "change keyup focusout focus",
        function handler() {
          checkForInput(this);
        }
      );
      jQuery("input.mktoField").focus(function handler() {
        jQuery(this).siblings("label").addClass("input-has-value");
      });
      jQuery("textarea.mktoField").on(
        "change keyup focusout focus",
        function handler() {
          checkForInput(this);
        }
      );
      jQuery("textarea").focus(function handler() {
        jQuery(this).siblings("label").addClass("input-has-value");
      });
      // remove values for select fields
      jQuery("select.mktoField").each(function handler() {
        jQuery(this).siblings("label").addClass("input-has-value");
      });
      jQuery(".mktoForm").on("focus", ".mktoField", function handler() {
        jQuery(this).siblings("label").addClass("input-has-value");
      });

      jQuery("form").change(function formChangeHandler() {
        jQuery("select.mktoField").each(function handler() {
          jQuery(this).siblings("label").addClass("input-has-value");
        });
        jQuery("input.mktoField").each(function handler() {
          checkForInput(this);
        });
        // The lines below (inside) are executed on change & keyup
        jQuery("input.mktoField").on(
          "change keyup focusout focus",
          function handler() {
            checkForInput(this);
          }
        );
        jQuery("textarea.mktoField").on(
          "change keyup focusout focus",
          function handler() {
            checkForInput(this);
          }
        );
        jQuery("textarea").each(function handler() {
          checkForInput(this);
        });

        jQuery("input.mktoField").focus(function handler() {
          jQuery(this).siblings("label").addClass("input-has-value");
        });
      });

      function checkForInput(element: any) {
        const $label = jQuery(element).siblings("label");
        if (jQuery(element).val()) {
          $label.addClass("input-has-value");
        } else {
          $label.removeClass("input-has-value");
        }
      }
    },
    [authenticatedUser]
  );

  useMarketo({
    baseUrl: "//nd.nasdaq.com",
    munchkinId: "303-QKM-463",
    formId,
    callback: loadCallback
  });

  return <form id={`mktoForm_${formId}`} />;
};

export default MarketoForm;
