import React from "react";
import { MemoryRouter, Router as ReactRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import NEFThemeProvider from "@nef/style-utils";
import { CookiesProvider } from "react-cookie";
import { NotificationHub } from "@nef/core";
import { createBrowserHistory } from "history";
import * as Sentry from "@sentry/react";

import { GlobalModal } from "../context/modal-context";
import { BookmarkProvider } from "../context/bookmark-context";
import { UserProvider } from "../context/user-context";
import { UTMProvider } from "../context/utm-context";
import { SENTRY_ENABLED, SENTRY_DSN } from "../appConfig";
import { SegmentIntegrationProvider } from "../context/segment-context";
import { AnnouncementsProvider } from "../context/announcements-context";

import PageMetadata from "./page-metadata";
import { AuthProvider } from "./authentication";

interface ApplicationProviderInterface {
  children: JSX.Element;
  isTest?: boolean;
  initialEntries?: string[];
}

const history = createBrowserHistory();
Sentry.init({
  dsn: SENTRY_DSN,
  enabled: SENTRY_ENABLED,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
    }),
    new Sentry.Replay()
  ],

  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const Router = ({
  children,
  isTest,
  initialEntries
}: ApplicationProviderInterface) => {
  return (
    <>
      {isTest ? (
        <MemoryRouter initialEntries={initialEntries}>{children}</MemoryRouter>
      ) : (
        <ReactRouter history={history}>{children}</ReactRouter>
      )}
    </>
  );
};

const ApplicationProvider = ({
  children,
  isTest = false,
  initialEntries
}: ApplicationProviderInterface) => {
  return (
    <>
      <NEFThemeProvider mode="lightWithDarkSidebar">
        <Router isTest={isTest} initialEntries={initialEntries}>
          <HelmetProvider>
            <PageMetadata metadataKey="application" />
            <CookiesProvider>
              <SegmentIntegrationProvider>
                <AuthProvider>
                  <AnnouncementsProvider>
                    <UTMProvider>
                      <GlobalModal>
                        <BookmarkProvider>
                          <UserProvider>{children}</UserProvider>
                          <NotificationHub />
                        </BookmarkProvider>
                      </GlobalModal>
                    </UTMProvider>
                  </AnnouncementsProvider>
                </AuthProvider>
              </SegmentIntegrationProvider>
            </CookiesProvider>
          </HelmetProvider>
        </Router>
      </NEFThemeProvider>
    </>
  );
};

export default ApplicationProvider;
