import React from "react";
import { useLocation } from "react-router-dom";

import { ICategoryNavigationBasicFields } from "../../../../../../contentful/__generated__/types";
import "./style.scss";

type Props = {
  fields: ICategoryNavigationBasicFields;
};

const Basic: React.FC<Props> = ({ fields }) => {
  const location = useLocation();
  const slug = location.pathname;

  const { navigationLink } = fields;

  return (
    <div className="sub-nav sub-nav__menu--sticky">
      <div className="sub-nav__crumb" />
      <nav>
        <ul>
          {!!navigationLink &&
            navigationLink.map(nav => {
              return (
                <li key={nav.sys.id}>
                  <a
                    href={nav.fields.url}
                    className={
                      slug === nav.fields.url
                        ? "sub-nav__item active"
                        : "sub-nav__item"
                    }
                  >
                    {nav.fields.label}
                  </a>
                </li>
              );
            })}
        </ul>
      </nav>
    </div>
  );
};

// eslint-disable-next-line import/prefer-default-export
export { Basic as BasicCategoryNavigation };
