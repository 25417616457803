import React from "react";
import "./style.scss";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, Node } from "@contentful/rich-text-types";

import { LinkButton } from "../../button/link";
import { IRichTextBackgroundFields } from "../../../../../contentful/__generated__/types";
import { ActionButton } from "../../button/action";

const RICHTEXT_OPTIONS = {
  renderNode: {
    [BLOCKS.EMBEDDED_ENTRY]: ({ data }: Node) => {
      const { theme, label, url } = data.target.fields;
      const { action } = data.target.fields;

      return action ? (
        <ActionButton fields={data.target.fields} />
      ) : (
        <LinkButton theme={theme} label={label} url={url} />
      );
    },
    [BLOCKS.EMBEDDED_ASSET]: ({ data }: Node) => {
      const {
        file,
        description,
        details: { image }
      } = data.target.fields;

      return (
        <a href={description}>
          <img
            className="rich-text-image"
            src={`https://${file.url}`}
            height={image.height}
            width={image.width}
            alt={description}
          />
        </a>
      );
    }
  }
};

type Props = {
  fields: IRichTextBackgroundFields;
  sideBox?: boolean;
};

const Background: React.FC<Props> = ({ fields, sideBox = false }) => {
  const { body, backgroundImage } = fields;

  const background = {
    backgroundImage: `url(https://${backgroundImage?.fields.file.url})`
  };

  return sideBox ? (
    <div className="background-rich-text-info-wrapper">
      <div style={background} className="info-content">
        {!!body && documentToReactComponents(body, RICHTEXT_OPTIONS)}
      </div>
    </div>
  ) : (
    <div style={background} className="background-rich-text-fabric-wrapper">
      <div className="fabric-content">
        {!!body && documentToReactComponents(body, RICHTEXT_OPTIONS)}
      </div>
    </div>
  );
};

// eslint-disable-next-line import/prefer-default-export
export { Background as BackgroundRichText };
