import React from "react";
import { Modal } from "@nef/core";

import { useGlobalModalContext } from "../../context/modal-context";

const ContactModal = () => {
  const { store, hideModal } = useGlobalModalContext();
  const { modalProps } = store || {};
  const { title, formElement } = modalProps || {};

  const hideOnOutsideClick = () => {
    hideModal();
  };

  return (
    <Modal
      title={title || "Create Modal"}
      size="sm"
      isOpen={true}
      toggle={() => hideOnOutsideClick()}
    >
      <div style={{ margin: "40px 0", overflow: "auto" }}>{formElement}</div>
    </Modal>
  );
};

export default ContactModal;
