import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "@nef/core";
import { useHistory } from "react-router-dom";

import RegisterSuccess from "../register-success";
import useAuth from "../../hooks/useAuth";
import { getMyOrganizations } from "../../api/api";
import {
  MODAL_TYPES,
  useGlobalModalContext
} from "../../context/modal-context";
import AcceptWelcome from "../modals/accept-welcome";
import AcceptTouModal from "../accept-tou-modal";

const RegistrationSuccessModal = () => {
  const [organizations, setOrganizations] = useState<any[]>([]);
  const history = useHistory();
  const { authenticatedUser } = useAuth();
  const { showModal, hideModal } = useGlobalModalContext();
  const modalRedirectFn = () => {
    dismissContent();
    history.push("/account/profile");
  };

  const openAcceptWelcome = (name: string, orgName: string, orgId: number) => {
    showModal(MODAL_TYPES.ACCEPT_WELCOME, {
      title: "Accept welcome",
      children: <AcceptWelcome name={name} orgName={orgName} orgId={orgId} />
    });
  };

  const openAcceptTou = (name: string, orgName: string, orgId: number) => {
    showModal(MODAL_TYPES.ACCEPT_TOU_MODAL, {
      title: "Accept terms of use",
      children: <AcceptTouModal name={name} orgName={orgName} orgId={orgId} />
    });
  };

  const dismissContent = () => {
    hideModal();

    const orgsPendingAcceptance = organizations.filter(
      org => org.needToAcceptTerms
    );
    const orgsPendingWelcome = organizations.filter(
      org => !org.welcomeAcceptedByMe && !org.hasTerms
    );

    if (orgsPendingAcceptance.length > 0) {
      openAcceptTou(
        authenticatedUser?.first_name as string,
        orgsPendingAcceptance[0]?.name as string,
        orgsPendingAcceptance[0]?.id as number
      );
    } else if (orgsPendingWelcome.length > 0) {
      openAcceptWelcome(
        authenticatedUser?.first_name as string,
        orgsPendingWelcome[0]?.name as string,
        orgsPendingWelcome[0]?.id as number
      );
    }
  };

  useEffect(() => {
    getMyOrganizations().then((response: any) => {
      setOrganizations(response.data.organizations);
    });
  }, []);

  return (
    <Modal isOpen={true}>
      <ModalHeader toggle={dismissContent} />
      <ModalBody>
        <RegisterSuccess
          apiKey={authenticatedUser?.api_key}
          redirectFn={modalRedirectFn}
        />
      </ModalBody>
      <ModalFooter>
        <Button size="md" onClick={dismissContent}>
          Continue
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default RegistrationSuccessModal;
