import { AppAction } from "./types";

import { Types, ActionMap } from "./index";

type planCategoriesPayload = {
  [Types.CreatePlanCategories]: {
    planCategories: any;
  };
};

export type PlanCategoriesActions =
  ActionMap<planCategoriesPayload>[keyof ActionMap<planCategoriesPayload>];

export const planCategoriesReducer = (state: any, action: AppAction) => {
  switch (action.type) {
    case Types.CreatePlanCategories:
      return { ...state, planCategories: action.payload.planCategories };
    default:
      return state;
  }
};
