import React from "react";
import { Modal, ModalBody, ModalHeader } from "@nef/core";

import { useGlobalModalContext } from "../../context/modal-context";
import "./style.scss";

type Props = {
  children: any;
};

const PlainModal = ({ children }: Props) => {
  const { hideModal, store } = useGlobalModalContext();
  const { modalProps } = store || {};
  const { title } = modalProps || {};

  return (
    <Modal title={title || ""} size="md" isOpen={true} className="plain-modal">
      <ModalHeader toggle={hideModal} />
      <ModalBody>{children}</ModalBody>
    </Modal>
  );
};

export default PlainModal;
