import { Button, FormField, FormRadioCheckboxButton } from "@nef/core";
import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  SubmitHandler,
  useForm,
  Controller,
  Control,
  FieldErrors,
  useWatch
} from "react-hook-form";
import * as yup from "yup";

import { getCountries, getEnchiridionData } from "../../api/api";
import { Country } from "../../types";
import { identifyUser } from "../../analytics/analytics";
import LocalStorageService from "../../services/localStorage";
import useGetAnonymousSegmentId from "../../analytics/useGetAnonymousSegmentId";
import useTrackEvent from "../../analytics/hooks/useTrackEvent";

import styles from "./style.module.scss";

const BUY_SIDE_INSTITUTION_OPTIONS = "8";
const USA_VALUE = "United States";

type EnchiridionType = {
  title: string;
  "sort-order": number;
  "short-form": string;
  id?: string;
  type?: string;
};

const formSchema = yup
  .object({
    firstName: yup
      .string()
      .max(50, "first name is too long")
      .required("Enter first name"),
    lastName: yup
      .string()
      .max(50, "last name is too long")
      .required("Enter last name"),
    organizationName: yup.string().required("Enter company name"),
    country: yup.string().required("Select country"),
    email: yup
      .string()
      .required("Enter email")
      .min(3, "must be at least 3 characters long")
      .matches(
        // EMAIL_REGEX convert from wikiposit
        /^([\w+-].?)+@[\w\d-]+(\.[\w\d-]+)*\.[a-z]+$/,
        "Invalid email"
      ),
    phone: yup.string().required("Enter phone number"),
    jobTitle: yup.string().required("Enter job title"),
    businessType: yup.string().required("Select your company's business type"),
    assetsUnderManagement: yup.string().when("businessType", {
      is: BUY_SIDE_INSTITUTION_OPTIONS,
      then: schema => schema.required("Select your company's AUM"),
      otherwise: schema => schema
    }),
    numberOfEmployees: yup
      .string()
      .required("Select your company's number of employees"),
    // planned_data_usage_ids
    dataUsage: yup.string().required("Select the data usage"),
    // recaptcha_response
    recaptcha: yup.string().required("Captcha is invalid"),
    // optin_newsletter
    allowOptin: yup.bool(),

    isAgree: yup.boolean().oneOf([true], "MUST BE ACCEPTED")
  })
  .required();

type FormType = yup.InferType<typeof formSchema>;

function OptinController({ control }: { control: Control<any> }) {
  const country = useWatch({
    control,
    name: "country"
  });
  // hide optin when usa or country field is no selected
  if (!country || country === "" || country === "United States") return null;
  return (
    <Controller
      name="allowOptin"
      control={control}
      defaultValue={false}
      render={({ field: { onChange, value, name } }) => (
        <FormRadioCheckboxButton
          id="allowOptin"
          name={name}
          type="checkbox"
          label="To comply with local law Nasdaq needs your permission to send you relevant information. If you do not opt-in now, we can`t send you emails."
          checked={value}
          optional={true}
          onChange={onChange}
          data-testid="signupform_optIn"
        />
      )}
    />
  );
}

export function AUMController({
  control,
  errors,
  children
}: React.PropsWithChildren<{
  control: Control<any>;
  errors: FieldErrors<{ assetsUnderManagement: string }>;
}>) {
  const businessType = useWatch({
    control,
    name: "businessType"
  });
  return (
    <Controller
      name="assetsUnderManagement"
      control={control}
      defaultValue=""
      render={({ field: { onChange, value, name } }) => (
        <FormField
          id="assetsUnderManagement"
          name={name}
          label="AUM"
          type="select"
          placeholder="Select..."
          optional={businessType !== BUY_SIDE_INSTITUTION_OPTIONS}
          disabled={businessType !== BUY_SIDE_INSTITUTION_OPTIONS}
          value={value}
          onChange={onChange}
          data-testid="signupForm_assets_under_management"
          invalid={!!errors.assetsUnderManagement}
          feedback={errors.assetsUnderManagement?.message}
        >
          <option value="" disabled defaultValue="">
            Select...
          </option>
          {children}
        </FormField>
      )}
    />
  );
}

function ADPSignUpForm({
  onSubmit: externalSubmit
}: {
  onSubmit?: (_: any) => void;
}) {
  const [countries, setCountries] = useState<Country[]>([]);
  useEffect(() => {
    getCountries().then(res =>
      setCountries(
        res.sort((a: Country, b: Country) =>
          a.sort_order > b.sort_order ? 1 : -1
        )
      )
    );
  }, []);
  const [
    [
      businessDataUsage = [],
      assetsUnderManagement = [],
      businessType = [],
      numberOfEmployees = []
    ],
    setEnchiridionData
  ] = useState<EnchiridionType[][]>([]);
  useEffect(() => {
    const enchiridionDataList = [
      "business_planned_data_usages",
      "assets_under_management",
      "business_types",
      "number_of_employees"
    ];
    // TODO error handle, caching
    Promise.all(enchiridionDataList.map(data => getEnchiridionData(data))).then(
      enchiridionData => {
        setEnchiridionData(enchiridionData);
      }
    );
  }, []);

  const onSubmit: SubmitHandler<FormType> = async (data, anonId) => {
    const payload = {
      user_type: "business",
      first_name: data.firstName,
      last_name: data.lastName,
      organization_name: data.organizationName,
      address_country: data.country,
      email: data.email,
      phone: data.phone,
      job_title: data.jobTitle,
      business_type_id: data.businessType,
      assets_under_management_id:
        data.businessType === BUY_SIDE_INSTITUTION_OPTIONS
          ? data.assetsUnderManagement
          : null,
      number_of_employee_id: data.numberOfEmployees,
      planned_data_usage_ids: [data.dataUsage],
      recaptcha_response: data.recaptcha,
      optin_newsletter: data.country === USA_VALUE ? true : data.allowOptin
    };
    await externalSubmit?.(payload);

    const user = LocalStorageService.getUser();
    trackEvent("Account Created", {
      submission_origin: "adp-signup-form",
      user_type: "business",
      ID: user?.id,
      "API Key": user?.apiKey,
      "Email Opt-In": payload?.optin_newsletter,
      business_type:
        user?.user_type === "business" &&
        user?.business_type_id &&
        user?.business_type_short_form
          ? user.business_type_short_form
          : undefined
    });

    const identityOptions = {
      id: user?.id,
      username: user?.username,
      firstName: user?.first_name,
      lastName: user?.last_name,
      email: user?.email,
      organizationName: user?.organization_name,
      apiKey: user?.api_key,
      optinNewsletter: payload?.optin_newsletter,
      assetsUnderManagementShortForm: user?.assets_under_management_short_form,
      plannedDataUsageShortForm: user?.planned_data_usage_short_form,
      jobTitle: user?.job_title,
      userType: user?.user_type,
      businessTypeShortForm: user?.business_type_short_form,
      addressCountry: user?.address_country,
      numberOfEmployeeId: user?.number_of_employee_id,
      anonymous_id: anonId
    };
    identifyUser(user?.id, identityOptions);
  };

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(formSchema)
  });
  const anonId = useGetAnonymousSegmentId();
  const trackEvent = useTrackEvent();

  return (
    <>
      <div className={styles.formHeaderContainer} data-testid="adp-form">
        <h4 className={styles.formHint}>For Institutional Clients Only</h4>
        <span className="with-top-blue-line" />
        <h3 className={styles.formHead}>Create An Institution Account</h3>
        <p className={styles.formHeadSub}>
          Alternative data is reserved for institutional clients only.
        </p>
      </div>
      <form
        className={styles.formItemContainer}
        onSubmit={handleSubmit(data => onSubmit(data, anonId))}
      >
        <div className={styles.formItem}>
          <Controller
            name="firstName"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value, name } }) => (
              <FormField
                id="firstName"
                name={name}
                value={value}
                onChange={onChange}
                label="FIRST NAME"
                type="text"
                placeholder="Type here..."
                data-testid="signupForm_firstName"
                invalid={!!errors.firstName}
                feedback={errors.firstName?.message}
              />
            )}
          />
        </div>

        <div className={styles.formItem}>
          <Controller
            name="lastName"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value, name } }) => (
              <FormField
                id="lastName"
                name={name}
                label="LAST NAME"
                type="text"
                placeholder="Type here..."
                value={value}
                onChange={onChange}
                data-testid="signupForm_lastName"
                invalid={!!errors.lastName}
                feedback={errors.lastName?.message}
              />
            )}
          />
        </div>

        <div className={styles.formItem}>
          <Controller
            name="organizationName"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value, name } }) => (
              <FormField
                id="organizationName"
                name={name}
                label="COMPANY NAME"
                type="text"
                placeholder="Type here..."
                value={value}
                onChange={onChange}
                data-testid="signupForm_organizationName"
                invalid={!!errors.organizationName}
                feedback={errors.organizationName?.message}
              />
            )}
          />
        </div>
        <div className={styles.formItem}>
          <Controller
            name="country"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value, name } }) => (
              <FormField
                id="country"
                name={name}
                label="COUNTRY"
                type="select"
                placeholder="Select..."
                value={value}
                onChange={onChange}
                data-testid="signupForm_country"
                invalid={!!errors.country}
                feedback={errors.country?.message}
              >
                <option value="" disabled>
                  Select...
                </option>
                {countries.map((item: any) => (
                  <option value={item.name} key={item.id}>
                    {item.name}
                  </option>
                ))}
              </FormField>
            )}
          />
        </div>
        <div className={styles.formItem}>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value, name } }) => (
              <FormField
                id="email"
                name={name}
                label="BUSINESS EMAIL"
                type="text"
                placeholder="Type here..."
                value={value}
                onChange={onChange}
                data-testid="signupForm_email"
                invalid={!!errors.email}
                feedback={errors.email?.message}
              />
            )}
          />
        </div>
        <div className={styles.formItem}>
          <Controller
            name="phone"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value, name } }) => (
              <FormField
                id="phone"
                name={name}
                label="BUSINESS PHONE"
                type="text"
                placeholder="Type here..."
                invalid={!!errors.phone}
                feedback={errors.phone?.message}
                value={value}
                onChange={onChange}
                data-testid="signupForm_phone"
              />
            )}
          />
        </div>
        <div className={styles.formItem}>
          <Controller
            name="jobTitle"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value, name } }) => (
              <FormField
                id="jobTitle"
                name={name}
                label="JOB TITLE"
                type="text"
                placeholder="Type here..."
                value={value}
                onChange={onChange}
                data-testid="signupForm_jobTitle"
                invalid={!!errors.jobTitle}
                feedback={errors.jobTitle?.message}
              />
            )}
          />
        </div>
        <div className={styles.formItem}>
          <Controller
            name="businessType"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value, name } }) => (
              <FormField
                id="businessType"
                name={name}
                label="Business Type"
                type="select"
                placeholder="Select..."
                value={value}
                onChange={onChange}
                data-testid="signupForm_businessType"
                invalid={!!errors.businessType}
                feedback={errors.businessType?.message}
              >
                <option value="" disabled defaultValue="">
                  Select...
                </option>
                {businessType.map((item: any) => (
                  <option value={item.id} key={item.id}>
                    {item.title}
                  </option>
                ))}
              </FormField>
            )}
          />
        </div>
        <div className={styles.formItem}>
          <AUMController control={control} errors={errors}>
            {assetsUnderManagement.map((item: any) => (
              <option value={item.id} key={item.id}>
                {item.title}
              </option>
            ))}
          </AUMController>
        </div>
        <div className={styles.formItem}>
          <Controller
            name="numberOfEmployees"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value, name } }) => (
              <FormField
                id="numberOfEmployees"
                name={name}
                label="NUMBER OF EMPLOYEES"
                type="select"
                placeholder="Select..."
                value={value}
                onChange={onChange}
                data-testid="signupForm_numberOfEmployees"
                invalid={!!errors.numberOfEmployees}
                feedback={errors.numberOfEmployees?.message}
              >
                <option value="" disabled defaultValue="">
                  Select...
                </option>
                {numberOfEmployees.map((item: any) => (
                  <option value={item.id} key={item.id}>
                    {item.title}
                  </option>
                ))}
              </FormField>
            )}
          />
        </div>
        <div className={styles.formItem}>
          <Controller
            name="dataUsage"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value, name } }) => (
              <FormField
                id="dataUsage"
                name={name}
                label="HOW WILL YOU BE USING THIS DATA *"
                type="select"
                placeholder="Select..."
                value={value}
                onChange={onChange}
                data-testid="signupForm_dataUsage"
                invalid={!!errors.dataUsage}
                feedback={errors.dataUsage?.message}
              >
                <option value="" disabled defaultValue="">
                  Select...
                </option>
                {businessDataUsage.map((item: any) => (
                  <option value={item.id} key={item.id}>
                    {item.title}
                  </option>
                ))}
              </FormField>
            )}
          />
        </div>
        <div className={styles.formRow}>
          <div className={styles.formItem}>
            <Controller
              name="recaptcha"
              control={control}
              defaultValue=""
              render={({ field: { onChange } }) => (
                <>
                  <ReCAPTCHA
                    className={errors.recaptcha && styles.formError}
                    sitekey={
                      process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY as string
                    }
                    onChange={onChange}
                    size="compact"
                  />
                  {errors.recaptcha?.message && (
                    <small color="muted" className={styles.formErrorMsg}>
                      {errors.recaptcha?.message}
                    </small>
                  )}
                </>
              )}
            />
          </div>
          <div className={styles.formItem}>
            <div>
              <Controller
                name="isAgree"
                control={control}
                defaultValue={false}
                render={({ field: { onChange, value, name } }) => (
                  <FormRadioCheckboxButton
                    id="agree"
                    name={name}
                    type="checkbox"
                    label={
                      (
                        <span>
                          I have agreed to the{" "}
                          <a href="https://www.nasdaq.com/legal">terms</a> of
                          service and{" "}
                          <a href="https://www.nasdaq.com/privacy-statement">
                            privacy policy
                          </a>
                        </span>
                      ) as any
                    }
                    checked={value}
                    onChange={onChange}
                    data-testid="signupForm_termsOfService"
                    invalid={!!errors.isAgree}
                    feedback={errors.isAgree?.message}
                  />
                )}
              />
            </div>
            <OptinController control={control} />
          </div>
        </div>
        <div className={styles.oktaHint}>
          <h3 className={styles.oktaHintHead}>Nasdaq OKTA</h3>
          <p className={styles.oktaHintSub}>
            Nasdaq Data Link is happy to announce we have migrated with Nasdaq's
            Signin. If you do not have Nasdaq user account, one will be created
            for you. Please keep an eye out for your activation email from
            Nasdaq Data Link.
          </p>
        </div>
        <div className={styles.formSubmit}>
          <Button
            block={true}
            type="submit"
            data-testid="signupForm_createAccount"
          >
            CREATE ACCOUNT
          </Button>
        </div>
      </form>
    </>
  );
}

export default ADPSignUpForm;
