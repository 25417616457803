import React from "react";
import { FontAwesomeIcon } from "@nef/core";

import "./style.scss";
import {
  Announcement,
  useAnnouncementsContext
} from "../../context/announcements-context";

const AnnouncementsBar = () => {
  const { announcements, removeAnnouncement } = useAnnouncementsContext();

  const handleCloseButtonClicked = (attributes: Announcement["attributes"]) => {
    removeAnnouncement({
      "cookie-name": attributes["cookie-name"],
      "cookie-expire-date": attributes["cookie-expire-date"]
    });
  };

  return (
    <>
      {announcements.map(({ attributes }) => (
        <div key={attributes.id} className={`alerts ${attributes.style}`}>
          <button
            type="button"
            className="close-button"
            onClick={() => handleCloseButtonClicked(attributes)}
          >
            <FontAwesomeIcon iconClassName="fa-times" />
          </button>
          <p
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: attributes["content-as-html"] }}
          />
        </div>
      ))}
    </>
  );
};

export default AnnouncementsBar;
