import React, { useState } from "react";
import {
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormField,
  NotificationHub
} from "@nef/core";

import { inviteUsersToOrganization } from "../../../api/api";
import { useGlobalModalContext } from "../../../context/modal-context";
import "./style.scss";

type Props = {
  organizationId: string;
};

const InviteUsersToOrgModal = ({ organizationId }: Props) => {
  const { hideModal } = useGlobalModalContext();
  const [emailAddresses, setEmailAddresses] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [apiErrorMsg, setApiErrorMsg] = useState();

  const sendInviteAndCloseModal = async () => {
    setIsLoading(true);
    try {
      await inviteUsersToOrganization(organizationId, emailAddresses);
      NotificationHub.send("primary", "Users successfully invited");
    } catch (error: any) {
      if (error?.errors?.new_user_list[0]) {
        // set api error message if provided by API, for example 'Please verify the format ...'
        setApiErrorMsg(error?.errors?.new_user_list[0]);
        return;
      }
      NotificationHub.send(
        "danger",
        "Failed to invite users, please verify the format"
      );
    }
    setIsLoading(false);
    hideModal();
  };

  const hideOnOutsideClick = () => {
    hideModal();
  };

  return (
    <Modal size="md" isOpen={true} toggle={() => hideOnOutsideClick()}>
      <ModalHeader>
        <p className="invite-users-modal-header with-top-blue-line">
          Invite you colleagues to use Nasdaq Data Link
        </p>
      </ModalHeader>
      <ModalBody>
        <p>
          Enter the email address of the users you would like to add to your
          organization below. Invite multiple users by seperating emails with a
          comma.
        </p>
        <span className="b-input__error">{apiErrorMsg} </span>
        <FormField
          placeholder="email@address.com, otheremail@sample.com, sample@email.com"
          type="textarea"
          style={{ width: "100%" }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setEmailAddresses(event?.target?.value)
          }
        />
      </ModalBody>
      <ModalFooter>
        <Button
          key="confirm"
          onClick={sendInviteAndCloseModal}
          isLoading={isLoading}
          disabled={emailAddresses === ""}
          data-testid="agree-button"
        >
          Send Invite(s)
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default InviteUsersToOrgModal;
