import React, { createContext, useContext } from "react";

import useBookmark, { UseBookmark } from "../hooks/useBookmark";

interface BookmarkProviderProps {
  children: React.ReactNode;
}

const BookmarkContext = createContext<UseBookmark | null>(null);

const useBookmarkContext = (): UseBookmark => {
  const context = useContext(BookmarkContext);
  if (!context) {
    throw new Error("useBookmark must used within a BookmarkProvider");
  }
  return context;
};

const BookmarkProvider: React.FC<BookmarkProviderProps> = ({ children }) => {
  const bookmarkState = useBookmark();

  return (
    <BookmarkContext.Provider value={bookmarkState}>
      {children}
    </BookmarkContext.Provider>
  );
};

export { BookmarkProvider, BookmarkContext, useBookmarkContext };
