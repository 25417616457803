import React from "react";
import { Redirect, useLocation } from "react-router-dom";

const RedirectWithQuery = ({ from, to }: { from: string; to: string }) => {
  const location = useLocation();

  return (
    <Redirect
      from={from}
      to={{
        pathname: to,
        search: location.search
      }}
    />
  );
};

export default RedirectWithQuery;
