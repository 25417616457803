import React, { useState } from "react";

import "./style.scss";
import { IAccordionLinksFields } from "../../../../../contentful/__generated__/types";

type Props = {
  fields: IAccordionLinksFields;
};

const THEMES = [{ name: "Dark", class: "dark-background" }];

const Links: React.FC<Props> = ({ fields }) => {
  const { theme, items } = fields;

  const themeClass = THEMES.find(t => t.name === theme)?.class;

  const [expanded, setExpanded] = useState(Array(items?.length).fill(false));
  const toggleSection = (index: number) => {
    const newExpanded = [...expanded];
    newExpanded[index] = !newExpanded[index];
    setExpanded(newExpanded);
  };

  return (
    <section className="accordion-link-wrapper">
      <div className={`accordion-links ${themeClass}`}>
        <div className="accordion-container">
          <dl className="accordion-border">
            {!!items &&
              items.map((item, index) => {
                return (
                  <div
                    className="accordion-item"
                    key={item.sys.id}
                    onClick={() => toggleSection(index)}
                  >
                    <dt className="accordion-button-container">
                      <button
                        type="button"
                        className="reset-button accordion-button"
                      >
                        <h3 className="accordion-heading">
                          {item.fields.heading}
                        </h3>
                        <div
                          className="accordion-header__icon"
                          aria-expanded={!expanded[index]}
                        />
                      </button>
                    </dt>

                    <dd
                      className="accordion-content"
                      aria-expanded={!expanded[index]}
                    >
                      <ul className="accordion-list">
                        {item.fields.items?.map(listItem => {
                          return (
                            <li
                              className="accordion-list-item"
                              key={listItem.sys.id}
                            >
                              <a
                                className="accordion-list-link"
                                href={listItem.fields.url}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {listItem.fields.label}
                              </a>
                              <p className="accordion-list-text">Open</p>
                            </li>
                          );
                        })}
                      </ul>
                    </dd>
                  </div>
                );
              })}
          </dl>
        </div>
      </div>
    </section>
  );
};

// eslint-disable-next-line import/prefer-default-export
export { Links as AccordionLinks };
