import React, { Suspense } from "react";

import ErrorBoundary from "./components/ErrorBoundary";
import Routes from "./routes";
import "./App.scss";
import { SessionTimeoutProvider } from "./context/timeout-context";
import { ContentfulProvider } from "./context/contentful-context";
import TopBar from "./components/topbar";
import PrivacyBanner from "./components/privacy-setting/privacy-banner";
import { RecentURLsProvider } from "./context/recent-urls-context";
import AnnouncementsBar from "./components/announcements-bar";

function App() {
  document.body.style.backgroundColor = "#FFF";

  return (
    <div data-testid="app-main" className="App">
      <ErrorBoundary>
        <ContentfulProvider>
          <SessionTimeoutProvider>
            <RecentURLsProvider>
              <Suspense fallback={<div />}>
                <AnnouncementsBar />
                <TopBar />
                <>
                  <Routes />
                  <PrivacyBanner />
                </>
              </Suspense>
            </RecentURLsProvider>
          </SessionTimeoutProvider>
        </ContentfulProvider>
      </ErrorBoundary>
    </div>
  );
}

export default App;
