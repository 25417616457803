import { UserTracking } from "../analytics/hooks/useTrackUser";
import { User } from "../api/types";

type AlgoliaInsights = {
  queryId?: string;
  objectId?: string;
  position?: number;
};

const LocalStorageService = {
  saveUser: (
    user: User | null,
    trackingFn: ({ identityOptions }: UserTracking, userId: string) => void
  ) => {
    const previousUser = LocalStorageService.getUser();
    window.localStorage.setItem("user", JSON.stringify(user));

    if (previousUser?.id !== user?.id && trackingFn) {
      trackingFn(
        {
          identityOptions: {
            username: user?.username,
            firstName: user?.first_name,
            lastName: user?.last_name
          }
        },
        user?.id || "anonymous"
      );
    }
  },

  getUser: (): User | null => {
    const user = window.localStorage.getItem("user");

    return user ? JSON.parse(user) : null;
  },

  removeUser: () => {
    window.localStorage.removeItem("user");
  },

  saveAlgoliaInsights: (insights: AlgoliaInsights) => {
    window.localStorage.setItem("algoliaInsights", JSON.stringify(insights));
  },

  getAlgoliaInsights: (): AlgoliaInsights => {
    const insights = window.localStorage.getItem("algoliaInsights");

    return insights ? JSON.parse(insights) : null;
  },

  savePreviousURL: (previousURL: string) => {
    window.localStorage.setItem("previousURL", previousURL);
  },

  getPreviousURL: () => {
    return window.localStorage.getItem("previousURL") || "";
  },

  removePreviousURL: () => {
    window.localStorage.removeItem("previousURL");
  },

  getLastContentfulURL: () => {
    return window.localStorage.getItem("lastContentfulURL") || "";
  },

  removeLastContentfulURL: () => {
    window.localStorage.removeItem("lastContentfulURL");
  },

  saveInviteToken: (inviteToken: string) => {
    window.localStorage.setItem("invite", inviteToken);
  },

  getInviteToken: () => {
    return window.localStorage.getItem("invite") || "";
  },

  removeInviteToken: () => {
    window.localStorage.removeItem("invite");
  }
};

export default LocalStorageService;
