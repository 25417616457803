import { AppAction } from "./types";

import { Types, ActionMap } from "./index";

type organizationsPayload = {
  [Types.CreateMyOrganizations]: {
    myOrganizations: any;
  };
  [Types.CreateDatabases]: {
    databases: any;
  };
  [Types.CreateTeams]: {
    teams: any;
  };
  [Types.CreateUsers]: {
    users: any;
  };
  [Types.CreateOrganizationDetails]: {
    organizationDetails: any;
  };
  [Types.CreatePermissionDetails]: {
    permissionDetails: any;
  };
};

export type OrganizationsActions =
  ActionMap<organizationsPayload>[keyof ActionMap<organizationsPayload>];

export const organizationReducer = (state: any, action: AppAction) => {
  switch (action.type) {
    case Types.CreateMyOrganizations:
      return { ...state, myOrganizations: action.payload.myOrganizations };
    case Types.CreateDatabases:
      return { ...state, myOrganizations: action.payload.databases };
    case Types.CreateTeams:
      return { ...state, myOrganizations: action.payload.teams };
    case Types.CreateUsers:
      return { ...state, myOrganizations: action.payload.users };
    case Types.CreateOrganizationDetails:
      return { ...state, myOrganizations: action.payload.organizationDetails };
    case Types.CreatePermissionDetails:
      return { ...state, currentPermission: action.payload.permissionDetails };
    default:
      return state;
  }
};
