import React, { ChangeEvent, useState } from "react";
import { Link, FormFieldWithIcon } from "@nef/core";
import { useHistory } from "react-router-dom";

import "./style.scss";

const NoMatch: React.FC = () => {
  const history = useHistory();
  const [searchQuery, setSearchQuery] = useState<string>("");

  const handleSubmit = (event: ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    history.push(`/search?query=${encodeURIComponent(searchQuery)}`);
  };
  return (
    <>
      <div className="error-page">
        <h2 className="error-page__heading" data-testid="heading-404">
          PAGE NO LONGER EXISTS.
        </h2>
        <p>
          You may be able to find the data you’re looking for by using the
          search box below.
        </p>
        <form onSubmit={handleSubmit}>
          <div className="search-box">
            <FormFieldWithIcon
              type="text"
              className="search-box__input"
              placeholder="Search core financial data feeds"
              addonType="prepend"
              iconClassName="fa-search"
              value={searchQuery}
              onChange={(e: any) => setSearchQuery(e.target.value)}
              data-testid="search-input"
            />
          </div>
        </form>

        <p>Here are some further options for you:</p>

        <ul className="error-page__options" data-testid="options-list">
          <li>
            Return to the&nbsp;
            <Link href="/" style={{ display: "inline-block" }}>
              Nasdaq Data Link home page
            </Link>
            .
          </li>
          <li>
            See our premium{" "}
            <Link href="/publishers" style={{ display: "inline-block" }}>
              data publishers
            </Link>
            .
          </li>
          <li>
            Read our <a href="https://help.data.nasdaq.com">help pages</a>.
          </li>
        </ul>

        <p>Thank you for using Nasdaq Data Link!</p>
      </div>
    </>
  );
};

export default NoMatch;
