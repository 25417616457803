export const getCookie = (name: string) => {
  const cookies = decodeURIComponent(document.cookie).split(";");

  const content = cookies.find(c => c.trim().startsWith(`${name}=`)) || "";
  return content.split("=").slice(1).join("");
};

export const deleteCookie = (cookieName: string) => {
  document.cookie = `${cookieName}=quandl; expires=${new Date(
    0
  ).toUTCString()}`;
  document.cookie = `${cookieName}=quandl; path=${
    window.location.pathname
  }; expires=${new Date(0).toUTCString()}`;
};

export const setCookie = (settingString: string) => {
  const setting = JSON.parse(settingString);
  document.cookie = `privacy_setting=%7B%22performance%22%3A${setting.performance}%2C%22functionality%22%3A${setting.functionality}%2C%22targeting%22%3A${setting.targeting}%2C%22accepted%22%3A${setting.accepted}%7D;path=/;`;
};
