import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import React from "react";

import { IStructuredTextCtaBoxFields } from "../../../../../contentful/__generated__/types";
import { LinkButton } from "../../button/link";
import { ActionButton } from "../../button/action";
import "./style.scss";
import useTrackEvent from "../../../../analytics/hooks/useTrackEvent";

const handleCtaClick = (
  trackEvent: (event: string, properties?: {}) => void,
  analyticsEventName?: string,
  downloadUrl?: string
) => {
  if (analyticsEventName === "Data Downloaded") {
    trackEvent(analyticsEventName, {
      download_link: downloadUrl,
      submission_origin: "tools/excel"
    });
  }
};

const RICHTEXT_OPTIONS = {
  renderNode: {
    [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
      const trackEvent = useTrackEvent();
      const { data } = node;
      const { action } = data.target.fields;

      return (
        <div
          className="structured-cta-box__section__right__button"
          onClick={() =>
            handleCtaClick(
              trackEvent,
              data.target.fields?.analyticsEventName,
              data.target.fields?.url
            )
          }
        >
          {action ? (
            <ActionButton fields={data.target.fields} />
          ) : (
            <LinkButton
              theme={data.target.fields.theme}
              label={data.target.fields.label}
              url={data.target.fields.url}
            />
          )}
        </div>
      );
    }
  }
};

const RICHTEXT_OPTIONS_HEADING = {
  renderNode: {
    [BLOCKS.HEADING_1]: (node: any) => {
      const { content } = node;
      return <h1 className="with-top-blue-line">{content[0].value}</h1>;
    }
  }
};

type Props = {
  fields: IStructuredTextCtaBoxFields;
};

const CtaBox: React.FC<Props> = ({ fields }) => {
  const { ctaRichTextField, richTextBody, alignment } = fields;

  return (
    <div className="structured-cta-box__section">
      <div className="structured-cta-box__section__left">
        {!!richTextBody &&
          documentToReactComponents(richTextBody, RICHTEXT_OPTIONS_HEADING)}
      </div>
      <div
        className="structured-cta-box__section__right"
        style={{ textAlign: alignment }}
      >
        {!!ctaRichTextField &&
          documentToReactComponents(ctaRichTextField, RICHTEXT_OPTIONS)}
      </div>
    </div>
  );
};

// eslint-disable-next-line import/prefer-default-export
export { CtaBox as CtaBoxRichText };
