import React, { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { ITableGroupFilterableFields } from "../../../../../contentful/__generated__/types";
import "./style.scss";
import Table from "../table";

type Props = {
  fields: ITableGroupFilterableFields;
};

const TableGroup: React.FC<Props> = ({ fields }) => {
  const location = useLocation();
  const ref = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (location.hash === `#${fields.nameId}`) {
      ref.current?.scrollIntoView();
    }
  }, [fields.nameId]);

  return (
    <section
      id={fields.nameId}
      key={fields.nameId}
      className="tableGroup__content"
      ref={ref}
    >
      <a href={`${location.search}#${fields.nameId}`}>
        <h3 className="tableGroup__name" id={fields.nameId}>
          {fields.name}
        </h3>
      </a>
      {fields.tables?.map(table => (
        <Table fields={table.fields} key={table.fields.nameUrl} />
      ))}
    </section>
  );
};

export default TableGroup;
