import React from "react";

import "./style.scss";
import { IDataProvidersFields } from "../../../../contentful/__generated__/types";

const THEMES = [
  { name: "Light", class: "light-theme" },
  { name: "Dark", class: "dark-theme" }
];

type Props = {
  fields: IDataProvidersFields;
};

const DataProviders: React.FC<Props> = ({ fields }) => {
  const { theme, dataProvider } = fields;

  const themeClass = THEMES.find(t => t.name === theme)?.class;
  const className = ` ${themeClass}`;

  return (
    <div className={`data-providers ${className}`}>
      <div className="cards-row">
        {dataProvider?.map(data => {
          return (
            <div className="card-container" key={data.sys.id}>
              <img
                className="card__icon"
                src={`https://${data.fields.logo?.fields.file.url}`}
                alt={data.fields.logo?.fields.title}
              />
              <div className="card__text">
                <a target="_blank" rel="noreferrer" href={data.fields.link}>
                  {data.fields.name}
                  <span style={{ whiteSpace: "nowrap" }}>&nbsp;-&gt;</span>
                </a>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

// eslint-disable-next-line import/prefer-default-export
export { DataProviders };
