import React, { useEffect, useState } from "react";
import { FormField } from "@nef/core";
import { useHistory, useLocation } from "react-router-dom";

import { ITableGroupsFilterableFields } from "../../../../../contentful/__generated__/types";
import TableGroup from "../table-group";

import "./style.scss";

type Props = {
  fields: ITableGroupsFilterableFields;
};

const TableGroups: React.FC<Props> = ({ fields }) => {
  const history = useHistory();
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  const [categoryFilter, setCategoryFilter] = useState(
    query.get("category") ?? ""
  );
  const [subcategoryFilter, setSubcategoryFilter] = useState(
    query.get("subcategory") ?? ""
  );

  const [data, setData] = useState(fields.tableGroups);

  const categories = fields?.tableGroups?.reduce(
    (allCategories: string[], tableGroup) => {
      if (!allCategories.includes(tableGroup.fields.category)) {
        allCategories.push(tableGroup.fields.category);
      }
      return allCategories;
    },
    []
  );

  const subCategories = fields?.tableGroups?.reduce(
    (allSubcategories: string[], tableGroup) => {
      if (
        tableGroup.fields.subcategory &&
        !allSubcategories.includes(tableGroup.fields.subcategory) &&
        tableGroup.fields.category === categoryFilter
      ) {
        allSubcategories.push(tableGroup.fields.subcategory);
      }
      return allSubcategories;
    },
    []
  );

  const filterData = (category: string, subcategory = "") => {
    if (!category) return fields.tableGroups;
    if (subcategory) {
      return fields.tableGroups?.filter(
        tableGroup =>
          tableGroup.fields.category === category &&
          tableGroup.fields.subcategory === subcategory
      );
    }
    return fields.tableGroups?.filter(
      tableGroup => tableGroup.fields.category === category
    );
  };

  useEffect(() => {
    setData(filterData(categoryFilter, subcategoryFilter));
  }, [categoryFilter, subcategoryFilter]);

  return (
    <div className="tableGroups__wrapper">
      <div className="tableGroups__filter">
        <div className="filter-dropdown">
          <h3>Category</h3>
          <FormField
            id="category_filter"
            className="filter"
            name="filter"
            type="select"
            value={categoryFilter}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setCategoryFilter(e.target.value);
              setSubcategoryFilter("");
              const params = new URLSearchParams();
              if (e.target.value) {
                params.append("category", e.target.value);
              } else {
                params.delete("category");
              }
              history.push({ search: params.toString() });
            }}
          >
            <option value="" selected>
              Select
            </option>
            {categories?.map(category => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </FormField>
        </div>

        <div className="filter-dropdown">
          <h3>Subcategory</h3>
          <FormField
            id="subcategory_filter"
            className="filter"
            name="filter"
            type="select"
            value={subcategoryFilter}
            disabled={!categoryFilter}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSubcategoryFilter(e.target.value);
              const params = new URLSearchParams(search);
              if (e.target.value) {
                params.append("subcategory", e.target.value);
              } else {
                params.delete("subcategory");
              }
              history.push({ search: params.toString() });
            }}
          >
            <option value="">Select</option>
            {subCategories?.map(subCategory => {
              if (subCategory)
                return (
                  <option key={subCategory} value={subCategory}>
                    {subCategory}
                  </option>
                );
              return <></>;
            })}
          </FormField>
        </div>
      </div>

      <div>
        {data?.map(tableGroup => (
          <TableGroup
            key={tableGroup.fields.nameId}
            fields={tableGroup.fields}
          />
        ))}
      </div>
    </div>
  );
};

export default TableGroups;
