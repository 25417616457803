import React, { useEffect, useState } from "react";
import { Alert } from "@nef/core";

import PrivacySettingModal from "../privacy-setting-panel";
import useAuth from "../../../hooks/useAuth";
import { getCookie } from "../../../helpers/cookie-helpers";
import {
  useGlobalModalContext,
  MODAL_TYPES
} from "../../../context/modal-context";
import "./style.scss";
import { PrivacySetting } from "../../../api/types";
import PrivacySettingsService from "../../../services/privacySettings";

const PrivacyBanner = () => {
  const { showModal } = useGlobalModalContext();
  const [hasUpdatedPrivacy, setHasUpdatedPrivacy] = useState<boolean>(false);
  // TODO: check what is the use for `privacySettings`
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [privacySettings, setPrivacySettings] = useState<PrivacySetting>(
    PrivacySettingsService.defaultSettings()
  );
  const [alert, setAlert] = useState<string>("");

  const { authenticated } = useAuth();
  const isAuthenticated = authenticated();

  useEffect(() => {
    getPrivacyFromCookie();
  }, []);

  const getPrivacyFromCookie = () => {
    const cookie = getCookie("privacy_setting").trim();
    if (!cookie) {
      setHasUpdatedPrivacy(false);
      // If they dont have a cookie, set it
      PrivacySettingsService.updatePrivacySetting(
        PrivacySettingsService.defaultSettings(),
        setPrivacySettings,
        setAlert,
        setHasUpdatedPrivacy,
        isAuthenticated
      );
    } else {
      const currentCookie = JSON.parse(cookie);
      setPrivacySettings(currentCookie);
      if (!currentCookie.accepted || currentCookie.accepted === false) {
        setHasUpdatedPrivacy(false);
      } else {
        setHasUpdatedPrivacy(true);
      }
    }
  };

  const openPrivacySettingsModal = () => {
    showModal(MODAL_TYPES.PLAIN_MODAL, {
      children: <PrivacySettingModal />
    });
  };

  return (
    <div data-testid="privacy-banner">
      {!!alert.length && <Alert color="danger">{alert}</Alert>}
      {!hasUpdatedPrivacy ? (
        <div className="privacy__container">
          <div className="">
            <h5 className="privacy__heading">Nasdaq Data Link uses cookies</h5>
            <p
              className="privacy__content"
              data-testid="privacy-banner-message"
            >
              This website utilizes cookies and similar technologies for
              functionality and other purposes. Your use of this website
              constitutes your acceptance of cookies. To learn more about our
              cookies and the choices we offer, please see our{" "}
              <a
                data-testid="privacy-banner-policy"
                href="https://www.nasdaq.com/cookie-statement"
              >
                Cookie Policy
              </a>
              . You can also{" "}
              <a
                onClick={openPrivacySettingsModal}
                data-testid="privacy-banner-settings"
              >
                manage your settings here
              </a>
              .
            </p>
          </div>
          <button
            type="button"
            onClick={() =>
              PrivacySettingsService.acceptPrivacy(
                setPrivacySettings,
                setAlert,
                setHasUpdatedPrivacy,
                isAuthenticated
              )
            }
            className="privacy__button b-button"
            data-testid="privacy-banner-accept"
          >
            Okay
          </button>
        </div>
      ) : (
        <div
          className="hidden"
          data-testid={`privacy-accepted-${hasUpdatedPrivacy}`}
        />
      )}
    </div>
  );
};

export default PrivacyBanner;
