import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "@nef/core";

interface OrganizationConfirmationModalProps {
  confirmationTitle: string;
  confirmationMessage: string;
  onCancel: () => void;
  onConfirm: () => void;
  onSelect?: (option: string | null) => void;
}

const OrganizationConfirmationModal = ({
  confirmationTitle,
  confirmationMessage,
  onCancel,
  onConfirm,
  onSelect
}: OrganizationConfirmationModalProps) => {
  const closeModal = () => onSelect?.(null);

  return (
    <>
      <Modal toggle={closeModal} isOpen={true}>
        <ModalHeader>{confirmationTitle}</ModalHeader>
        <ModalBody>{confirmationMessage}</ModalBody>
        <ModalFooter>
          <Button outline onClick={onCancel}>
            CANCEL
          </Button>
          <Button color="danger" onClick={onConfirm}>
            CONFIRM
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default OrganizationConfirmationModal;
