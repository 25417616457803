import React, { useEffect } from "react";
import "./style.scss";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, Node, Document } from "@contentful/rich-text-types";

import { LinkButton } from "../../button/link";
import { ActionButton } from "../../button/action";

const RICHTEXT_OPTIONS = {
  renderNode: {
    [BLOCKS.EMBEDDED_ENTRY]: ({ data }: Node) => {
      const { theme, label, url } = data.target.fields;
      const { action } = data.target.fields;

      return action ? (
        <ActionButton fields={data.target.fields} />
      ) : (
        <LinkButton theme={theme} label={label} url={url} />
      );
    },
    [BLOCKS.EMBEDDED_ASSET]: ({ data }: Node) => {
      const {
        file,
        description,
        details: { image }
      } = data.target.fields;

      return (
        <img
          src={`https://${file.url}`}
          height={image.height}
          width={image.width}
          alt={description}
        />
      );
    }
  }
};

type Props = {
  fields: Document | undefined;
};

const PageSidebox: React.FC<Props> = ({ fields }) => {
  useEffect(() => {
    const divTag = document.createElement("div");
    divTag.classList.add("my-flex-container");
    const div = document.querySelector(
      ".__3f1eb__sticky-header"
    ) as HTMLElement;
    const pTags = div.querySelectorAll("p");
    const aTag = document.querySelector(
      ".__3f1eb__sticky-header a"
    ) as HTMLElement;

    pTags?.forEach((pTag: HTMLParagraphElement) => {
      if (!pTag.textContent) {
        pTag.remove();
      } else {
        divTag.appendChild(pTag);
        divTag.appendChild(aTag);
      }
    });

    document
      .querySelector(".__3f1eb__sticky-header")
      ?.appendChild(divTag) as HTMLElement;

    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const sidebox = document.querySelector(
        ".__3f1eb__sticky-header--revealed"
      ) as HTMLElement;
      if (scrollTop > 50) {
        sidebox.classList.add("visible");
      } else {
        sidebox.classList.remove("visible");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section>
      <div className="__3f1eb__sticky-header--revealed __3f1eb__sticky-header">
        {!!fields && documentToReactComponents(fields, RICHTEXT_OPTIONS)}
      </div>
      <aside className="contentful__sidebox-wrapper">
        <div
          style={{ top: "109px" }}
          className="fixed-wrapper--active container"
        >
          <div className="fixed-sidebar">
            {!!fields && documentToReactComponents(fields, RICHTEXT_OPTIONS)}
          </div>
        </div>
      </aside>
    </section>
  );
};

export default PageSidebox;
