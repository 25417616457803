import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import React from "react";

import { IFeaturesListFields } from "../../../../../contentful/__generated__/types";
import "./style.scss";

type Props = {
  fields: IFeaturesListFields;
};

const THEMES = [
  { name: "Black/Green", class: "black-green-theme", backgroundColor: "black" },
  { name: "White/Black", class: "white-black-theme", backgroundColor: "white" }
];

const List: React.FC<Props> = ({ fields }) => {
  const { features, graphic, theme } = fields;

  const themeColor = THEMES.find(t => t.name === theme)?.backgroundColor;
  const themeClass = THEMES.find(t => t.name === theme)?.class;

  const background = {
    backgroundImage: `url(https://${graphic?.fields.file.url})`
  };

  return (
    <div
      style={{ background: themeColor }}
      className="features-list__section-wrapper"
    >
      <div
        className={`section-content section-content--with-bottom-margin ${themeClass}`}
      >
        <div className="image-items">
          {!!features &&
            features.map(feature => {
              return (
                <div key={feature.sys.id} className="card">
                  <div className="card__container">
                    <img
                      className="card__icon"
                      src={`https://${feature.fields.icon?.fields.file.url}`}
                      alt={feature.fields.icon?.fields.title}
                    />
                    <div className="card__content">
                      <h3 className="card__header">{feature.fields.heading}</h3>
                      <div className="card__body">
                        {feature.fields.body &&
                          documentToReactComponents(feature.fields.body)}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>

      <div className="overlapping-images-wrapper">
        <div className="overlapping-images">
          <div style={background} className="overlapping-image" />
          <div className="colored-box" />
        </div>
      </div>
    </div>
  );
};

// eslint-disable-next-line import/prefer-default-export
export { List as FeaturesList };
