import { EmptyState } from "@nef/core";
import React, { ErrorInfo } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

const StyledEmptyState = styled(EmptyState)`
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center !important;
`;

const DefaultFallbackUIComponent = ({ resetErrorBoundary }: any) => {
  const history = useHistory();

  return (
    <>
      <StyledEmptyState
        titleSize={1}
        icon="documents"
        emptyStateTitle="Something went wrong"
        button={{
          children: "Go home",
          onClick: () => {
            resetErrorBoundary();
            history.push("/");
          }
        }}
      >
        We're sorry, an unexpected error occurred. Please try again later.
      </StyledEmptyState>
    </>
  );
};

interface ErrorBoundaryProps {
  fallBackComponent?: any;
  onError?: (error: Error, errorInfo: ErrorInfo) => void;
  onReset?: () => void;
  children?: React.ReactNode;
}

interface ErrorBoundaryState {
  error: Error | null;
}

interface fallBackUIProps {
  error: Error;
  resetErrorBoundary: () => void;
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  public static defaultProps = {
    fallBackComponent: DefaultFallbackUIComponent
  };

  private static getDerivedStateFromError(error: Error) {
    return { error };
  }

  private reset = () => {
    this.setState({ error: null });
  };

  private resetErrorBoundary = () => {
    const { onReset } = this.props;
    if (onReset) {
      onReset();
    }
    this.reset();
  };

  public constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const { onError } = this.props;
    if (onError) {
      onError(error, errorInfo);
    }
  }

  public render() {
    const { error } = this.state;
    const { children, fallBackComponent: FallbackUIComponent } = this.props;
    if (error) {
      const props: fallBackUIProps = {
        error,
        resetErrorBoundary: this.resetErrorBoundary
      };
      if (React.isValidElement(FallbackUIComponent)) {
        return FallbackUIComponent;
      }
      if (FallbackUIComponent) {
        return <FallbackUIComponent {...props} />;
      }
    }

    return children;
  }
}
export default ErrorBoundary;
