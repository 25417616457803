import React, { useState, FormEvent, useEffect } from "react";
import { Modal, Button, FormField } from "@nef/core";

import { useGlobalModalContext } from "../../../context/modal-context";
import "./style.scss";
import {
  HelpscoutArticle,
  HelpscoutCategory,
  Product,
  SupportRequest,
  User
} from "../../../api/types";
import {
  createSupportRequest,
  getCategoryHelpscoutArticles,
  getHelpscoutArticles,
  getHelpscoutCategories,
  getMyInfo
} from "../../../api/api";
import { emailCheck } from "../adp-lead-form";

interface FormData {
  name: string;
  email: string;
  categoryId: string;
  comment: string;
}

interface SupportRequestModalProps {
  isLicensePage: boolean;
  referrer?: string;
  product?: Product;
}

const handleErrors = (
  name: string,
  email: string,
  category: string,
  comment: string
) => {
  const errors = {
    name: name === "" ? "Name - can't be blank" : "",
    email:
      email === ""
        ? "Email - can't be blank, email - is invalid"
        : !emailCheck(email)
        ? "Email - is invalid"
        : "",
    category: category === "" ? "Category can't be blank" : "",
    comment: comment === "" ? "Company - can't be blank" : ""
  };

  return errors;
};

export const SupportRequestModal = ({
  product,
  referrer: _referrer
}: SupportRequestModalProps) => {
  const { hideModal } = useGlobalModalContext();
  const [articles, setArticles] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isLicensePage, setIsLicensePage] = useState(false);
  const [productCode, setProductCode] = useState<string | null>(null);
  const [productType, setProductType] = useState<string | null>(null);
  const [formData, setFormData] = useState<FormData>({
    name: "",
    email: "",
    categoryId: "",
    comment: ""
  });

  const [formErrors, setFormErrors] = useState({
    name: "",
    email: "",
    category: "",
    comment: ""
  });
  const [user, setUser] = useState<User>({
    first_name: "",
    email: "",
    job_title: "",
    saml_first_login: false,
    api_key: "",
    apiKey: "",
    planned_data_usage_short_form: "",
    administrator: false,
    roles_array: [],
    user_type: "",
    business_type_short_form: "",
    assets_under_management_short_form: ""
  });

  const referrer = _referrer || window.location.href;

  useEffect(() => {
    getMyInfo().then(response => {
      setUser(response.data.user);
    });
  }, []);

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  useEffect(() => {
    if (user?.first_name) {
      setFormData(prevData => ({
        ...prevData,
        name: user?.first_name as string,
        email: user?.email
      }));
    }
  }, [user]);

  useEffect(() => {
    const getArticles = async () => {
      const { data } = await getHelpscoutArticles();
      const orderedArticles = data
        ?.sort(
          (a: HelpscoutArticle, b: HelpscoutArticle) =>
            b["hs-popularity"] - a["hs-popularity"]
        )
        .slice(0, 5);

      setArticles(orderedArticles);
    };

    getArticles();
  }, []);

  useEffect(() => {
    const getCategories = async () => {
      const { data } = await getHelpscoutCategories();
      setCategories(data);
    };

    getCategories();
  }, []);

  useEffect(() => {
    const getCategoryArticles = async () => {
      if (formData?.categoryId !== undefined) {
        const { data } = await getCategoryHelpscoutArticles(
          formData?.categoryId
        );
        const orderedArticles = data
          ?.sort(
            (a: HelpscoutArticle, b: HelpscoutArticle) =>
              b["hs-popularity"] - a["hs-popularity"]
          )
          .slice(0, 5);

        setArticles(orderedArticles);
      }
    };

    getCategoryArticles();
  }, [formData?.categoryId]);

  useEffect(() => {
    if (product) {
      setIsLicensePage(product.isLicensePage);
      setProductCode(product.code || null);
      setProductType(product.prodType || null);

      let licenseCategoryId: any;

      categories.forEach((category: HelpscoutCategory) => {
        if (category["hs-name"] === "Licensing") {
          licenseCategoryId = category.id;
        }
      });
      if (licenseCategoryId) {
        setFormData(prevData => ({
          ...prevData,
          categoryId: licenseCategoryId
        }));
      }
    }
  }, [product, categories]);

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const errors = handleErrors(
      formData?.name,
      formData?.email,
      formData?.categoryId,
      formData?.comment
    );

    setFormErrors(errors);
    const hasErrors = Object.values(errors).some(error => error);

    const { name, email, categoryId, comment } = formData;
    const supportRequestParams: SupportRequest = {
      name,
      email,
      comment,
      categoryId: parseInt(categoryId, 10),
      referrer,
      productCode,
      productType
    };

    if (!hasErrors) {
      createSupportRequest(supportRequestParams).catch(error => {
        return error;
      });
      hideModal();
    }
  };

  return (
    <Modal
      size="lg"
      isOpen={true}
      toggle={hideModal}
      data-testid="supportRequestModal"
    >
      <section className="support-request">
        <h1 className="support-request__title with-top-blue-line">
          Have a question?
        </h1>
        <p className="support-request__subtitle">
          Please fill out all the fields. We will get back to you within one to
          two business days.
        </p>

        <form onSubmit={onSubmit}>
          <div className="support-request__content">
            <div className="support-request__form">
              {formErrors.name && (
                <p className="error-message">{formErrors.name}</p>
              )}
              <FormField
                id="name"
                name="name"
                type="text"
                placeholder="Name"
                value={formData.name}
                onChange={handleInputChange}
              />

              {formErrors.email && (
                <p className="error-message">{formErrors.email}</p>
              )}
              <FormField
                id="email"
                name="email"
                type="text"
                placeholder="Email"
                value={formData.email}
                onChange={handleInputChange}
              />

              {formErrors.category && (
                <p className="error-message">{formErrors.category}</p>
              )}
              {!isLicensePage && (
                <FormField
                  id="categoryId"
                  name="categoryId"
                  type="select"
                  placeholder="Category"
                  value={formData.categoryId}
                  onChange={handleInputChange}
                >
                  <option value="" disabled selected>
                    Category
                  </option>
                  {categories?.map((category: any) => (
                    <option value={category.id} key={category.id}>
                      {category.attributes["hs-name"]}
                    </option>
                  ))}
                </FormField>
              )}

              {formErrors.comment && (
                <p className="error-message">{formErrors.comment}</p>
              )}
              <FormField
                id="comment"
                name="comment"
                type="textarea"
                cols={100}
                rows={5}
                placeholder="Comment"
                value={formData.comment}
                onChange={handleInputChange}
              />

              <Button type="submit" style={{ width: "100%" }}>
                Submit
              </Button>
            </div>

            {!isLicensePage && articles.length > 0 && (
              <div className="articles">
                <h4 className="articles__header">Popular Articles</h4>
                <ul className="articles__content">
                  {articles?.map((article: any) => (
                    <li key={article.id} className="articles__link">
                      <a
                        href={article.attributes["hs-public-url"]}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {article.attributes["hs-name"]}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </form>
      </section>
    </Modal>
  );
};

export default SupportRequestModal;
