import { AppAction } from "./types";

import { Types, ActionMap } from "./index";

type organizationsPayload = {
  [Types.CreateOrganizationName]: {
    organizationName: string;
  };
  [Types.CreateOrganizationId]: {
    organizationId: number | null;
  };
};

export type MyOrganizationActions =
  ActionMap<organizationsPayload>[keyof ActionMap<organizationsPayload>];

export const myOrganizationReducer = (state: any, action: AppAction) => {
  switch (action.type) {
    case Types.CreateOrganizationName:
      return { ...state, organizationName: action.payload.organizationName };
    case Types.CreateOrganizationId:
      return { ...state, organizationId: action.payload.organizationId };
    default:
      return state;
  }
};
