import React from "react";
import "./style.scss";

type Props = {
  apiKey: string | undefined;
  redirectFn?: () => void;
};

const RegisterSuccess = ({ apiKey, redirectFn }: Props) => {
  return (
    <section className="register-success-modal" data-testid="register-success">
      <h1 className="welcome-header">Welcome to Nasdaq Data Link!</h1>
      <h3 className="small-header u-align-center f-kicker api-header">
        Your API key is:
      </h3>
      <h3 className="api-key" data-testid="api-key">
        {apiKey}
      </h3>

      <p className="u-margin-bottom-10">
        and can be found in your{" "}
        <a>
          <span onClick={redirectFn}>account settings</span>
        </a>
        .
      </p>
      <p className="u-margin-bottom-40 api-description">
        Any help you need to get started using our data can be found in the{" "}
        <a href="https://help.data.nasdaq.com" data-testid="helpdoc-link">
          help
        </a>{" "}
        section.
      </p>
    </section>
  );
};

export default RegisterSuccess;
