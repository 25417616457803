import { createContext } from "react";

import { User } from "../api/types";

export type AuthenticatedUser = User | null;

export interface AuthContextInterface {
  authenticatedUser: AuthenticatedUser;
  setAuthenticatedUser(user: AuthenticatedUser): any;
  authenticated(): boolean;
  getUserInfo(): void;
  redirectToSamlLogin(samlLoginEmail: string): void;
  login(email: string, password: string, redirectTo?: string): any;
  logout(config?: { to: string }): void;
  signup(info: any, redirectTo?: string): any;
  getProducts(): any;
}

const initialState: AuthContextInterface = {
  authenticatedUser: null,
  setAuthenticatedUser: () => null,
  getUserInfo: () => null,
  redirectToSamlLogin: () => null,
  authenticated: () => false,
  login: () => null,
  logout: () => null,
  signup: () => null,
  getProducts: () => null
};

const AuthContext = createContext(initialState);

export default AuthContext;
