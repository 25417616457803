import React, { useContext } from "react";
import { useMediaQuery } from "react-responsive";
import { NavbarLogo, FontAwesomeIcon } from "@nef/core";
import { Link, NavLink, useRouteMatch } from "react-router-dom";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import "@algolia/autocomplete-theme-classic/dist/theme.css";

import useAuth from "../../hooks/useAuth";
import {
  useGlobalModalContext,
  MODAL_TYPES
} from "../../context/modal-context";
import MarketoForm from "../contact-sales-forms";
import { ContentfulContext } from "../../context/contentful-context";
import { useUserContext } from "../../context/user-context";

import styles from "./style.module.scss";
import UserDropdown from "./components/user-dropdown";
import Autocomplete from "./components/autocomplete";

type TopBarButtonLinkProps = {
  route: string;
  body: string;
  color: string;
};

const BUTTON_THEMES: Record<string, string> = {
  "Orange Border": "#fcb815",
  "Blue Border": "#30c2c9"
};

const TopBarButtonLink = React.forwardRef<
  HTMLAnchorElement,
  TopBarButtonLinkProps
>(({ route, body, color }, ref) => {
  const themeColor = BUTTON_THEMES[color] || color;

  return (
    <NavLink
      innerRef={ref}
      to={route}
      style={{ border: `1px solid ${themeColor}` }}
      className={styles.button}
    >
      {body}
    </NavLink>
  );
});
TopBarButtonLink.displayName = "TopBarButtonLink";

type TopBarButtonProps = {
  body: string;
  color: string;
  onClick: any;
};

const TopBarButton = ({ body, color, onClick }: TopBarButtonProps) => {
  return (
    <button
      type="button"
      style={{ border: `1px solid ${color}`, cursor: "pointer" }}
      className={styles.button}
      onClick={onClick}
    >
      {body}
    </button>
  );
};

const TopBar = () => {
  const entry = useContext(ContentfulContext);
  // eslint-disable-next-line react/destructuring-assignment
  const value = entry.filter(
    (item: any) => item.sys.contentType.sys.id === "topNavigation"
  )[0];

  const { showModal } = useGlobalModalContext();

  const contactModal = () => {
    showModal(MODAL_TYPES.CONTACT_MODAL, {
      title: "Create instance form",
      formElement: <MarketoForm formId="3102" />
    });
  };

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)"
  });

  const { authenticatedUser } = useAuth();
  const { isProfessional } = useUserContext();

  const match = useRouteMatch({
    path: [
      "/login",
      "/sign-up",
      "/invited",
      "/forgot-password",
      "/reset-password",
      "/tables/:productName/:tableName",
      "/databases/:productCode/pricing/plans/",
      "/databases/:productCode/pricing/terms",
      "/databases/:productCode/pricing/checkout",
      "/data/:productCode/pricing/plans/",
      "/data/:productCode/pricing/terms",
      "/data/:productCode/pricing/checkout"
    ],
    strict: true
  });

  const buttons = value?.fields?.buttons;
  const links = value?.fields?.links;
  const menu = value?.fields?.menu;

  return (
    <NavigationMenu.Root
      className={styles.container + (match ? ` ${styles.hidden}` : "")}
    >
      <div className={`${styles.content} ${styles["content--no-margin"]}`}>
        <Link to="/" className={styles.brand}>
          <NavbarLogo style={{ borderRight: "1px solid #a6aaad" }} />
          <h1 className={`${styles.link} ${styles["link--brand"]}`}>
            nasdaq data link
          </h1>
        </Link>
        <div className={styles["search-container"]}>
          <Autocomplete />
          <Link to="/search" className={styles.link}>
            explore
          </Link>
        </div>
      </div>

      <div className={styles.content}>
        <NavigationMenu.List className={styles.menuList}>
          {isDesktopOrLaptop &&
            !!buttons &&
            buttons?.map(
              (link: {
                sys: { id: string };
                fields: {
                  url: string;
                  label: string;
                  theme: string;
                };
              }) => {
                return (
                  <NavigationMenu.Item key={link?.sys?.id}>
                    <NavigationMenu.Link asChild>
                      <TopBarButtonLink
                        route={link?.fields?.url}
                        body={link?.fields?.label}
                        color={link?.fields?.theme}
                      />
                    </NavigationMenu.Link>
                  </NavigationMenu.Item>
                );
              }
            )}

          {!authenticatedUser && (
            <>
              {!!links &&
                links?.map(
                  (link: {
                    sys: { id: string };
                    fields: { url: string; label: string };
                  }) => {
                    return (
                      <NavigationMenu.Item key={link?.sys?.id}>
                        <NavigationMenu.Link asChild>
                          <Link to={link?.fields?.url} className={styles.link}>
                            {link?.fields?.label}
                          </Link>
                        </NavigationMenu.Link>
                      </NavigationMenu.Item>
                    );
                  }
                )}
            </>
          )}

          {authenticatedUser && (
            <>
              {isProfessional() && (
                <NavigationMenu.Item>
                  <NavigationMenu.Link asChild>
                    <TopBarButton
                      onClick={contactModal}
                      body="contact sales"
                      color="#000"
                    />
                  </NavigationMenu.Link>
                </NavigationMenu.Item>
              )}

              {isDesktopOrLaptop && <UserDropdown />}
            </>
          )}
          <NavigationMenu.Item>
            <NavigationMenu.Trigger className={styles.item}>
              <FontAwesomeIcon iconClassName="fa-th" />
            </NavigationMenu.Trigger>
            <NavigationMenu.Content className={styles["dropdown-content"]}>
              {menu?.map(
                (m: {
                  sys: { id: string };
                  fields: { url: string; label: string };
                }) => {
                  return (
                    <NavigationMenu.Link asChild key={m?.sys?.id}>
                      <Link to={m?.fields?.url} className={styles.link}>
                        {m?.fields?.label}
                      </Link>
                    </NavigationMenu.Link>
                  );
                }
              )}
            </NavigationMenu.Content>
          </NavigationMenu.Item>
        </NavigationMenu.List>
      </div>
    </NavigationMenu.Root>
  );
};

export default TopBar;
