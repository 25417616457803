import React, { useEffect, useRef } from "react";

import { IStructuredTextHeaderFields } from "../../../../../contentful/__generated__/types";
import "./style.scss";

type Props = {
  fields: IStructuredTextHeaderFields;
};

const THEMES = [
  { name: "Dark", class: "header-dark-background" },
  { name: "Light", class: "header-light-background" }
];

const Header: React.FC<Props> = ({ fields }) => {
  const {
    theme,
    tag,
    heading1,
    heading2,
    heading3,
    alignment,
    heading3Image,
    priority,
    sectionId
  } = fields;

  const themeClass = THEMES.find(t => t.name === theme)?.class;

  const headerRef = useRef(null);
  const bodyRef = useRef(null);

  useEffect(() => {
    const options = {
      rootMargin: "0px",
      threshold: 0.3
    };

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add("flipped__text__visible");
          observer.unobserve(entry.target);
        }
      });
    }, options);

    const headerNode = headerRef.current;
    const bodyNode = bodyRef.current;

    if (headerNode) {
      observer.observe(headerNode);
    }

    if (bodyNode) {
      observer.observe(bodyNode);
    }

    return () => {
      if (headerNode) {
        observer.unobserve(headerNode);
      }

      if (bodyNode) {
        observer.unobserve(bodyNode);
      }
    };
  }, [headerRef, bodyRef]);

  return (
    <div
      className={`short-structured-section-wrapper ${themeClass}`}
      style={{ textAlign: alignment }}
      id={sectionId}
    >
      <div className="short-structured-section-content short-structured-section-content--column">
        <div className="short-structured-market-header-container">
          <div className="short-structured-market-header short-structured-market-header--notice">
            {tag}
          </div>
          <div
            ref={headerRef}
            className={`short-structured-market-header ${
              priority === "Heading 1"
                ? "header-flipped with-top-blue-line"
                : ""
            }`}
          >
            {heading1}
          </div>
        </div>
        {!!heading2 && (
          <div
            ref={bodyRef}
            className={`short-structured-market-body ${
              priority === "Heading 1" ? "body-flipped" : ""
            }`}
          >
            {heading2}
          </div>
        )}
        <div className="short-structured-market-subheading">
          {heading3}
          {!!heading3Image && (
            <img
              className="short-structured-market-image"
              src={`https://${heading3Image?.fields.file.url}`}
              alt={heading3Image?.fields.title}
            />
          )}
        </div>
      </div>
    </div>
  );
};

// eslint-disable-next-line import/prefer-default-export
export { Header as HeaderStructuredText };
