import React from "react";

import { IHeaderFields } from "../../../../contentful/__generated__/types";
import { ActionButton } from "../button/action";
import { LinkButton } from "../button/link";
import "./style.scss";

type Props = {
  fields: IHeaderFields;
};

const Header: React.FC<Props> = ({ fields }) => {
  const {
    heading,
    body,
    backgroundImage,
    primaryButton,
    secondaryButton,
    media
  } = fields;

  const background = {
    backgroundImage: `url(https://${backgroundImage?.fields.file.url})`
  };

  return (
    <header style={background} className="contentful-header">
      <div className="contentful-header__section">
        <div className="contentful-header__container">
          <h1 className="contentful-header__title">{heading}</h1>
          <p className="contentful-header__subtitle">{body}</p>
          <div className="contentful-header__primary-button">
            {primaryButton?.fields ? (
              "action" in primaryButton?.fields ? (
                <div className="header__action-button">
                  <ActionButton fields={primaryButton?.fields} />
                </div>
              ) : (
                <LinkButton
                  theme={primaryButton?.fields?.theme}
                  label={primaryButton?.fields?.label}
                  url={primaryButton?.fields?.url}
                />
              )
            ) : null}

            {secondaryButton?.fields ? (
              "action" in secondaryButton?.fields ? (
                <div className="header__action-button">
                  <ActionButton fields={secondaryButton?.fields} />
                </div>
              ) : (
                <LinkButton
                  theme={secondaryButton?.fields?.theme}
                  label={secondaryButton?.fields?.label}
                  url={secondaryButton?.fields?.url}
                />
              )
            ) : null}
          </div>
        </div>
        <div>
          {!!media &&
            (media.fields.file.contentType.includes("image") ? (
              <img
                className="contentful-header__media"
                src={`https://${media.fields.file.url}`}
                alt={media.fields.title}
              />
            ) : (
              <video
                className="contentful-header__media contentful-header__media-opacity"
                controls
                width="250"
              >
                <source src={`https://${media.fields.file.url}`} />
              </video>
            ))}
        </div>
      </div>
    </header>
  );
};

export default Header;
