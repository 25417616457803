import { productReducer } from "./productReducer";
import { planReducer } from "./planReducer";
import { planCategoriesReducer } from "./planCategoriesReducer";
import { organizationReducer } from "./organizationsReducer";
import { internalCatalogueTokenReducer } from "./internalCatalogueTokenReducer";
import { myOrganizationReducer } from "./myOrganization";

export type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export enum Types {
  Create = "CREATE_PRODUCT",
  Delete = "DELETE_PRODUCT",
  Add = "ADD_PRODUCT",
  Pick = "PICK_PRODUCT",
  Flag = "MOUNT_PRODUCT",

  CreatePlans = "CREATE_PLANS",
  DeletePlans = "DELETE_PLANS",
  CreatePlanCategories = "CREATE_PLAN_CATEGORIES",

  CreateMyOrganizations = "CREATE_MY_ORGANIZATIONS",
  CreateDatabases = "CREATE_DATABASES",
  CreateTeams = "CREATE_TEAMS",
  CreateUsers = "CREATE_USERS",
  CreateOrganizationDetails = "CREATE_ORGANIZATION_DETAILS",
  CreatePermissionDetails = "CREATE_PERMISSION_DETAILS",
  CreateOrganizationName = "CREATE_ORGANIZATION_NAME",
  CreateOrganizationId = "CREATE_ORGANIZATION_ID",

  SetInternalCatalogueToken = "SET_INTERNAL_CATALOGUE_TOKEN",
  RemoveInternalCatalogueToken = "REMOVE_INTERNAL_CATALOGUE_TOKEN",
  ClearInternalCatalogueTokens = "CLEAR_INTERNAL_CATALOGUE_TOKENS"
}

export {
  productReducer,
  planReducer,
  planCategoriesReducer,
  organizationReducer,
  myOrganizationReducer,
  internalCatalogueTokenReducer
};
