import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const useRecentURLs = () => {
  const { pathname, search, hash } = useLocation();
  const [recentURLs, setRecentURLs] = useState<[string, string]>(["", ""]);

  useEffect(() => {
    setRecentURLs(prevState => {
      const newURL = `${pathname}${search}${hash}`;
      return [prevState[1], newURL];
    });
  }, [pathname, search, hash]);

  return recentURLs;
};

export default useRecentURLs;
