import React from "react";
import { HelmetProvider } from "react-helmet-async";

import Header from "../header";
import { CallOutStructuredText } from "../structured-text/call-out";
import { HeaderStructuredText } from "../structured-text/header";
import { CtaBoxRichText } from "../rich-text/cta-box";
import { BasicRichText } from "../rich-text/basic";
import { BackgroundRichText } from "../rich-text/background";
import { MediaRichText } from "../rich-text/media";
import {
  IDefaultPageFields,
  IHeader
} from "../../../../contentful/__generated__/types";
import { ProductsList } from "../products/list";
import { ProductsGrid } from "../products/grid";
import { FeaturesList } from "../features/list";
import { FeaturesGrid } from "../features/grid";
import { FeaturesSlideshow } from "../features/slideshow";
import { CTALink } from "../cta/link";
import { CTAButton } from "../cta/button";
import { BasicCategoryNavigation } from "../navigation/category/basic";
import { IconCategoryNavigation } from "../navigation/category/icon";
import { StatisticsGraphicText } from "../graphic-text/statistics";
import { CtaGraphicText } from "../graphic-text/cta";
import TopTrades from "../top-trades";
import { TechnologiesBasic } from "../technologies";
import Metadata from "../metadata";
import { AccordionLinks } from "../accordion/links";
import { AccordionRichText } from "../accordion/rich-text";
import { DataProviders } from "../data-providers";
import { QuoteStructuredText } from "../structured-text/quote";
import TableGroups from "../table-groups/filterable";

import "./style.scss";

type Props = {
  entry: {
    fields: IDefaultPageFields;
  };
};

const Basic: React.FC<Props> = ({ entry }: { entry: any }) => {
  const { fields: headerFields } = entry.fields.header as IHeader;
  const { fields: metadataFields } = entry.fields.metadata;
  const subnavigation = entry.fields?.subnavigation;

  const sectionComponents = entry.fields.sections.map((section: any) => {
    switch (section.sys.contentType.sys.id) {
      case "dataProviders":
        return <DataProviders key={section.sys.id} fields={section.fields} />;
      case "accordionRichText":
        return (
          <AccordionRichText key={section.sys.id} fields={section.fields} />
        );
      case "accordionLinks":
        return <AccordionLinks key={section.sys.id} fields={section.fields} />;
      case "technologiesBasic":
        return (
          <TechnologiesBasic key={section.sys.id} fields={section.fields} />
        );
      case "topTradesBasic":
        return <TopTrades key={section.sys.id} fields={section.fields} />;
      case "graphicTextCta":
        return <CtaGraphicText key={section.sys.id} fields={section.fields} />;
      case "graphicTextStatistic":
        return (
          <StatisticsGraphicText key={section.sys.id} fields={section.fields} />
        );
      case "categoryNavigationIcon":
        return (
          <IconCategoryNavigation
            key={section.sys.id}
            fields={section.fields}
          />
        );
      case "categoryNavigationBasic":
        return (
          <BasicCategoryNavigation
            key={section.sys.id}
            fields={section.fields}
          />
        );
      case "ctaButton":
        return <CTAButton key={section.sys.id} fields={section.fields} />;
      case "ctaLink":
        return <CTALink key={section.sys.id} fields={section.fields} />;
      case "featuresSlideshow":
        return (
          <FeaturesSlideshow key={section.sys.id} fields={section.fields} />
        );
      case "featuresGrid":
        return <FeaturesGrid key={section.sys.id} fields={section.fields} />;
      case "featuresList":
        return <FeaturesList key={section.sys.id} fields={section.fields} />;
      case "productsGrid":
        return <ProductsGrid key={section.sys.id} fields={section.fields} />;
      case "productsList":
        return <ProductsList key={section.sys.id} fields={section.fields} />;
      case "richTextMedia":
        return <MediaRichText key={section.sys.id} fields={section.fields} />;
      case "richTextBackground":
        return (
          <BackgroundRichText key={section.sys.id} fields={section.fields} />
        );
      case "richTextBasic":
        return <BasicRichText key={section.sys.id} fields={section.fields} />;
      case "structuredTextQuote":
        return (
          <QuoteStructuredText key={section.sys.id} fields={section.fields} />
        );
      case "structuredTextHeader":
        return (
          <HeaderStructuredText key={section.sys.id} fields={section.fields} />
        );
      case "structuredTextCallOut":
        return (
          <CallOutStructuredText key={section.sys.id} fields={section.fields} />
        );
      case "structuredTextCtaBox":
        return <CtaBoxRichText key={section.sys.id} fields={section.fields} />;
      case "tableGroupsFilterable":
        return <TableGroups key={section.sys.id} fields={section.fields} />;
      default:
        return null;
    }
  });

  return (
    <HelmetProvider>
      <Metadata fields={metadataFields} />
      <div className="main">
        <div className="main__grey-line" />
        {!!subnavigation &&
          (entry.fields.subnavigation.sys.contentType.sys.id ===
          "categoryNavigationIcon" ? (
            <IconCategoryNavigation fields={subnavigation.fields} />
          ) : (
            <BasicCategoryNavigation fields={subnavigation.fields} />
          ))}
        <Header fields={headerFields} />

        {sectionComponents}
      </div>
    </HelmetProvider>
  );
};

// eslint-disable-next-line import/prefer-default-export
export { Basic as BasicContentfulPage };
