/* eslint-disable func-names */
const h1 = /^#(?!#)\s*(.*)/;
const h2 = /^##(?!#)\s*(.*)/;

interface Page {
  getTitle(): any;
  getSlug(): any;
  getHash(): any;
  getHref(): any;
  getContent(): any;
  getCssClass(): any;
  pushLine(this: any, line: any): void;
  pushPage(this: any, page: any): void;
  getSectionInfo(this: any): any;
}

class Page {
  constructor(
    public parent?: any,
    public pages?: any,
    public lines?: any,
    public line?: any,
    public title?: any,
    public slug?: any,
    public sectionInfo?: any,
    public content?: string,
    public isContentEmpty?: boolean,
    public isWithoutChildren?: boolean,
    public isNotVisible?: boolean,
    public isVisible?: boolean
  ) {
    this.parent = parent;
    this.pages = pages || [];
    this.lines = lines || [];
    this.line = line;
    this.title = title;
    this.slug = slug;
    this.content = content;
    this.sectionInfo = sectionInfo;
    this.isContentEmpty = !!this.content;
    this.isWithoutChildren = !this.pages.length;
    this.isNotVisible = this.isContentEmpty && this.isWithoutChildren;
    this.isVisible = !this.isNotVisible;
  }
}

Page.prototype.getTitle = function (this: Page) {
  const { line } = this.parent;
  let matched = line.match(h1);
  if (matched && matched[1]) {
    return matched[1];
  }

  matched = line.match(h2);
  return matched ? matched[1] : undefined;
};

Page.prototype.getSlug = function (this: Page) {
  return this.title
    .trim()
    .toLowerCase()
    .replace(/[^\w]+/g, "-");
};
Page.prototype.getHash = function (this: Page) {
  return `#${this.slug}`;
};
Page.prototype.getHref = function (this: Page) {
  let href = this.slug;
  const parentHref = this.parent.href;
  if (parentHref) {
    href = `${parentHref}/${href}`;
  }
  return href;
};
Page.prototype.getContent = function (this: Page) {
  return (this.lines || []).join("\n");
};

Page.prototype.pushLine = function (this: Page, line: any) {
  this.lines.push(line);
};

Page.prototype.pushPage = function (this: Page, page: any) {
  this.pages.push(page);
};

const parsePages = (markdown: any) => {
  const pages: any = [];
  let rootPage: any = null;
  let page: any = null;
  let lines: any = [];

  const defaultRootPage = new Page({ line: "# DOCUMENTATION" }); // Uppercase for consistency with real documentation

  function processLine(line: any) {
    if (h1.test(line)) {
      page = null; // reset page
      rootPage = new Page({ line });
      pages.push(rootPage);
      return;
    }
    const parent = rootPage || defaultRootPage;
    if (h2.test(line)) {
      page = new Page({ parent, line });
      parent.pushPage(page);
      return;
    }
    if (page) {
      page.pushLine(line);
      page.content = page.getContent(line);
      page.href = page.getHref(line);
      page.hash = page.getHash(line);
      page.title = page.getTitle(line);
      page.slug = page.getSlug(line);
      return;
    }
    if (rootPage) {
      // rootPage is true only when inside of h1
      rootPage.pushLine(line);
      rootPage.content = rootPage.getContent(line);
      rootPage.title = rootPage.getTitle(line);
      rootPage.slug = rootPage.getSlug(line);
      rootPage.href = rootPage.getHref(line);
      rootPage.hash = rootPage.getHash(line);
      return;
    }
    if (parent) {
      parent.pushLine(line);
    }
  }

  lines = (markdown || "")
    .replace(/(\n##)|^[\r\n]+/g, (_: any, group1: string) => {
      if (group1) {
        return "\n\n##";
      }
      return "\n";
    })
    .split("\n");

  lines.forEach(processLine);

  if (defaultRootPage.pages.length > 0 || defaultRootPage.lines.length > 0) {
    pages.unshift(defaultRootPage);
  }
  return pages;
};

export default parsePages;
/* eslint-enable func-names */
