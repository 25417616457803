import React from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  NotificationHub
} from "@nef/core";

import { agreeTermsOfUseById } from "../../../api/api";
import { useGlobalModalContext } from "../../../context/modal-context";
import "./style.scss";

const AcceptWelcome = ({ children }: any) => {
  const { hideModal } = useGlobalModalContext();

  const acceptTermsAndCloseModal = async () => {
    try {
      await agreeTermsOfUseById(orgId?.toString());
    } catch (e) {
      NotificationHub.send(
        "danger",
        "Unfortunately, something went wrong. Please refresh this page and try again."
      );
    }
    hideModal();
  };

  const { name, orgName, orgId } = children.props;

  return (
    <Modal size="md" isOpen={true} onBlur={acceptTermsAndCloseModal}>
      <ModalBody>
        <div className="accept-welcome-container">
          <h3 className="greeting">Hi {name},</h3>
          <p className="modal-message">
            You are now a member of {orgName} on Nasdaq Data Link. You can view
            all your {orgName} datasets from the dropdown on the top right of
            the page, or simply click below
          </p>
        </div>
      </ModalBody>

      <ModalFooter>
        <Button
          size="md"
          key="confirm"
          onClick={acceptTermsAndCloseModal}
          data-testid="agree-button"
        >
          View {orgName} datasets
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AcceptWelcome;
