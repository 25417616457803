import React, { useState, useEffect } from "react";
import { createClient } from "contentful";
import { useLocation } from "react-router-dom";
import axios from "axios";

import { BasicContentfulPage } from "../contentful-page/basic";
import NoMatch from "../no-match";
import { SideboxContentfulPage } from "../contentful-page/sidebox";
import Footer from "../footer";
import ServerError from "../contentful-page/server-error";
import {
  IDefaultPage,
  ISideboxPage
} from "../../../contentful/__generated__/types";
import useAuth from "../../hooks/useAuth";

const client = createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE_ID as string,
  accessToken: process.env.REACT_APP_CONTENTFUL_DELIVERY_TOKEN as string
});

const fetcher = async (slug: string): Promise<IDefaultPage | ISideboxPage> => {
  let response = await axios.get(
    `https://${process.env.REACT_APP_CONTENTFUL_HOSTNAME}/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE_ID}/entries`,
    {
      params: {
        access_token: process.env.REACT_APP_CONTENTFUL_DELIVERY_TOKEN,
        content_type: "defaultPage",
        "fields.slug": slug,
        include: 10
      }
    }
  );

  if (response.data.total === 0) {
    response = await axios.get(
      `https://${process.env.REACT_APP_CONTENTFUL_HOSTNAME}/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE_ID}/entries`,
      {
        params: {
          access_token: process.env.REACT_APP_CONTENTFUL_DELIVERY_TOKEN,
          content_type: "sideboxPage",
          "fields.slug": slug,
          include: 10
        }
      }
    );
  }

  const entries = await client.parseEntries(response.data);
  const entry = entries.items.find((item: any) => item.fields.slug === slug);

  if (!entry) {
    throw new Error("Contentful entry not found");
  }

  return entry as IDefaultPage | ISideboxPage;
};

const ContentfulPageOrNoMatch = () => {
  const [entry, setEntry] = useState<IDefaultPage | ISideboxPage>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<unknown | null>(null);
  const { authenticatedUser } = useAuth();

  const location = useLocation();
  const slug = location.pathname.substring(1);

  useEffect(() => {
    if (!authenticatedUser) {
      localStorage.setItem("lastContentfulURL", location?.pathname);
    }
  }, [slug]);

  useEffect(() => {
    let isMounted = true;

    async function fetchData() {
      setIsLoading(true);
      try {
        /* eslint-disable no-underscore-dangle */
        const _entry = await fetcher(slug);
        if (isMounted && _entry) {
          setEntry(_entry);
        }
        /* eslint-enable no-underscore-dangle */
      } catch (e) {
        setError(e);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();

    return () => {
      isMounted = false;
    };
  }, [slug]);

  if (isLoading) return <div>Loading</div>;

  if (error && entry !== undefined) return <ServerError />;

  return (
    <>
      {entry ? (
        <>
          {entry.sys.contentType.sys.id === "defaultPage" ? (
            <BasicContentfulPage entry={entry as IDefaultPage} />
          ) : (
            <SideboxContentfulPage entry={entry as ISideboxPage} />
          )}
          <Footer />
        </>
      ) : (
        <NoMatch />
      )}
    </>
  );
};

export default ContentfulPageOrNoMatch;
