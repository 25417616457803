import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import React from "react";

import { IGraphicTextCtaFields } from "../../../../../contentful/__generated__/types";
import { ActionButton } from "../../button/action";
import "./style.scss";

type Props = {
  fields: IGraphicTextCtaFields;
};

const RICHTEXT_OPTIONS = {
  renderNode: {
    [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
      const { data } = node;

      return (
        <div className="cta__section__right__button">
          <ActionButton fields={data.target.fields} />
        </div>
      );
    }
  }
};

const THEMES = [
  { name: "Blue to Purple", class: "cta__graphic-background__blue_purple" },
  { name: "Orange to Purple", class: "cta__graphic-background__red_purple" },
  { name: "Green to Blue", class: "cta__graphic-background__green_blue" }
];

const Cta: React.FC<Props> = ({ fields }) => {
  const {
    image: imageField,
    textBoxBody,
    textBoxHeadline,
    theme,
    cta
  } = fields;

  const themeClass = THEMES.find(t => t.name === theme)?.class;
  const className = `cta__graphic-text-item ${themeClass}`;

  return (
    <section className="cta__graphic-wrapper">
      <div className="cta__graphic-fact-row cta__graphic__survey-row">
        <div className="cta__graphic">
          <div className="cta__graphic-overlapping-items">
            <img
              src={`https://${imageField?.fields.file.url}`}
              alt={imageField?.fields.title}
              className="cta__graphic-image-item"
            />
            <div className={className}>
              <h1 className="cta__graphic-header">{textBoxHeadline}</h1>
              <p className="cta__graphic-body">{textBoxBody}</p>
            </div>
          </div>
        </div>
        <div className="survey">
          <div className="survey-wrapper survey-wrapper--tall">
            {!!cta && documentToReactComponents(cta, RICHTEXT_OPTIONS)}
          </div>
        </div>
      </div>
    </section>
  );
};

// eslint-disable-next-line import/prefer-default-export
export { Cta as CtaGraphicText };
