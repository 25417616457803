import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  ModalFooter,
  ModalHeader,
  ModalBody,
  NotificationHub,
  FormField
} from "@nef/core";
import { Link } from "react-router-dom";
import { sortBy } from "lodash";

import { useGlobalModalContext } from "../../../context/modal-context";
import "./style.scss";
import { addUserToOrganizationTeam } from "../../../api/api";

import UserIcon from "./components/user-icon/UserIcon";

type User = {
  id: number;
  organization_id: number;
  username: string;
  full_name: string;
  email: string;
  terms_accepted: boolean;
  invitation_accepted: boolean;
};

type Props = {
  allUsersInOrg: number[];
  allUsersInCurrentTeam: number[];
  orgTeamId: number;
  slug: string;
  onAddUsersAndCloseModal?: () => void;
};

const usersNotGrantedAccess = (
  allUsersInOrg: any[],
  allUsersInCurrentTeam: any[]
): any[] => {
  // return users that haven't been added to the org team
  return allUsersInOrg.filter((user: any) => {
    const { id } = user;
    return allUsersInCurrentTeam.findIndex((u: any) => u.id === id) === -1;
  });
};

const AddUsersToOrgDatasetModal = ({
  allUsersInOrg,
  allUsersInCurrentTeam,
  orgTeamId,
  slug,
  onAddUsersAndCloseModal
}: Props) => {
  const [organizationUsers, setOrganizationUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { hideModal } = useGlobalModalContext();
  const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
  const [userQuery, setUserQuery] = useState("");

  const addUsersAndCloseModal = async (selectUsers: number[]) => {
    if (selectUsers.length === 0) {
      return;
    }
    setIsLoading(true);
    try {
      const userRecords = selectUsers.map(userId => {
        return addUserToOrganizationTeam(userId, orgTeamId);
      });
      await Promise.all(userRecords);
      NotificationHub.send("primary", "Users have been granted access");
      onAddUsersAndCloseModal?.();
    } catch (e) {
      NotificationHub.send("danger", "Something went wrong, try again later");
    }
    setIsLoading(false);
    hideModal();
  };

  const hideOnOutsideClick = () => {
    hideModal();
  };

  useEffect(() => {
    let isMounted = true;
    async function fetchData() {
      setIsLoading(true);
      try {
        const users = usersNotGrantedAccess(
          allUsersInOrg,
          allUsersInCurrentTeam
        );
        const sortedUsers = sortBy(users, user =>
          user.full_name?.toLowerCase()
        );

        if (isMounted) {
          setOrganizationUsers(sortedUsers);
        }
      } catch (error) {
        NotificationHub.send("danger", "Could not load users");
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();

    return () => {
      isMounted = false;
    };
  }, []);

  const toggleSelectedUser = (userId: number) => {
    if (selectedUsers.includes(userId)) {
      const withoutUserId = selectedUsers.filter(item => {
        return item !== userId;
      });

      setSelectedUsers(withoutUserId);
    } else {
      setSelectedUsers([...selectedUsers, userId]);
    }
  };

  const filteredUsers = organizationUsers.filter(user => {
    const query = userQuery.toLowerCase();

    if (/^\s*$/i.test(query)) {
      return true;
    }

    const queryString = [user.email, user.full_name]
      .filter(s => !!s)
      .join(" ")
      .toLowerCase();

    return query === "" || queryString.toLowerCase().indexOf(query) !== -1;
  });

  return (
    <Modal size="md" isOpen={true} toggle={() => hideOnOutsideClick()}>
      {organizationUsers.length !== 0 ? (
        <>
          <ModalHeader>
            <p className="add-users-modal-header with-top-blue-line">
              Give access to members
            </p>
          </ModalHeader>
          <ModalBody>
            <div className="add-users-modal-container">
              <FormField
                id="user"
                name="user"
                label="Search User"
                type="text"
                placeholder="User name or email..."
                value={userQuery}
                onChange={e =>
                  setUserQuery((e.target as HTMLInputElement).value)
                }
                data-testid="addUserModal-userQuery"
              />
              {filteredUsers.map((user: User) => {
                return (
                  <div key={user.id} className="user-details">
                    <UserIcon fullName={String(user.full_name)} />
                    <div className="user-details-information">
                      <div>
                        <p className="user-name"> {user.full_name} </p>
                        <p className="user-email"> {user.email} </p>
                        {user.invitation_accepted === false && (
                          <div
                            className="user-pending"
                            data-test-user-detail="pending"
                          >
                            Pending
                          </div>
                        )}
                      </div>
                      <div className="user-action">
                        <input
                          type="checkbox"
                          id={user.id.toString()}
                          name={user.id.toString()}
                          className="org-checkbox"
                          onClick={() => toggleSelectedUser(user.id)}
                          checked={selectedUsers.includes(user.id)}
                        />
                        <label
                          htmlFor={user.id.toString()}
                          className="org-label"
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </ModalBody>
          <ModalFooter
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <div className="add-user-metadata-wrap">
              <p className="selection-status">
                {selectedUsers.length === 0
                  ? "Select at least 1 member"
                  : `${selectedUsers.length} member(s) selected`}
              </p>
              <Button
                key="confirm"
                onClick={() => addUsersAndCloseModal(selectedUsers)}
                isLoading={isLoading}
              >
                Give Access
              </Button>
            </div>
          </ModalFooter>
        </>
      ) : (
        <>
          <ModalHeader>
            <p className="add-users-modal-header with-top-blue-line">
              All members have been added to the dataset
            </p>
          </ModalHeader>
          <ModalBody>
            <p className="emptystate">
              Invite new users to Nasdaq Data Link in the{" "}
              <Link to={`organizations-db/${slug}/users`}>members</Link> tab.
            </p>
          </ModalBody>
          <ModalFooter>
            <Button key="cancel" onClick={hideModal} isLoading={isLoading}>
              Close
            </Button>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};

export default AddUsersToOrgDatasetModal;
