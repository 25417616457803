export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export const ALGOLIA_INDEX = process.env.REACT_APP_ALGOLIA_INDEX || "";
export const ALGOLIA_APPLICATION_ID =
  process.env.REACT_APP_ALGOLIA_APPLICATION_ID || "";
export const ALGOLIA_API_KEY = process.env.REACT_APP_ALGOLIA_API_KEY || "";
export const ALGOLIA_QUERY_SUGGESTIONS_INDEX =
  process.env.REACT_APP_ALGOLIA_QUERY_SUGGESTIONS_INDEX || "";

export const APP_ROOT = process.env.REACT_APP_APP_ROOT;
export const NDL_URL = process.env.REACT_APP_NDL_URL;
export const SAML_STRATEGY_ENABLED = Boolean(
  process.env.REACT_APP_SAML_STRATEGY_ENABLED
);

export const SENTRY_ENABLED = Boolean(process.env.REACT_APP_SENTRY);
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;

export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || "test";
export const REACT_APP_SEGMENT_WRITE_KEY =
  process.env.REACT_APP_SEGMENT_WRITE_KEY || "";
export const STRIPE_PUBLISHABLE_KEY =
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || "";
